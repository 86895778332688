import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css'; 
import moment, { duration, max } from "moment";
import { components } from "react-select";
import swal from "sweetalert";
import MultiRangeSlider from "../../../shared/multiRangeSlider/MultiRangeSlider";
import { CSVLink } from "react-csv";
import DataTableView from "../../../shared/DataTable";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
  WarningNotify,
} from "../../../shared/Toast";
// import MultiSelection from "../controls/MultiSelect";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
var reportData = {};
var options = [];
const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
  data: [],
};

const titleToMatch = "agent-coaching-service-levels";

let columnsArray = [];
let reqColumnsArray = [];
let columnsMatrixArray = [];
let reqColumnsMatrixArray = [];
let combineMatArray = [];
var globalManager2Value = "";
var globalManager1Value = "";
var globalAgentValue = "";

var headersDonw;

const Reports = () => {
  var apiFlag = false;
  const search = useLocation().search;
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );

  let hotWordsNormal = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ];

  let standMetaData = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Call ID",
      value: "call_id",
    },
    {
      label: "Call Duration",
      value: "duration",
    },
    {
      label: "Silence%",
      value: "silence_percentage",
    },
    {
      label: "Agent Talk Time",
      value: "agent_talk_time",
    },
    // {
    //   label: "Client Talk Time",
    //   value: "client_talk_time",
    // },
  ];

  let emotionOptions = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Positive",
      value: "Positive",
    },
    {
      label: "Improving",
      value: "Improving",
    },
    {
      label: "Worsening",
      value: "Worsening",
    },
    {
      label: "Negative",
      value: "Negative",
    },
    {
      label: "Neutral",
      value: "Neutral",
    },
    // {
    //   label: "Client Talk Time",
    //   value: "Client Talk Time",
    // },
  ];
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const [fromReport, setFromReport] = useState(
    pathname?.includes("fromReport") ? true : false
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [valueDragList2, setValueDragList2] = useState([]);
  const [valueDragList1, setValueDragList1] = useState(columnsArray);
  const [valueDragList3, setValueDragList3] = useState(columnsMatrixArray);
  const [valueDragList4, setValueDragList4] = useState([]);
  const [reqColArrList, setReqColArrList] = useState(reqColumnsArray);
  const [reqColMatArrList, setReqColMatArrList] = useState(
    reqColumnsMatrixArray
  );
  const [combineMatArrayList, setCombineMatArrayList] =
    useState(combineMatArray);
  const [folderListData, setFolderListData] = useState([]);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const dragItem2 = useRef(null);
  const dragOverItem2 = useRef(null);
  const [allRecords, setAllRecords] = useState([]);
  const [dateRangeValue, setdateRangeValue] = useState("Month to Date");
  const [dateRangeTypeValue, setdateRangeTypeValue] = useState("Thismonth");
  const [maxFlag, setMaxFlag] = useState(false);

  const [dateType, setDateType] = useState({
    value: "call_date",
    label: "Call Date",
  });
  const [logId,setLogId] = useState()
  const [isDataFound, setIsDataFound] = useState(false);
  const [secondLevelView, setSecondLevelView] = useState(false);
  const [thirdLevelView, setThirdLevelView] = useState(false);
  const [groupByvalue, setGroupByvalue] = useState("LOB");
  const [groupByEnableCheck, setGroupByEnableCheck] = useState(true);
  const [statusVlaue, setStatusValue] = useState([]);
  const [latestForm, setLatestForm] = useState([]);
  const [latestFormValue, setLatestFormValue] = useState([]);
  const [response, setResponse] = useState([]);
  const [agentCoachingLevelData, setAgentCoachingLevelData] = useState([]);
  const [c1Data, setC1Data] = useState([]);
  const [isC1DataLoaded, setIsC1DataLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [c2Data, setC2Data] = useState([]);
  const [c3Data, setC3Data] = useState([]);
  const [c4Data, setC4Data] = useState([]);
  const [payload, setPayload] = useState([]);
  const [typeEval, setTypeEval] = useState("All");
  const [defaultValueC1, setdefaultValueC1] = useState([]);
  const [custom1value, setCustom1Value] = useState([]);
  const [defaultValueC2, setdefaultValueC2] = useState([]);
  const [custom2value, setCustom2Value] = useState([]);
  const [endDuration, setEndDuration] = useState("");
  const [startDuration, setStartDuration] = useState("");
  const [endSlTime, setEndSlTime] = useState("");
  const [startSlTime, setStartSlTime] = useState("");
  const [agentEmotion, setAgentEmotion] = useState();
  const [agentEmotionValue, setAgentEmotionValue] = useState();

  const [clientEmotion, setClientEmotion] = useState();
  const [clientEmotionValue, setClientEmotionValue] = useState();
  const [appReportData, setAppReportData] = useState([]);

  const [callDriver, setCallDriver] = useState([]);
  const [callDriverValue, setCallDriverValue] = useState([]);

  const [DSAT, setDSAT] = useState("");
  const [appHitPhrase, setAppHitPhrase] = useState("yes");
  const [optionHotWord, setOptionHotWord] = useState("yes");
  const [reportStatus,setReportStatus] = useState(false)
  const [compAdherence, setCompAdherence] = useState("");
  const [posLangInstance, setPosLangInstance] = useState("All");
  const [negLangInstance, setNegLangInstance] = useState("All");
  const [application, setApplication] = useState();
  const [applicationVal, setApplicationVal] = useState([]);
  const [customMeta, setCustomMeta] = useState([]);
  const [customMetaValue, setCustomMetaValue] = useState([]);
  const [standardMeta, setStandardMeta] = useState();
  const [standardMetaValue, setStandardMetaValue] = useState();

  const [category, setCategory] = useState();
  const [categoryValue, setCategoryValue] = useState();

  const [subCategory, setSubCategory] = useState("");

  const [callDrCategory, setCallDrCategory] = useState();
  const [callDrSubCategory, setCallDrSubCategory] = useState("");

  const [HWCategory, setHWCategory] = useState();
  const [HWCatData, setHWCatData] = useState([]);

  const [appCatData, setAppCatData] = useState([]);
  const [callDrCatData, setCallDrCatData] = useState([]);

  const [appId, setAppId] = useState();
  const [callDrAppId, setCallDrAppId] = useState();
  const [HWAppId, setHWAppId] = useState();
  const [hotWords, setHotWords] = useState();
  const [hotWordsValue, setHotWordsValue] = useState();

  const [HWId, setHWId] = useState();

  const [allQAAttr, setAllQAAttr] = useState("All");
  const [appListData, setAppListData] = useState([]);
  const [customMetaData, setCustomMetaData] = useState([]);
  const [callDriverData, setCallDriverData] = useState([]);
  const [hotWordsData, setHotWordsData] = useState([]);

  const [defaultValueC3, setdefaultValueC3] = useState([]);
  const [custom3value, setCustom3Value] = useState([]);

  const [defaultValueC4, setdefaultValueC4] = useState([]);
  const [custom4value, setCustom4Value] = useState([]);

  const [hierarchyData, setHierarchyData] = useState([]);

  const [formData, setFormData] = useState([]);
  const [formName, setFormName] = useState("");
  const [manager2, setManager2] = useState([]);
  const [manager2Val, setManager2Val] = useState([]);
  const [manager2Opt, setManager2Opt] = useState([]);

  const [manager1Val, setManager1Val] = useState("");
  const [supervisorVal, setSupervisorVal] = useState("");
  const [agentVal, setAgentVal] = useState("");
  const [manager1, setManager1] = useState([]);
  const [supervisor, setSupervisor] = useState([]);
  const [agent, setAgent] = useState([]);

  const [agentTenure, setAgentTenure] = useState([]);
  const [agentTenureValue, setAgentTenureValue] = useState([]);

  const [evaluatorType, setEvaluatorType] = useState([]);
  const [typeOfEvaluation, setTypeOfEvaluation] = useState();
  const [typeOfEvaluationValue, setTypeOfEvaluationValue] = useState();
  const [folderValue, setFolderValue] = useState([]);
  const [folderId, setFolderId] = useState([""]);
  const [customerState, setCustomerState] = useState([]);
  const [customerStateValue, setCustomerStateValue] = useState([]);
  const [PrimaryReaseon, setPrimaryReason] = useState([]);
  const [CRM, setCRM] = useState([]);
  const [CRMValue, setCRMValue] = useState([]);
  const [language, setLanguage] = useState([]);
  const [languageValue, setLanguageValue] = useState([]);
  const [obCampaign, setObCampaign] = useState([]);
  const [callType, setCallType] = useState([]);
  const [callTypeValue, setCallTypeValue] = useState([]);
  const [tenureGroupFilter, settenureGroupFilter] = useState([]);
  const [medialFile, setMediaFile] = useState([]);
  const [agentId, setAgentId] = useState([]);
  const [supervisorId, setSupervisorId] = useState([]);
  const [orderByValue, setOrderByValue] = useState("Call ID");
  const [submitBtnLoader, setsubmitBtnLoader] = useState(false);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(true);

  const [downloadBtnLoader, setDownloadBtnLoader] = useState(false);
  const [reportStatusBtnLoader, setReportStatusBtnLoader] = useState(false);


  const [firstHeader, setFirstHeader] = useState([]);
  const [totalEvaluationListData, setTotalEvaluationListData] = useState([]);
  const [isGenerate, setIsgenerate] = useState(false);
  const [scheduleGroupList, setScheduleGroupList] = useState([]);
  const [deliveryProfileValue, setDeliveryProfileValue] = useState([]);
  const [name, setName] = useState("");
  const [favouriteReportname, setFavouriteReportName] = useState("");
  const [frequency, setFrequency] = useState([]);
  const [format, setFormat] = useState("CSV");
  const [startAt, setStartAt] = useState("");
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [favouriteLoader, setFavouriteLoader] = useState(false);
  const schedulerId = new URLSearchParams(search).get("schedulerId");
  const favReportId = new URLSearchParams(search).get("favReportId");
  const reportFlag = new URLSearchParams(search).get("reportFlag");
  const callId = new URLSearchParams(search).get("call_id")?.replace(" ", "+");
  const [huntGroupData, setHuntGroupData] = useState([]);
    const [selectedHuntData, setSelectedHuntData] = useState([]);
    const [selectedLocData, setSelectedLocData] = useState();
      const [selectedVendorData, setSelectedVendorData] = useState();
      const [huntsId, setHuntsId] = useState([]);
  console.log("customMeta", customMeta);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
    setMaxFlag(true)
  }, []);
  const Logout = () => {
    localStorage.clear();
    Navigate("/");
    window.location.reload();
    
  };

  useEffect(() => {
    setFromReport(pathname?.includes("fromReport") ? true : false);
    getCustomHierarchyData();
    getAllColumnForOptionView();
    getDeliveryGroupList();
    getFoldersApplications();
    getCustomMetaData();
    getCallDriverData();
    getHotWordsforAll();
  }, [folderId]);

   const ValueContainer5 = ({ children, ...props }) => {
      const { getValue } = props;
      let selectedOption = getValue().length;
  
      if (selectedOption > 0 && selectedHuntData.length > 0) {
        return (
          <components.ValueContainer {...props}>
            {`${selectedHuntData.length} items selected`}
          </components.ValueContainer>
        );
      } else {
        return (
          <components.ValueContainer {...props}>
            {children}
          </components.ValueContainer>
        );
      }
    };

  useEffect(() => {
      if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
      getHuntGroupData()
      }
    }, []);

    const getHuntGroupData = async () => {
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      };
  
      var url = `${base_url}callspeech/get-meta-hunt-group`;
      await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if(data?.data?.length > 0){
            let huntArr = [];
            huntArr.push({ label: "All", value: "All" });
                  data?.data[0]?.hunt_group?.map((item) => {
                    const values = {
                      label: item,
                      value: item,
                    };
                    huntArr.push(values);
                  })
            
          setHuntGroupData(huntArr);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleHuntGroupData = (e,event) => {
      if (event?.option?.value == "All" && event?.action == "select-option") {
        setSelectedHuntData(huntGroupData);
        let allHunts = [];
        huntGroupData &&
        huntGroupData?.map((item) => {
            if (item?.value !== "All") {
              allHunts.push(item);
            }
          });
          console.log("allHuntsallHuntsallHunts",allHunts);
          
        setHuntsId(allHunts);
      }
      else if (event?.option?.value == "All" && event?.action == "deselect-option") {
        setSelectedHuntData([]);
        setHuntsId([]);
      } else {
        if (e.some((i) => i.value == "All")) {
          let filterData = e.filter((item) => item?.value !== "All");
          console.log("3333333333",filterData);
  
          setSelectedHuntData(filterData);
          let filterDataValue = [];
          filterData?.map((item) => {
            filterDataValue.push(item);
          });
          console.log("filterDataValue",filterDataValue);
          
          setHuntsId(filterDataValue);
        }
        else {
            // if (e?.length > 10) {
            //   return ErrorNotify("Can not select tags more than 10 at a time")
            // }
            // else {
              let huntData = [];
              if (e.some((i) => i.value !== "All" && e?.length===huntGroupData?.length-1)) {
                huntData.push({ label: "All", value: "All" });
              }
              e?.map((item) => {
                huntData.push(item);
              });
              console.log("NOVERMNHTEYYE",huntData);
              
              setSelectedHuntData(huntData);
              setHuntsId(huntData);
       // }
          
        }
      }    
    }

  // getting general application categories
  const getCategories = (app_id, key) => {
    var url = `${base_url}callspeech/category-list/${app_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    if (key == "app") {
      callApi(url, requestOptions, "app-cat-list");
    }

    if (key == "callDr") {
      callApi(url, requestOptions, "callDr-cat-list");
    }

    if (key == "HW") {
      callApi(url, requestOptions, "HW-cat-list");
    }
  };

  const downloadExcelReport = () =>{
    setDownloadBtnLoader(true)
    setTimeout(() => {
      
  
    if (appReportData?.length > 0) {
      let csvData = [];
      let responseData = [];
      let heads = [];
      const objValues = Object.keys(appReportData[0]);
      objValues?.map((keys) => {
        if (
          keys !== "call_driver" &&
          keys !== "dsat" &&
          keys !== "all_unmatched_applications" &&
          keys !== "applications" &&
          keys !== "compliance" &&
          keys !== "meta_data" &&
          keys !== "normal_hot_words" &&
          keys !== "_id" &&
          keys !== "created_at" &&
          keys !== "created_by" &&
          keys !== "folder_name"

        ) {
          heads.push(keys);
        }
      });

      let metaData = {};
      let isBroken = false;
      appReportData?.map((obj) => {
        if (isBroken) {
          return;
        }
        if (
          obj?.meta_data !== undefined &&
          obj?.meta_data?.length !== 0
        ) {
          Object.entries(obj?.meta_data).forEach(([metaKey, metaVal]) => {
            metaData[metaKey] = "";
          });
          return;
        }
      });
      appReportData?.forEach((obj) => {
        let values = [];
        Object.entries(obj).forEach(([key, value]) => {
          if ((typeof value == "string" || typeof value == "number") && key !== "created_at" && key !== "folder_name" && key !== "created_by") {
            values[key] = value;
          }
          if (key == "applications") {
            value?.map((item) => {
              item?.category?.length > 0 &&
                item?.category?.map((cat, appCatInd) => {
                  values[
                    `app.${item?.application_name}.${cat?.cat_name}`
                  ] = cat?.phrase_count_percentage;

                  heads.push(
                    `app.${item?.application_name}.${cat.cat_name}`
                  );

                  cat?.subcategory?.length > 0 &&
                    cat?.subcategory?.map((subcat, appSubCat) => {
                      values[
                        `app.${item?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                      ] = subcat?.sub_cat_wise_match_phrase;

                      heads.push(
                        `app.${item?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                      );
                    });
                });
            });
          }

          if (key == "compliance") {
            value?.category?.length > 0 &&
              value?.category?.map((cat, compCatInd) => {
                values[
                  `app.${value?.application_name}.${cat?.cat_name}`
                ] = cat?.phrase_count_percentage;

                heads.push(
                  `app.${value?.application_name}.${cat.cat_name}`
                );

                cat?.subcategory?.length > 0 &&
                  cat?.subcategory?.map((subcat, compSubCatInd) => {
                    heads.push(
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    );

                    values[
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    ] = subcat?.sub_cat_wise_match_phrase?.length;
                  });
              });
          }

          if (key == "meta_data") {
            if (value !== undefined && value?.length !== 0) {
              Object.entries(value).forEach(([objKey, objVal]) => {
                heads.push(objKey);
                values[objKey] = objVal;
              });
            } else {
              Object.entries(metaData).forEach(([objKey, objVal]) => {
                heads.push(objKey);
                values[objKey] = objVal;
              });
            }
          }

          if (key == "dsat") {
            value?.category?.length > 0 &&
              value?.category?.map((cat, dsatCatind) => {
                values[
                  `app.${value?.application_name}.${cat?.cat_name}`
                ] =
                  cat?.phrase_count_percentage !== 0
                    ? cat?.phrase_count_percentage?.toFixed(2)
                    : cat?.phrase_count_percentage;

                heads.push(
                  `app.${value?.application_name}.${cat.cat_name}`
                );

                cat?.subcategory?.length > 0 &&
                  cat?.subcategory?.map((subcat, dsatSubCatind) => {
                    heads.push(
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    );

                    values[
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    ] = subcat?.sub_cat_wise_match_phrase?.length;
                  });
              });
            // })
          }

          if (key == "normal_hot_words") {
            value?.category?.length > 0 &&
              value?.category?.map((cat, HWCatInd) => {
                values[
                  `app.${value?.application_name}.${cat?.cat_name}`
                ] =
                  cat?.phrase_count_percentage !== 0
                    ? cat?.phrase_count_percentage?.toFixed(2)
                    : cat?.phrase_count_percentage;

                heads.push(
                  `app.${value?.application_name}.${cat.cat_name}`
                );

                cat?.subcategory?.length > 0 &&
                  cat?.subcategory?.map((subcat, HWSubCatInd) => {
                    heads.push(
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    );

                    values[
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    ] = subcat?.sub_cat_wise_match_phrase?.length;
                  });
              });
            // })
          }

          if (key == "call_driver") {
            value?.category?.length > 0 &&
              value?.category?.map((cat, cdCatInd) => {
                values[
                  `app.${value?.application_name}.${cat?.cat_name}`
                ] =
                  cat?.phrase_count_percentage !== 0
                    ? cat?.phrase_count_percentage?.toFixed(2)
                    : cat?.phrase_count_percentage;

                heads.push(
                  `app.${value?.application_name}.${cat.cat_name}`
                );

                cat?.subcategory?.length > 0 &&
                  cat?.subcategory?.map((subcat, cdSubCatInd) => {
                    heads.push(
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    );

                    values[
                      `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
                    ] = subcat?.sub_cat_wise_match_phrase?.length;
                  });
              });
            // })
          }
        });
        console.log("values", values);
        responseData.push(values);
      });

      const uniqueArray = [...new Set(heads)];
      console.log("uniqueArray", uniqueArray, responseData);

      // create workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(responseData);

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Smartspeech-Application-Report"
      );

      // customize header names
      XLSX.utils.sheet_add_aoa(worksheet, [uniqueArray]);

      XLSX.writeFile(workbook, "Smartspeech-Application-Report.xlsx", {
        compression: true,
      })
  
        var url = `${base_url}smartspeech/delete-application-report`;

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
        logid:logId,
        created_by:localStorage.getItem("loginUserUniqueid"),
          }),
        };
    
        fetch(url, requestOptions)
          .then((res) => res.json())
          .then((data) => {
            if (data?.status == 200 || data?.status == 201) {
              console.log("Report Data", data?.data);
              setDownloadBtnLoader(false)
              setSubmitBtnStatus(false)
              // if(data?.data?.length > 0){
              //   setReportStatus(false)
              //   setAppReportData([])
              //   setLogId()
              // }
              setReportStatus(false)
                setAppReportData([])
                setLogId()
              
            } else if (data?.status >= 400 || data?.status <= 422) {
              setDownloadBtnLoader(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
    
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                // setIsgenerate(false);
                return;
              }
            } else {
              setDownloadBtnLoader(false);
              ErrorNotify(data.message, {
                icon: "error",
              });
              // setIsgenerate(false);
              return;
            }
          })
          .catch((err) => {
            setDownloadBtnLoader(false);
            DismissToast();
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
              icon: "error",
            });
            return;
            // console.log('========================', data.data);
          });
    }
  }, 2000);
  }

  // const resetDownloadReport = () =>{
  //   var url = `${base_url}smartspeech/delete-application-report`;

  //   var requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("loginToken"),
  //     },
  //     body: JSON.stringify({
  //   logid:logId,
  //   created_by:localStorage.getItem("loginUserUniqueid"),
  //     }),
  //   };

  //   fetch(url, requestOptions)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data?.status == 200 || data?.status == 201) {
  //         console.log("Report Data", data?.data);
  //         setDownloadBtnLoader(false)
  //         if(data?.data?.length > 0){
  //           setReportStatus(false)
  //           setAppReportData([])
  //           setLogId()
  //         }
          
  //       } else if (data?.status >= 400 || data?.status <= 422) {
  //         setDownloadBtnLoader(false);
  //         if (data?.status == 401) {
  //           ErrorNotify(data.message, {
  //             icon: "error",
  //           });

  //           localStorage.clear();
  //           navigate("/");
  //           return;
  //         } else {
  //           ErrorNotify(data.message, {
  //             icon: "error",
  //           });
  //           // setIsgenerate(false);
  //           return;
  //         }
  //       } else {
  //         setDownloadBtnLoader(false);
  //         ErrorNotify(data.message, {
  //           icon: "error",
  //         });
  //         // setIsgenerate(false);
  //         return;
  //       }
  //     })
  //     .catch((err) => {
  //       setDownloadBtnLoader(false);
  //       DismissToast();
  //       ErrorNotify("Something went wrong, Please contact to Administrator !", {
  //         icon: "error",
  //       });
  //       return;
  //       // console.log('========================', data.data);
  //     });
  // };
  const valueAllAppContainer = ({ children, ...props }) => {
    if (application?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            application.some((item) => item?.value == "All")
              ? application.length - 1
              : application?.length
          }  items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  useEffect(() => {
    if(!apiFlag){
    getAllFolders();
    checkReportStatus();
    apiFlag = true
    }
  }, []);


  // getting all folders on call search
  const getAllFolders = () => {
    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "all-folders-list");
  };
  const ValueContainerCustomMeta = ({ children, ...props }) => {
    if (customMeta?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            customMeta.some((item) => item?.value == "All")
              ? customMeta.length - 1
              : customMeta?.length
          }  items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const valueCallDriverContainer = ({ children, ...props }) => {
    if (callDriver?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            callDriver.some((item) => item?.value == "All")
              ? callDriver.length - 1
              : callDriver?.length
          }  items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (folderValue && folderValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${folderValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const valueAppCatContainer = ({ children, ...props }) => {
    if (category?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            category.some((item) => item?.value == "All")
              ? category.length - 1
              : category?.length
          } items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const valueHotWordsContainer = ({ children, ...props }) => {
    if (hotWords?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            hotWords.some((item) => item?.value == "All")
              ? hotWords.length - 1
              : hotWords?.length
          } items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainerStandardMeta = ({ children, ...props }) => {
    if (standardMeta?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            standardMeta.some((item) => item?.value == "All")
              ? standardMeta.length - 1
              : standardMeta?.length
          } items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainerAgentEmotion = ({ children, ...props }) => {
    if (agentEmotion?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            agentEmotion.some((item) => item?.value == "All")
              ? agentEmotion.length - 1
              : agentEmotion?.length
          } items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainerClientEmotion = ({ children, ...props }) => {
    if (clientEmotion?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            clientEmotion.some((item) => item?.value == "All")
              ? clientEmotion.length - 1
              : clientEmotion?.length
          } items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const handleCustomMeta = (e, event) => {
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setCustomMeta(customMetaData);
      setCustomMetaValue(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setCustomMeta([]);
      setCustomMetaValue([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setCustomMeta(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setCustomMetaValue(filterDataValue);
      } else {
        if (customMetaData?.length - 1 == e?.length) {
          setCustomMeta(customMetaData);
          setCustomMetaValue(["All"]);
        } else {
          let clientData = [];
          e?.map((item) => {
            clientData.push(item);
          });
          setCustomMeta(clientData);
          let clientValue = [];
          e?.map((item) => {
            clientValue.push(item.value);
          });
          setCustomMetaValue(clientValue);
        }
      }
    }
  };

  const handleStandardMeta = (e, event) => {
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setStandardMeta(standMetaData);
      setStandardMetaValue(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setStandardMeta([]);
      setStandardMetaValue([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setStandardMeta(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setStandardMetaValue(filterDataValue);
      } else {
        if (standMetaData?.length - 1 == e?.length) {
          setStandardMeta(standMetaData);
          setStandardMetaValue(["All"]);
        } else {
          let clientData = [];
          e?.map((item) => {
            clientData.push(item);
          });
          setStandardMeta(clientData);
          let clientValue = [];
          e?.map((item) => {
            clientValue.push(item.value);
          });
          setStandardMetaValue(clientValue);
        }
      }
    }
  };

  const handleAgentEmotion = (e, event) => {
    console.log("ValueContainerCustomMeta", e, event);

    if (event?.option?.value == "All" && event?.action == "select-option") {
      setAgentEmotion(emotionOptions);
      setAgentEmotionValue(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setAgentEmotion([]);
      setAgentEmotionValue([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setAgentEmotion(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setAgentEmotionValue(filterDataValue);
      } else {
        if (emotionOptions?.length - 1 == e?.length) {
          setAgentEmotion(emotionOptions);
          setAgentEmotionValue(["All"]);
        } else {
          let agentData = [];
          e?.map((item) => {
            agentData.push(item);
          });
          setAgentEmotion(agentData);
          let agentValue = [];
          e?.map((item) => {
            agentValue.push(item.value);
          });
          setAgentEmotionValue(agentValue);
        }
      }
    }
  };

  const handleClientEmotion = (e, event) => {
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setClientEmotion(emotionOptions);
      setClientEmotionValue(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setClientEmotion([]);
      setClientEmotionValue([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setClientEmotion(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setClientEmotionValue(filterDataValue);
      } else {
        if (emotionOptions?.length - 1 == e?.length) {
          setClientEmotion(emotionOptions);
          setClientEmotionValue(["All"]);
        } else {
          let clientData = [];
          e?.map((item) => {
            clientData.push(item);
          });
          setClientEmotion(clientData);
          let clientValue = [];
          e?.map((item) => {
            clientValue.push(item.value);
          });
          setClientEmotionValue(clientValue);
        }
      }
    }
  };

  const handleCallDriver = (e, event) => {
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setCallDriver(callDriverData);
      setCallDriverValue(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setCallDriver([]);
      setCallDriverValue([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setCallDriver(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setCallDriverValue(filterDataValue);
      } else {
        if (callDriverData?.length - 1 == e?.length) {
          setCallDriver(callDriverData);
          setCallDriverValue(["All"]);
        } else {
          let clientData = [];
          e?.map((item) => {
            clientData.push(item);
          });
          setCallDriver(clientData);
          let clientValue = [];
          e?.map((item) => {
            clientValue.push(item.value);
          });
          setCallDriverValue(clientValue);
        }
      }
    }
  };

  const handleHotWords = (e, event) => {
    console.log("ValueContainerCustomMeta", e, event);

    if (event?.option?.value == "All" && event?.action == "select-option") {
      setHotWords(hotWordsData);
      setHotWordsValue(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setHotWords([]);
      setHotWordsValue([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setHotWords(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setHotWordsValue(filterDataValue);
      } else {
        if (hotWordsData?.length - 1 == e?.length) {
          setHotWords(hotWordsData);
          setHotWordsValue(["All"]);
        } else {
          let agentData = [];
          e?.map((item) => {
            agentData.push(item);
          });
          setHotWords(agentData);
          let agentValue = [];
          e?.map((item) => {
            agentValue.push(item.value);
          });
          setHotWordsValue(agentValue);
        }
      }
    }
  };

  // const handleHotWords = (e) => {

  //   console.log("handleCallDriver",e)
  //   let hotWordsData = [];
  //   e?.map((item) => {
  //     hotWordsData.push(item);
  //   });

  //   setHotWords(hotWordsData);

  //   let hotWordsValue = [];
  //   e?.map((item) => {
  //     hotWordsValue.push(item.value);
  //   });
  //   setHotWordsValue(hotWordsValue);
  // };

  const renderMatchPhrase = (data) => {
    var stringArray = data;
    for (var z = 0; z < stringArray.length; z++) {
      stringArray[z] = stringArray[z].replace("#", "(");
      stringArray[z] = stringArray[z] + ")";
    }

    return stringArray.join(" ");
  };

  console.log("handleAppChange",application,applicationVal)
  const handleAppChange = (e,event) => {
    setCategory([]);
    setCategoryValue([]);
    setAppCatData([]);
    // getCategories(e.value, "app");
    // setAppId(e.value);


    if (event?.option?.value == "All" && event?.action == "select-option") {
      setApplication(appListData);

      let allAppData = appListData.filter((item) => item?.value !== "All");
        let filterAppValue = [];
        allAppData?.map((item) => {
          filterAppValue.push(item.value);
        });
        setApplicationVal(filterAppValue);
      // setApplicationVal(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setApplication([]);
      setApplicationVal([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setApplication(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setApplicationVal(filterDataValue);
      } else {
        if (appListData?.length - 1 == e?.length) {
          setApplication(appListData);
          let allAppData = appListData.filter((item) => item?.value !== "All");
        let filterAppValue = [];
        allAppData?.map((item) => {
          filterAppValue.push(item.value);
        });
        setApplicationVal(filterAppValue);
          // setApplicationVal(["All"]);
        } else {
          let appData = [];
          e?.map((item) => {
            appData.push(item);
          });
          setApplication(appData);
          let appValue = [];
          e?.map((item) => {
            appValue.push(item.value);
          });
          setApplicationVal(appValue);
        }
      }
    }
  };

  const handleCategoryChange = (e, event) => {
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setCategory(appCatData);
      setCategoryValue(["All"]);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      setCategory([]);
      setCategoryValue([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        let filterData = e.filter((item) => item?.value !== "All");
        setCategory(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setCategoryValue(filterDataValue);
      } else {
        if (appCatData?.length - 1 == e?.length) {
          setCategory(appCatData);
          setCategoryValue(["All"]);
        } else {
          let clientData = [];
          e?.map((item) => {
            clientData.push(item);
          });
          setCategory(clientData);
          let clientValue = [];
          e?.map((item) => {
            clientValue.push(item.value);
          });
          setCategoryValue(clientValue);
        }
      }
    }
  };
  const getCustomMetaData = () => {
    // setAppName("");
    // setAppID([]);
    var url = `${base_url}smartspeech/call-custom-meta`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "custom-meta-data");
  };
  const getCallDriverData = () => {
    // setAppName("");
    // setAppID([]);
    var url = `${base_url}smartspeech/call-driver-application`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "call-driver-data");
  };

  const onChangeTypEval = (e) => {
    setTypeEval(e.target.value);
    setHWCatData([]);
    setHWId();
    setHWCategory();
    setHotWords([]);
  };
  const getHotWordsforAll = () => {
    // setAppName("");
    // setAppID([]);
    var url = `${base_url}smartspeech/hot-words-application`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "hot-words-data");
  };
  const getFoldersApplications = () => {
    // setAppName("");
    // setAppID([]);
    var url = `${base_url}tags/all-application-multi-folder-wise`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        folder_id: folderId,
      }),
    };
    callApi(url, requestOptions, "folder-wise-app-list");
  };

  const onChangeDuration = (min, max) => {
    console.log("onChangeDuration", min, max);

    setEndDuration(max.toString());
    setStartDuration(min.toString());
  };
  const onChangeSlienceTime = (min, max) => {
    console.log("onChangeSlienceTime", min, max);
    setEndSlTime(max.toString());
    setStartSlTime(min.toString());
  };
  const onMaxClick = (e) => {
    console.log("onMaxClick", e);
  };

  console.log("setReportData", reportData);
  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === ""
    ) {
      localStorage.clear();
      Navigate("/");
      window.location.reload();
    }
  }, []);

  console.log(
    "all Custom Fields",
    defaultValueC1,
    defaultValueC2,
    defaultValueC3,
    defaultValueC4
  );

  const getDeliveryGroupList = () => {
    var url = `${base_url}delivery-group-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("group-list--", data);
        if (data?.status == 200 || data?.status == 201) {
          setScheduleGroupList(data?.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const getAllColumnForOptionView = () => {
    var url = `${base_url}reports-view-options`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          if (data?.data?.length > 0) {
            const matchingObject = data?.data?.find(
              (item) => item.slug == titleToMatch
            );
            console.log("Columnsdata", matchingObject);
            if (matchingObject) {
              columnsArray = matchingObject.columns.split(",");
              reqColumnsArray = matchingObject.req_columns.split(",");
              columnsMatrixArray = matchingObject.columns_matrix.split(",");
              reqColumnsMatrixArray =
                matchingObject.req_columns_matrix.split(",");
              combineMatArray = columnsMatrixArray.concat(
                reqColumnsMatrixArray
              );
            }
            console.log("Columns:", columnsArray);
            console.log("Columns Req:", reqColumnsArray);
            console.log("Columns Matrix:", columnsMatrixArray);
            console.log("Columns Matrix Req:", reqColumnsMatrixArray);
            console.log("combineMatArray:", combineMatArray);

            setValueDragList1(columnsArray);
            setValueDragList3(columnsMatrixArray);
            setReqColArrList(reqColumnsArray);
            setReqColMatArrList(reqColumnsMatrixArray);
            setCombineMatArrayList(combineMatArray);
          }
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const addToList2 = (e) => {
    console.log("ererereree", e);
    setValueDragList1(valueDragList1.filter((value) => value !== e));
    setValueDragList2([...valueDragList2, e]);
  };
  const addToList1 = (e) => {
    console.log("ererereree", e);
    setValueDragList2(valueDragList2.filter((value) => value !== e));
    setValueDragList1([...valueDragList1, e]);
  };
  const addToList4 = (e) => {
    console.log("ererereree", e);
    setValueDragList3(valueDragList3.filter((value) => value !== e));
    setValueDragList4([...valueDragList4, e]);
  };
  const addToList3 = (e) => {
    console.log("ererereree", e);
    setValueDragList4(valueDragList4.filter((value) => value !== e));
    setValueDragList3([...valueDragList3, e]);
  };

  const getCSVFile = (csvData) => {
    let anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csvData);
    anchor.target = "_blank";
    anchor.download = "test.csv";
    anchor.click();
  };
  const handleSort = () => {
    let _Items = [...valueDragList2];
    const draggedItemContent = _Items.splice(dragItem.current, 1)[0];
    _Items.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setValueDragList2(_Items);
  };
  const handleSort2 = () => {
    let _Items = [...reqColArrList];
    const draggedItemContent = _Items.splice(dragItem2.current, 1)[0];
    _Items.splice(dragOverItem2.current, 0, draggedItemContent);
    dragItem2.current = null;
    dragOverItem2.current = null;
    setReqColArrList(_Items);
  };

  console.log("setReqColArrList", reqColArrList);

  function handleOnDragFromList1(e, valueType) {
    e.dataTransfer.setData("valueType", valueType);
  }

  function handleOnDragFromList3(e, list3) {
    e.dataTransfer.setData("list3", list3);
  }

  function handleOnDropOnList2(e) {
    const valueType = e.dataTransfer.getData("valueType");
    setValueDragList1(valueDragList1.filter((value) => value !== valueType));
    console.log("valueType", valueType);
    if (valueType != "") {
      setValueDragList2([...valueDragList2, valueType]);
    }

    console.log("valueList", valueDragList2);
  }

  function handleOnDropOnList4(e) {
    const valueType = e.dataTransfer.getData("list3");
    setValueDragList3(valueDragList3.filter((value) => value !== valueType));
    console.log("valueTypeL3", valueType);
    if (valueType != "") {
      setValueDragList4([...valueDragList4, valueType]);
    }

    console.log("valueListL4", valueDragList2);
  }
  function handleDragOver(e) {
    e.preventDefault();
    //console.log("valueDragOver")
  }
  function handleOnDragFromList2(e, valueID) {
    e.dataTransfer.setData("valueTypeFromList2", valueID);
  }
  function handleOnDragFromList4(e, valueID) {
    e.dataTransfer.setData("valueTypeFromList3", valueID);
  }
  function handleOnDropOnList1(e) {
    const valueTypeList1 = e.dataTransfer.getData("valueTypeFromList2");
    setValueDragList2(
      valueDragList2.filter((value) => value !== valueTypeList1)
    );
    console.log("valueType1", valueTypeList1);
    if (valueTypeList1 != "") {
      setValueDragList1([...valueDragList1, valueTypeList1]);
    }

    console.log("valueList1", valueTypeList1);
  }
  function handleOnDropOnList3(e) {
    const valueTypeList3 = e.dataTransfer.getData("valueTypeFromList3");
    setValueDragList4(
      valueDragList4.filter((value) => value !== valueTypeList3)
    );
    console.log("valueType2", valueTypeList3);
    if (valueTypeList3 != "") {
      setValueDragList3([...valueDragList3, valueTypeList3]);
    }

    console.log("valueList", valueTypeList3);
  }
  function handleDragOverList1(e) {
    e.preventDefault();
    //console.log("valueDragOver")
  }
  const formDataToJson = (formData) => {
    const jsonObject = {};

    for (let pair of formData.entries()) {
      jsonObject[pair[0]] = pair[1];
    }

    return jsonObject;
  };

  const handleFrequencyChange = (e) => {
    console.log("frequency", e);
    setFrequency(e);
  };

  const submitReport = () => {
    setsubmitBtnLoader(true);
    var url = `${base_url}smartspeech/application-wise-report-new2`;
let reportPayload = {
  overall_from_date: fromDate,
  overall_to_date: toDate,
  duration: endDuration,
  start_duration: startDuration,
  start_silent_time: typeEval !== "manual_qa" ? startSlTime : undefined,
  silent_time: typeEval !== "manual_qa" ? endSlTime : undefined,
  client_emotion:
    typeEval !== "manual_qa" && clientEmotionValue?.length > 0
      ? clientEmotionValue
      : undefined,
  agent_emotion:
    typeEval !== "manual_qa" && agentEmotionValue?.length > 0
      ? agentEmotionValue
      : undefined,
  application:applicationVal,
  application_hit_process_status:
    typeEval !== "manual_qa" ? appHitPhrase : undefined,
  dsat: typeEval !== "manual_qa" ? DSAT : '',
  compliance: typeEval !== "manual_qa" ? compAdherence : '',
  custom_meta: customMetaValue?.length > 0 ? customMetaValue : undefined,
  // standard_meta:
  //   standardMetaValue?.length > 0 ? standardMetaValue : undefined,
  call_driver_category:
    typeEval !== "manual_qa" && callDriverValue?.length > 0
      ? [
          {
            category: callDriverValue,
          },
        ]
      : [],

  normal_hot_words_category:
    typeEval == "All" && hotWordsValue?.length > 0
      ? [
          {
            category: hotWordsValue,
          },
        ]
      : [],

  option_hot_words:
    typeEval === "speech_analytics" ? optionHotWord : '',
  folder_id: folderId,
  userId:localStorage.getItem("loginUserUniqueid"),
}

if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
  reportPayload["group_hunt"] = huntsId?.filter(f=>f?.value!=="All")?.map(i=>i?.value)
}
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify(reportPayload),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          console.log("Report Data", data?.data);
          setsubmitBtnLoader(false)
          if (data?.data == "Pending") {
            setSubmitBtnStatus(true)
            SuccessNotify("Your report has been processed! You will be notified via email shortly.");

            // WarningNotify("Report is in progress, We will notify you on mail once the report is ready.")
          }
          // if (data?.data?.length > 0) {
          //   let csvData = [];
          //   let responseData = [];
          //   let heads = [];
          //   const objValues = Object.keys(data?.data[0]);
          //   objValues?.map((keys) => {
          //     if (
          //       keys !== "call_driver" &&
          //       keys !== "dsat" &&
          //       keys !== "all_unmatched_applications" &&
          //       keys !== "all_applications" &&
          //       keys !== "compliance" &&
          //       keys !== "meta_data" &&
          //       keys !== "normal_hot_words"
          //     ) {
          //       heads.push(keys);
          //     }
          //   });

          //   let metaData = {};
          //   let isBroken = false;
          //   data?.data?.map((obj) => {
          //     if (isBroken) {
          //       return;
          //     }
          //     if (
          //       obj?.meta_data !== undefined &&
          //       obj?.meta_data?.length !== 0
          //     ) {
          //       Object.entries(obj?.meta_data).forEach(([metaKey, metaVal]) => {
          //         metaData[metaKey] = "";
          //       });
          //       return;
          //     }
          //   });
          //   data?.data?.forEach((obj) => {
          //     let values = [];
          //     Object.entries(obj).forEach(([key, value]) => {
          //       if (typeof value == "string" || typeof value == "number") {
          //         values[key] = value;
          //       }
          //       if (key == "all_applications") {
          //         value?.map((item) => {
          //           item?.category?.length > 0 &&
          //             item?.category?.map((cat, appCatInd) => {
          //               values[
          //                 `app.${item?.application_name}.${cat?.cat_name}`
          //               ] =
          //                 cat?.phrase_count_percentage !== 0
          //                   ? cat?.phrase_count_percentage?.toFixed(2)
          //                   : cat?.phrase_count_percentage;

          //               heads.push(
          //                 `app.${item?.application_name}.${cat.cat_name}`
          //               );

          //               cat?.subcategory?.length > 0 &&
          //                 cat?.subcategory?.map((subcat, appSubCat) => {
          //                   values[
          //                     `app.${item?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                   ] = subcat?.sub_cat_wise_match_phrase;

          //                   heads.push(
          //                     `app.${item?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                   );
          //                 });
          //             });
          //         });
          //       }

          //       if (key == "compliance") {
          //         value?.category?.length > 0 &&
          //           value?.category?.map((cat, compCatInd) => {
          //             values[
          //               `app.${value?.application_name}.${cat?.cat_name}`
          //             ] =
          //               cat?.phrase_count_percentage !== 0
          //                 ? cat?.phrase_count_percentage?.toFixed(2)
          //                 : cat?.phrase_count_percentage;

          //             heads.push(
          //               `app.${value?.application_name}.${cat.cat_name}`
          //             );

          //             cat?.subcategory?.length > 0 &&
          //               cat?.subcategory?.map((subcat, compSubCatInd) => {
          //                 heads.push(
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 );

          //                 values[
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 ] = subcat?.sub_cat_wise_match_phrase?.length;
          //               });
          //           });
          //       }

          //       if (key == "meta_data") {
          //         if (value !== undefined && value?.length !== 0) {
          //           Object.entries(value).forEach(([objKey, objVal]) => {
          //             heads.push(objKey);
          //             values[objKey] = objVal;
          //           });
          //         } else {
          //           Object.entries(metaData).forEach(([objKey, objVal]) => {
          //             heads.push(objKey);
          //             values[objKey] = objVal;
          //           });
          //         }
          //       }

          //       if (key == "dsat") {
          //         value?.category?.length > 0 &&
          //           value?.category?.map((cat, dsatCatind) => {
          //             values[
          //               `app.${value?.application_name}.${cat?.cat_name}`
          //             ] =
          //               cat?.phrase_count_percentage !== 0
          //                 ? cat?.phrase_count_percentage?.toFixed(2)
          //                 : cat?.phrase_count_percentage;

          //             heads.push(
          //               `app.${value?.application_name}.${cat.cat_name}`
          //             );

          //             cat?.subcategory?.length > 0 &&
          //               cat?.subcategory?.map((subcat, dsatSubCatind) => {
          //                 heads.push(
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 );

          //                 values[
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 ] = subcat?.sub_cat_wise_match_phrase?.length;
          //               });
          //           });
          //         // })
          //       }

          //       if (key == "normal_hot_words") {
          //         value?.category?.length > 0 &&
          //           value?.category?.map((cat, HWCatInd) => {
          //             values[
          //               `app.${value?.application_name}.${cat?.cat_name}`
          //             ] =
          //               cat?.phrase_count_percentage !== 0
          //                 ? cat?.phrase_count_percentage?.toFixed(2)
          //                 : cat?.phrase_count_percentage;

          //             heads.push(
          //               `app.${value?.application_name}.${cat.cat_name}`
          //             );

          //             cat?.subcategory?.length > 0 &&
          //               cat?.subcategory?.map((subcat, HWSubCatInd) => {
          //                 heads.push(
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 );

          //                 values[
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 ] = subcat?.sub_cat_wise_match_phrase?.length;
          //               });
          //           });
          //         // })
          //       }

          //       if (key == "call_driver") {
          //         value?.category?.length > 0 &&
          //           value?.category?.map((cat, cdCatInd) => {
          //             values[
          //               `app.${value?.application_name}.${cat?.cat_name}`
          //             ] =
          //               cat?.phrase_count_percentage !== 0
          //                 ? cat?.phrase_count_percentage?.toFixed(2)
          //                 : cat?.phrase_count_percentage;

          //             heads.push(
          //               `app.${value?.application_name}.${cat.cat_name}`
          //             );

          //             cat?.subcategory?.length > 0 &&
          //               cat?.subcategory?.map((subcat, cdSubCatInd) => {
          //                 heads.push(
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 );

          //                 values[
          //                   `app.${value?.application_name}.${cat?.cat_name}.${subcat?.subcat_name}`
          //                 ] = subcat?.sub_cat_wise_match_phrase?.length;
          //               });
          //           });
          //         // })
          //       }
          //     });
          //     console.log("values", values);
          //     responseData.push(values);
          //   });

          //   const uniqueArray = [...new Set(heads)];
          //   console.log("uniqueArray", uniqueArray, responseData);

          //   // create workbook and worksheet
          //   const workbook = XLSX.utils.book_new();
          //   const worksheet = XLSX.utils.json_to_sheet(responseData);

          //   XLSX.utils.book_append_sheet(
          //     workbook,
          //     worksheet,
          //     "Smartspeech-Application-Report"
          //   );

          //   // customize header names
          //   XLSX.utils.sheet_add_aoa(worksheet, [uniqueArray]);

          //   XLSX.writeFile(workbook, "Smartspeech-Application-Report.xlsx", {
          //     compression: true,
          //   });
          // } else {
          //   WarningNotify("No Records Found !");
          // }
          // setReportBtnLoader(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          setsubmitBtnLoader(false);
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            // setIsgenerate(false);
            return;
          }
        } else {
          setsubmitBtnLoader(false);
          ErrorNotify(data.message, {
            icon: "error",
          });
          // setIsgenerate(false);
          return;
        }
      })
      .catch((err) => {
        setsubmitBtnLoader(false);
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const checkReportStatus = () => {
    if(appReportData?.length > 0){
      // WarningNotify("Download the Generated Report!")
      return;
    }
    setReportStatusBtnLoader(true)

    var url = `${base_url}smartspeech/get-application-report-list`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
    created_at:moment().format("YYYY-MM-DD"),
    created_by:localStorage.getItem("loginUserUniqueid"),
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setReportStatusBtnLoader(false)
        if (data?.status == 200 || data?.status == 201) {
          console.log("Report Data", data?.data);
          if(data?.data?.length > 0){
            // SuccessNotify("You can download the report now.")
            setReportStatus(true)
            setAppReportData(data?.data)
            setLogId(data?.logid)
          }
          else{
            if(data?.message == "Report is not ready.Please wait for some time"){
              setSubmitBtnStatus(true)
            }
            else{
              setSubmitBtnStatus(false)
            }
            // WarningNotify(data?.message)
          }
          
        } else if (data?.status >= 400 || data?.status <= 422) {
          setDownloadBtnLoader(false);
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            // setIsgenerate(false);
            return;
          }
        } else {
          setDownloadBtnLoader(false);
          ErrorNotify(data.message, {
            icon: "error",
          });
          // setIsgenerate(false);
          return;
        }
      })
      .catch((err) => {
        setReportStatusBtnLoader(false)
        setDownloadBtnLoader(false);
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const getCustomHierarchyData = async () => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("hierarchy data======", data);

        if (data?.status == 200 || data?.status == 201) {
          var arr = [];
          data.data?.map((i, j) => {
            arr[i.custom1_id] = i.custom1_name;
            arr[i.custom2_id] = i.custom2_name;
            arr[i.custom3_id] = i.custom3_name;
            arr[i.custom4_id] = i.custom4_name;
          });
          // arr['c2'] = 'Vendor';
          // arr['c3'] = 'LOB';
          // arr['c1'] = 'Campaign';
          // arr['c4'] = 'Location';
          console.log("arr -===========", arr);
          setHierarchyData(arr);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
      });

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    // var url = `${base_url}custom/1`;
    // await fetch(url, requestOptions)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data?.status == 200 || data?.status == 201) {
    //       setC1Data(data.data);
    //       return;
    //     } else if (data?.status >= 400 || data?.status <= 422) {
    //       if (data?.status == 401) {
    //         ErrorNotify(data.message, {
    //           icon: "error",
    //         });

    //         localStorage.clear();
    //         navigate("/");
    //         return;
    //       } else {
    //         ErrorNotify(data.message, {
    //           icon: "error",
    //         });
    //         return;
    //       }
    //     } else {
    //       ErrorNotify(data.message, {
    //         icon: "error",
    //       });
    //       return;
    //     }
    //   })
    //   .catch((err) => {
    //     DismissToast();
    //     ErrorNotify("Something went wrong, Please contact to Administrator !", {
    //       icon: "error",
    //     });
    //     return;
    //   });
  };

  const handleDateRangeTypeChange = (data) => {
    setdateRangeTypeValue(data);
    setFromToDatesFunc(data.value);
  };

  const setFromToDatesFunc = (val) => {
    // week wise date range starts here
    if (val === "Thisweek") {
      setFromDate(moment().clone().startOf("week").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "1_Weeks") {
      setFromDate(
        moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
      );
    }

    if (val === "2_Weeks") {
      setFromDate(
        moment().subtract(2, "weeks").startOf("week").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
      );
    }

    if (val === "3_Weeks") {
      setFromDate(
        moment().subtract(3, "weeks").startOf("week").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
      );
    }

    if (val === "weekToDate") {
      setFromDate(moment().clone().startOf("week").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    // week wise date range ends here

    // month wise date range starts here
    if (val === "Thismonth") {
      setFromDate(moment().clone().startOf("month").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "1_Months") {
      setFromDate(
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }

    if (val === "2_Months") {
      setFromDate(
        moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }

    if (val === "3_Months") {
      setFromDate(
        moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }
    // month wise date range ends here

    // day wise date range starts here
    if (val === "Today") {
      setFromDate(moment().format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "OneDay") {
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    if (val === "TwoDays") {
      setFromDate(moment().subtract(2, "days").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
    // day wise date range ends here

    if (val === "YearDate") {
      setFromDate(moment().clone().startOf("year").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }

    if (val === "PreviousMonth") {
      setFromDate(
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
      );
      setToDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    }
    if (val === "CurrentMonth") {
      setFromDate(moment().clone().startOf("month").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
  };

  // const handleFolderChange = (e,event) => {
  //   console.log("handleFolderChange",e)

  //   if (event?.option?.value == "All" && event?.action == "select-option") {
  //     setFolderValue(folderListData);
  //     let allFolderValue = []
  //     folderListData && folderListData?.map(item=>{
  //       if(item?.value !== "All"){
  //       allFolderValue.push(item?.value)
  //       }
  //     })
  //     setFolderId(allFolderValue);
  //   } else if (
  //     event?.option?.value == "All" &&
  //     event?.action == "deselect-option"
  //   ) {
  //     setFolderValue([]);
  //     setFolderId([]);
  //   } else {
  //     if (e.some((i) => i.value == "All")) {
  //       let filterData = e.filter((item) => item?.value !== "All");
  //       setFolderValue(filterData);
  //       let filterDataValue = [];
  //       filterData?.map((item) => {
  //         filterDataValue.push(item.value);
  //       });
  //       setFolderId(filterDataValue);
  //     } else {
  //       if (folderListData?.length - 1 == e?.length) {
  //         setFolderValue(folderListData);
  //         setFolderId(["All"]);
  //       } else {
  //         let folderData = [];
  //         e?.map((item) => {
  //           folderData.push(item);
  //         });
  //         setFolderValue(folderData);
  //         let folderValue = [];
  //         e?.map((item) => {
  //           folderValue.push(item.value);
  //         });
  //         setFolderId(folderValue);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    const value = dateRangeValue;
    let startDate, endDate;
    const today = moment().startOf("day").format("YYYY-MM-DD");
    if (value === "1 Day") {
      startDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "Week to Date") {
      startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      endDate = today;
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "1 Week") {
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "2 Week") {
      startDate = moment(today).subtract(14, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "Month to Date") {
      startDate = moment(today).startOf("month").format("YYYY-MM-DD");
      endDate = today;
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "Previous Month") {
      startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "1 Month") {
      startDate = moment(today).subtract(31, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "2 Month") {
      startDate = moment(today).subtract(62, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "Quarter To Date") {
      startDate = moment(today).subtract(92, "day").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }
    if (value === "Year to Date") {
      startDate = moment().startOf("year").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "1 Year") {
      startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
      endDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
      setFromDate(startDate);
      setToDate(endDate);
    }

    if (value === "filter_customdate") {
      setFromDate(moment().startOf("month").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
    }
  }, [dateRangeValue]);
  const today = new Date().toISOString().split("T")[0];
  const handleDateRangeChange = (e) => {
    console.log("handleDateRangeChange", e);
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");
    setLatestFormValue("");
    setdateRangeValue(e.target.value);
    console.log("valvalvalval", e.target.value);
  };

  const handleC2Change = (e) => {
    console.log("handleC2Change", e);
    // setdefaultValueC1("");
    var c1Data = [];
    var c1Value = [];

    e?.map((item) => {
      c1Data.push(item);
    });

    setdefaultValueC1(c1Data);

    e?.map((item) => {
      c1Value.push(item.value);
    });

    setCustom1Value(c1Value);

    setCustom2Value([]);
    setCustom3Value([]);
    setCustom4Value([]);

    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");

    var url = `${base_url}filter/custom2`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        daterange: dateRangeValue?.value,
        custom1: c1Value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setC2Data(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const handleC3Change = (e) => {
    // setdefaultValueC2("");
    console.log("handleC3Change", e);
    // setdefaultValueC1("");
    let c2Data = [];
    e?.map((item) => {
      c2Data.push(item);
    });

    setdefaultValueC2(c2Data);

    let c2Value = [];
    e?.map((item) => {
      c2Value.push(item.value);
    });

    setCustom2Value(c2Value);
    setCustom3Value([]);
    setCustom4Value([]);
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    console.log("custom1value", custom1value);
    var url = `${base_url}filter/custom3`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        daterange: dateRangeValue?.value,
        custom1: custom1value,
        custom2: c2Value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setdefaultValueC2(e);
          setC3Data(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const handleC4Change = (e) => {
    console.log("handleC4Change", e);
    // setdefaultValueC1("");
    let c3Data = [];
    e?.map((item) => {
      c3Data.push(item);
    });

    setdefaultValueC3(c3Data);

    let c3Value = [];
    e?.map((item) => {
      c3Value.push(item.value);
    });

    setCustom3Value(c3Value);
    setCustom4Value([]);
    setdefaultValueC4("");
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");

    var url = `${base_url}filter/custom4`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from: fromDate,
        to: toDate,
        datetype: dateType?.value,
        daterange: dateRangeValue?.value,
        custom1: custom1value,
        custom2: custom2value,
        custom3: c3Value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setCustom3Value((prevCustom3) => [...prevCustom3, ...custom3value]);
          setC4Data(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const handleC5Change = (e) => {
    let c4Data = [];
    e?.map((item) => {
      c4Data.push(item);
    });

    setdefaultValueC4(c4Data);

    let c4Value = [];
    e?.map((item) => {
      c4Value.push(item.value);
    });
    setCustom4Value(c4Value);
    setLatestForm("");
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    // props.getC4Value(e);

    var url = `${base_url}filter/forms`;
    fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from: fromDate,
          to: toDate,
          datetype: dateType?.value,
          daterange: dateRangeValue?.value,
          custom1: custom1value,
          custom2: custom2value,
          custom3: custom3value,
          custom4: c4Value,
        }),
      },
      "formData"
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setFormData(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      });

    var url = `${base_url}filter/manager2`;
    fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from: fromDate,
          to: toDate,
          datetype: dateType?.value,
          daterange: dateRangeValue?.value,
          custom1: custom1value,
          custom2: custom2value,
          custom3: custom3value,
          custom4: c4Value,
        }),
      },
      "manager2"
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          setManager2Opt(data.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      });
  };

  const handleManager2Changed = (e) => {
    setManager2Val("");
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    console.log("manager2 val------", e);

    let mg2Data = [];
    e?.map((item) => {
      mg2Data.push(item);
    });

    setManager2(mg2Data);

    let mg2Value = [];
    e?.map((item) => {
      mg2Value.push(item.value);
    });
    setManager2Val(mg2Value);

    globalManager2Value = mg2Value;

    getAllValueFunc("manager1");
  };

  const handleFormChanged = (e) => {
    console.log("form value", e);

    let formData = [];
    e?.map((item) => {
      formData.push(item);
    });

    setLatestForm(formData);

    let formValue = [];
    e?.map((item) => {
      formValue.push(item.value);
    });

    setLatestFormValue(formValue);
  };

  const handleManager1Changed = (val) => {
    setManager1Val("");
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    globalManager1Value = val;
    setManager1Val(val);
    // props.getManager1(val);
    getAllValueFunc("supervisor");
  };

  const handleSupChanged = (val) => {
    setSupervisorVal("");
    setAgentVal("");
    setAgentTenure("");
    globalAgentValue = val;
    setSupervisorVal(val);
    setSupervisorId(val);
    getAllValueFunc("agent");
  };

  const handleAgentChanged = (val) => {
    // setAgent(val)
    setAgentVal("");
    setAgentTenure("");
    setAgentId(val);
  };
  const handleAgentTenureChanged = (e) => {
    let agentData = [];
    e?.map((item) => {
      agentData.push(item);
    });

    setAgentTenure(agentData);

    let agentValue = [];
    e?.map((item) => {
      agentValue.push(item.value);
    });
    setAgentTenureValue(agentValue);
  };
  const handleTypeOfEvaluation = (e) => {
    let evalData = [];
    e?.map((item) => {
      evalData.push(item);
    });

    setTypeOfEvaluation(evalData);

    let evalValue = [];
    e?.map((item) => {
      evalValue.push(item.value);
    });
    setTypeOfEvaluationValue(evalValue);
  };
  const handleCustomerState = (e) => {
    let custData = [];
    e?.map((item) => {
      custData.push(item);
    });

    setCustomerState(custData);

    let custValue = [];
    e?.map((item) => {
      custValue.push(item.value);
    });
    setCustomerStateValue(custValue);
  };
  const handleCRM = (e) => {
    let CRMData = [];
    e?.map((item) => {
      CRMData.push(item);
    });

    setCRM(CRMData);

    let CRMValue = [];
    e?.map((item) => {
      CRMValue.push(item.value);
    });
    setCRMValue(CRMValue);
  };
  const handleLanguage = (e) => {
    let langData = [];
    e?.map((item) => {
      langData.push(item);
    });

    setLanguage(langData);

    let langValue = [];
    e?.map((item) => {
      langValue.push(item.value);
    });
    setLanguageValue(langValue);
  };
  const handleCallType = (e) => {
    let callTypData = [];
    e?.map((item) => {
      callTypData.push(item);
    });

    setCallType(callTypData);

    let callTypValue = [];
    e?.map((item) => {
      callTypValue.push(item.value);
    });
    setCallTypeValue(callTypValue);
  };
  // const handleTypeOfEvaluation = (val) => {
  //     setTypeOfEvaluation(val)
  // }

  const handleOrderBy = (e) => {
    console.log("agentCoachingLevelData11Order", e.target.value);
    setOrderByValue(e.target.value);
  };

  const handleGroupByValue = (event) => {
    console.log("checkingGrpByValue");
    // setGroupByvalue(event.target.value);
  };

  const handleDeliveryChange = (val) => {
    console.log("DeliveryChange", val);
    setDeliveryProfileValue(val);
  };

  const getAllValueFunc = (val) => {
    switch (val) {
      case "manager1":
        // console.log('manager2Val---------', manager2Val)
        var url = `${base_url}filter/manager1`;
        callApi(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify({
              from: fromDate,
              to: toDate,
              dateType: dateType?.value,
              daterange: dateRangeValue?.value,
              custom1: custom1value,
              custom2: custom2value,
              custom3: custom3value,
              custom4: custom4value,
              manager2: globalManager2Value,
            }),
          },
          "manager1"
        );
        break;

      case "supervisor":
        var url = `${base_url}filter/supervisor`;
        callApi(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify({
              from: fromDate,
              to: toDate,
              datetype: dateType?.value,
              daterange: dateRangeValue?.value,
              custom1: custom1value,
              custom2: custom2value,
              custom3: custom3value,
              custom4: custom4value,
              manager1: globalManager1Value,
            }),
          },
          "supervisor"
        );

        break;

      case "agent":
        var url = `${base_url}filter/agent`;
        callApi(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify({
              from: fromDate,
              to: toDate,
              datetype: dateType?.value,
              daterange: dateRangeValue?.value,
              supervisor: globalAgentValue,
            }),
          },
          "agent"
        );

        break;

      default:
        break;
    }
  };

  const callApi = (url, requestOptions, purpose) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (purpose === "manager2") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("manager2 value----------------", data);

              if (data?.status == 200 || data?.status == 201) {
                setManager2Opt(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });

                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
            });
        }
        if (purpose === "manager1") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data?.status == 200 || data?.status == 201) {
                setManager1(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });

                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "supervisor") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("supervisor--------", data);
              if (data?.status == 200 || data?.status == 201) {
                setSupervisor(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });

                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "folder-wise-app-list") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("setAppListData--------", data);
              if (data?.status == 200 || data?.status == 201) {
                if (data?.data?.length > 0) {
                  let appData = [];
                  appData.push({ label: "All", value: "All" });
                  data?.data?.map((item) => {
                    const values = {
                      label: item.application_name,
                      value: item.application_id,
                    };
                    appData.push(values);
                  });
                  setAppListData(appData);
                }
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "custom-meta-data") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("setCustomMetaData--------", data);
              if (data?.status == 200 || data?.status == 201) {
                let customMetaRec = data?.data?.length == 0 ? {} : data?.data;
                if (Object.keys(customMetaRec).length !== 0) {
                  let customData = [];
                  customData.push({ label: "All", value: "All" });
                  for (const [key, value] of Object.entries(customMetaRec)) {
                    console.log(key, value);
                    const values = {
                      label: key,
                      value: key,
                    };
                    customData.push(values);
                  }
                  console.log("customData", customData);
                  setCustomMetaData(customData);
                }
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "hot-words-data") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("hot-words-data--------", data);
              if (data?.status == 200 || data?.status == 201) {
                if (data?.data?.length > 0) {
                  let hotWordsData = [];
                  hotWordsData.push({ label: "All", value: "All" });
                  data?.data?.map((item) => {
                    const values = {
                      label: item.category_name,
                      value: item.category_id,
                      app_id: data?.application_id,
                    };
                    hotWordsData.push(values);
                  });
                  setHotWordsData(hotWordsData);
                }
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "call-driver-data") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("All-driver-data--------", data);
              if (data?.status == 200 || data?.status == 201) {
                if (data?.data?.length > 0) {
                  let callDriverData = [];
                  callDriverData.push({ label: "All", value: "All" });
                  data?.data?.map((item) => {
                    const values = {
                      label: item.category_name,
                      value: item.category_id,
                      app_id: data?.application_id,
                    };
                    callDriverData.push(values);
                  });
                  setCallDriverData(callDriverData);
                }
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }
        if (purpose === "agent") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              console.log("agent value-------", data);

              if (data?.status == 200 || data?.status == 201) {
                setAgent(data?.data);
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });

                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
            });
        }

        if (purpose === "app-cat-list") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data?.status == 200 || data?.status == 201) {
                if (data?.data?.category?.length > 0) {
                  let appCatList = [];
                  appCatList.push({ label: "All", value: "All" });
                  data?.data?.category?.map((item) => {
                    const values = {
                      label: item.category_name,
                      value: item.category_id,
                    };
                    appCatList.push(values);
                  });
                  setAppCatData(appCatList);
                }
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }

        if (purpose === "callDr-cat-list") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data?.status == 200 || data?.status == 201) {
                if (data?.data?.category?.length > 0) {
                  let callDriveCatList = [];
                  data?.data?.category?.map((item) => {
                    const values = {
                      label: item.category_name,
                      value: item.category_id,
                    };
                    callDriveCatList.push(values);
                  });
                  setCallDrCatData(callDriveCatList);
                }
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }

        if (purpose === "HW-cat-list") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data?.status == 200 || data?.status == 201) {
                if (data?.data?.category?.length > 0) {
                  let HWCatList = [];
                  data?.data?.category?.map((item) => {
                    const values = {
                      label: item.category_name,
                      value: item.category_id,
                    };
                    HWCatList.push(values);
                  });
                  setHWCatData(HWCatList);
                }
                return;
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            })
            .catch((err) => {
              DismissToast();
              ErrorNotify(
                "Something went wrong, Please contact to Administrator !",
                {
                  icon: "error",
                }
              );
              return;
              // console.log('========================', data.data);
            });
        }

        if (purpose === "all-folders-list") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data?.status == 200 || data?.status == 201) {
                if (data?.data?.length > 0) {
                  let folderListArr = [];
                  // folderListArr.push({ label: "All", value: "All" })

                  data?.data?.map((item) => {
                    // const values = {
                    //   label: item.folder_name,
                    //   value: item.folder_id,
                    // };
                    folderListArr.push(item.folder_id);
                  });
                  console.log(folderListArr, "folderListArr");
                  setFolderId(folderListArr);
                  // setFolderID(data?.data[0]?.folder_id);
                  // setFolderName(data?.data[0]?.folder_name);
                }
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.error, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            });
        }
        if (purpose === "check-report-status") {
          fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data?.status == 200 || data?.status == 201) {
                setReportStatus(true)
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.error, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            });
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
      });
  };

  const selectAllFromList1 = () => {
    setValueDragList2([]);
    setValueDragList1([]);
    setValueDragList2(columnsArray);
  };

  const removeAllFormList2 = () => {
    setValueDragList2([]);
    setValueDragList1(columnsArray);
    // setValueDragList2([ "Call ID","Agent ID", "Supervisor","EvaluationTime Thresold"])
  };

  const selectAllFromList3 = () => {
    setValueDragList4([]);
    setValueDragList3([]);
    setValueDragList4(columnsMatrixArray);
  };

  const removeAllFormList4 = () => {
    setValueDragList4([]);
    setValueDragList3(columnsMatrixArray);
  };

  const showTotalEvaluationList = async (grpByVal) => {
    setThirdLevelView(false);

    console.log("shadowwwwwwwwwwww", grpByVal);
    var url = `${base_url}agent-coaching-service-levels`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_toDate: toDate ? toDate : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_columns: filter_columns,
        filter_group_by: groupByvalue ? groupByvalue : "LOB",
        // filter_form: ["61f037fbe30e80866"],
        filter_group_by_value: grpByVal ? grpByVal : "",
        // filter_custom_call_type: ["Tech"],
        // filter_order_by: orderByValue ? orderByValue : '',
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          const sortedData = data?.sort((a, b) =>
            // a[`${orderByValue}`].localeCompare(b[`${orderByValue}`])
            String(a[`${orderByValue}`])
              .toLowerCase()
              .localeCompare(String(b[`${orderByValue}`]).toLowerCase())
          );

          // console.log("agentCoachingLevelData", data?.map((i) => (i["Call ID"])))
          setTotalEvaluationListData(sortedData);

          // console.log("99999999999999999999", Object.keys(totalEvaluationListData[0])??.map((key) => (key))[0])
          setFirstHeader(Object.keys(sortedData[0])?.map((key) => key));
          setThirdLevelView(true);
          headers(Object.keys(sortedData[0])?.map((key) => key));
          setAllRecords(sortedData);
          // csvReport = {
          //   data: sortedData,
          //   headers: headersDonw,
          //   filename: "AgentCoaching.csv",
          // };
          setTimeout(() => {
            scrollToData2();
          }, 200);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
    // setTimeout(() => {
    //     setThirdLevelView(true);
    // }, 3000);
  };

  const headers = (headerlist) =>
    (headersDonw = headerlist?.map((item) => ({
      label: item,
      key: item,
    })));
  const showTotalEvaluationWithoutGrpVall = () => {
    setThirdLevelView(false);

    var url = `${base_url}agent-coaching-service-levels`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_toDate: toDate ? toDate : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_columns: filter_columns,
        // filter_group_by: groupByvalue ? groupByvalue : '',
        // filter_form: ["61f037fbe30e80866"],
        // filter_group_by_value: grpByVal ? grpByVal : '',
        // filter_custom_call_type: ["Tech"],
        // filter_order_by: orderByValue ? orderByValue : '',
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          const sortedData = data.sort((a, b) =>
            String(a[`${orderByValue}`])
              .toLowerCase()
              .localeCompare(String(b[`${orderByValue}`]).toLowerCase())
          );

          setTotalEvaluationListData(sortedData);
          setFirstHeader(Object.keys(sortedData[0])?.map((key) => key));
          setAllRecords(sortedData);
          headers(Object.keys(sortedData[0])?.map((key) => key));
          console.log("agentCoachingwwcsvdata", sortedData);
          console.log("agentCoachingwwheader", firstHeader);
          // csvReport = {
          //   data: sortedData,
          //   headers: headersDonw,
          //   filename: "AgentCoaching.csv",
          // };

          setThirdLevelView(true);
          setTimeout(() => {
            scrollToData2();
          }, 200);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  const setFromDateFunc = (e) => {
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");

    setFromDate(e);
  };

  const setToDateFunc = (e) => {
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setLatestForm("");

    setToDate(e);
  };

  const handleEnableGroupBy = (e) => {
    console.log("mmmmmmmmmmmm", e);
    const isChecked = e.target.checked;
    if (!isChecked) {
      setGroupByEnableCheck(false);
    } else {
      setGroupByEnableCheck(true);
    }
  };

  const scheduleReport = () => {
    if (
      defaultValueC1 === "" ||
      defaultValueC1.length === 0 ||
      defaultValueC1 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c1}`);
      return;
    }
    if (
      defaultValueC2 === "" ||
      defaultValueC2.length === 0 ||
      defaultValueC2 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c2}`);
      return;
    }
    if (filter_columns === "") {
      ErrorNotify(`Please Add Filter Columns`);
      return;
    }
    if (
      defaultValueC3 === "" ||
      defaultValueC3.length === 0 ||
      defaultValueC3 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c3}`);
      return;
    }
    if (
      defaultValueC4 === "" ||
      defaultValueC4.length === 0 ||
      defaultValueC4 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c4}`);
      return;
    }
    if (
      latestFormValue === "" ||
      latestFormValue.length === 0 ||
      latestFormValue === "Select"
    ) {
      ErrorNotify(`Please Select Form`);
      return;
    }
    if (name === "" || name.length === 0 || name === "Select") {
      ErrorNotify(`Please Select Schedule Name`);
      return;
    }
    if (frequency === "" || frequency.length === 0 || frequency === "Select") {
      ErrorNotify(`Please Select Report Frequency`);
      return;
    }
    if (
      deliveryProfileValue === "" ||
      deliveryProfileValue.length === 0 ||
      deliveryProfileValue === "Select"
    ) {
      ErrorNotify(`Please Select Delivery profile`);
      return;
    }
    if (format === "" || format.length === 0 || format === "Select") {
      ErrorNotify(`Please Select Report Format`);
      return;
    }
    if (startAt === "" || startAt.length === 0 || startAt === "Select") {
      ErrorNotify(`Please Select Start At`);
      return;
    }
    setScheduleLoader(true);
    var url = `${base_url}scheduler-report-add`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_toDate: toDate ? toDate : "",
        filter_custom1: custom1value ? custom1value : [],
        filter_custom2: custom2value ? custom2value : [],
        filter_custom3: custom3value ? custom3value : [],
        filter_custom4: custom4value ? custom4value : [],
        filter_form: latestFormValue ? latestFormValue : "",
        report_url: titleToMatch,
        filter_columns: filter_columns,
        filter_custom_type_of_evaluation: typeOfEvaluationValue,
        filter_manager2: manager2Val,
        filter_agent_tenure: agentTenureValue,
        filter_custom_customer_state: customerStateValue,
        filter_custom_crm: CRMValue,
        filter_group_by: groupByvalue,
        filter_order_by: orderByValue,
        filter_schedule_name: name,
        filter_report_frequency: frequency?.value,
        filter_delivery_profile_group_id: deliveryProfileValue?.value,
        filter_scheduler_start_date_time: startAt,
        user_id: localStorage.getItem("loginUserUniqueid"),
        user_name: localStorage.getItem("loginUserName"),
        empid: localStorage.getItem("loginUserUniqueid"),
        emp_group: localStorage.getItem("loginUserGroup"),
        emp_type: localStorage.getItem("loginUserType"),
        filter_custom_type_of_evaluation: typeOfEvaluationValue,
        filter_manager2: manager2Val,
        filter_agent_tenure: agentTenureValue,
        filter_custom_customer_state: customerStateValue,
        filter_custom_crm: CRMValue,
        filter_custom_language: languageValue,
        filter_custom_call_type: callTypeValue,
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          SuccessNotify(data.message);
          setScheduleLoader(false);
          setName("");
          setFrequency([]);
          setDeliveryProfileValue([]);
          setFormat("CSV");
          setStartAt("");
          setdefaultValueC1("");
          setdefaultValueC2("");
          setdefaultValueC3("");
          setdefaultValueC4("");
          setLatestForm("");
          setLatestFormValue("");
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setScheduleLoader(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setScheduleLoader(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setScheduleLoader(false);
          return;
        }
      })
      .catch((err) => {
        setScheduleLoader(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
          toastId: "login",
        });
        return;
      });
  };

  const updateScheduleReport = () => {
    if (
      defaultValueC1 === "" ||
      defaultValueC1.length === 0 ||
      defaultValueC1 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c1}`);
      return;
    }
    if (
      defaultValueC2 === "" ||
      defaultValueC2.length === 0 ||
      defaultValueC2 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c2}`);
      return;
    }
    if (filter_columns === "") {
      ErrorNotify(`Please Add Filter Columns`);
      return;
    }
    if (
      defaultValueC3 === "" ||
      defaultValueC3.length === 0 ||
      defaultValueC3 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c3}`);
      return;
    }
    if (
      defaultValueC4 === "" ||
      defaultValueC4.length === 0 ||
      defaultValueC4 === "Select"
    ) {
      ErrorNotify(`Please Select ${hierarchyData.c4}`);
      return;
    }
    if (
      latestFormValue === "" ||
      latestFormValue.length === 0 ||
      latestFormValue === "Select"
    ) {
      ErrorNotify(`Please Select Form`);
      return;
    }
    if (name === "" || name.length === 0 || name === "Select") {
      ErrorNotify(`Please Select Schedule Name`);
      return;
    }
    if (frequency === "" || frequency.length === 0 || frequency === "Select") {
      ErrorNotify(`Please Select Report Frequency`);
      return;
    }
    if (
      deliveryProfileValue === "" ||
      deliveryProfileValue.length === 0 ||
      deliveryProfileValue === "Select"
    ) {
      ErrorNotify(`Please Select Delivery profile`);
      return;
    }
    if (format === "" || format.length === 0 || format === "Select") {
      ErrorNotify(`Please Select Report Format`);
      return;
    }
    if (startAt === "" || startAt.length === 0 || startAt === "Select") {
      ErrorNotify(`Please Select Start At`);
      return;
    }
    setScheduleLoader(true);
    var url = `${base_url}scheduler-report-update`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        schedulerId: schedulerId,
        filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
        filter_datetype: dateType ? dateType?.value : "",
        filter_fromDate: fromDate ? fromDate : "",
        filter_toDate: toDate ? toDate : "",
        filter_custom1: custom1value ? custom1value : [],
        filter_custom2: custom2value ? custom2value : [],
        filter_custom3: custom3value ? custom3value : [],
        filter_custom4: custom4value ? custom4value : [],
        filter_form: latestFormValue ? latestFormValue : "",
        report_url: titleToMatch,
        filter_custom_type_of_evaluation: typeOfEvaluationValue,
        filter_manager2: manager2Val,
        filter_agent_tenure: agentTenureValue,
        filter_custom_customer_state: customerStateValue,
        filter_custom_crm: CRMValue,
        filter_custom_language: languageValue,
        filter_custom_call_type: callTypeValue,
        filter_columns: filter_columns,
        filter_group_by: groupByvalue,
        filter_order_by: orderByValue,
        filter_schedule_name: name,
        filter_report_frequency: frequency?.value,
        filter_delivery_profile_group_id: deliveryProfileValue?.value,
        filter_scheduler_start_date_time: startAt,
        user_id: localStorage.getItem("loginUserUniqueid"),
        empid: localStorage.getItem("loginUserUniqueid"),
        emp_group: localStorage.getItem("loginUserGroup"),
        emp_type: localStorage.getItem("loginUserType"),
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          SuccessNotify(data.message);
          setScheduleLoader(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setScheduleLoader(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setScheduleLoader(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setScheduleLoader(false);
          return;
        }
      })
      .catch((err) => {
        setScheduleLoader(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
          toastId: "login",
        });
        return;
      });
  };

  const saveFavouriteReport = () => {
    if (favReportId !== null) {
      if (
        defaultValueC1 === "" ||
        defaultValueC1.length === 0 ||
        defaultValueC1 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c1} value`);
        return;
      }
      if (
        defaultValueC2 === "" ||
        defaultValueC2.length === 0 ||
        defaultValueC2 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c2} value`);
        return;
      }
      if (
        defaultValueC3 === "" ||
        defaultValueC3.length === 0 ||
        defaultValueC3 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c3} value`);
        return;
      }
      if (
        defaultValueC4 === "" ||
        defaultValueC4.length === 0 ||
        defaultValueC4 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c4} value`);
        return;
      }
      if (
        latestFormValue === "" ||
        latestFormValue.length === 0 ||
        latestFormValue === "Select"
      ) {
        ErrorNotify(`Please select Form value`);
        return;
      }
      if (
        favouriteReportname === "" ||
        favouriteReportname.length === 0 ||
        favouriteReportname === "Select"
      ) {
        ErrorNotify(`Please Enter Favourite Report`);
        return;
      }
      console.log("dateType", dateType);
      setFavouriteLoader(true);
      var url = `${base_url}favorite-report-update`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          favoriteId: favReportId,
          filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
          filter_datetype: dateType?.value,
          filter_fromDate: fromDate ? fromDate : "",
          filter_toDate: toDate ? toDate : "",
          filter_custom1: custom1value ? custom1value : [],
          filter_custom2: custom2value ? custom2value : [],
          filter_custom3: custom3value ? custom3value : [],
          filter_custom4: custom4value ? custom4value : [],
          filter_form: latestFormValue ? latestFormValue : "",
          report_url: titleToMatch,
          filter_columns: filter_columns,
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_schedule_name: name,
          filter_group_by: groupByvalue,
          filter_order_by: orderByValue,
          filter_favorite_name: favouriteReportname,
          filter_report_frequency: frequency?.value,
          filter_delivery_profile_group_id: deliveryProfileValue?.value,
          filter_scheduler_start_date_time: startAt,
          user_id: localStorage.getItem("loginUserUniqueid"),
          user_name: localStorage.getItem("loginUserName"),
          empid: localStorage.getItem("loginUserUniqueid"),
          emp_group: localStorage.getItem("loginUserGroup"),
          emp_type: localStorage.getItem("loginUserType"),
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_custom_language: languageValue,
          filter_custom_call_type: callTypeValue,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setFavouriteLoader(false);
          if (data?.status == 200 || data?.status == 201) {
            SuccessNotify(data.message);
            return;
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });

              localStorage.clear();
              navigate("/");
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        })
        .catch((err) => {
          setFavouriteLoader(false);
          DismissToast();
          ErrorNotify(
            "Something went wrong, Please contact to Administrator !",
            {
              icon: "error",
            }
          );
          return;
          // console.log('========================', data.data);
        });
    } else {
      if (
        defaultValueC1 === "" ||
        defaultValueC1.length === 0 ||
        defaultValueC1 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c1} value`);
        return;
      }
      if (
        defaultValueC2 === "" ||
        defaultValueC2.length === 0 ||
        defaultValueC2 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c2} value`);
        return;
      }
      if (
        defaultValueC3 === "" ||
        defaultValueC3.length === 0 ||
        defaultValueC3 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c3} value`);
        return;
      }
      if (
        defaultValueC4 === "" ||
        defaultValueC4.length === 0 ||
        defaultValueC4 === "Select"
      ) {
        ErrorNotify(`Please select ${hierarchyData.c4} value`);
        return;
      }
      if (
        latestFormValue === "" ||
        latestFormValue.length === 0 ||
        latestFormValue === "Select"
      ) {
        ErrorNotify(`Please select Form value`);
        return;
      }
      if (
        favouriteReportname === "" ||
        favouriteReportname.length === 0 ||
        favouriteReportname === "Select"
      ) {
        ErrorNotify(`Please Enter Favourite Report`);
        return;
      }
      setFavouriteLoader(true);
      var url = `${base_url}favorite-report-add`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          filter_date: dateRangeValue ? dateRangeValue : "Month to Date",
          filter_datetype: dateType?.value,
          filter_fromDate: fromDate ? fromDate : "",
          filter_toDate: toDate ? toDate : "",
          filter_custom1: custom1value ? custom1value : [],
          filter_custom2: custom2value ? custom2value : [],
          filter_custom3: custom3value ? custom3value : [],
          filter_custom4: custom4value ? custom4value : [],
          filter_form: latestFormValue ? latestFormValue : "",
          report_url: titleToMatch,
          filter_columns: filter_columns,
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_group_by: groupByvalue,
          filter_order_by: orderByValue,
          filter_schedule_name: name,
          filter_favorite_name: favouriteReportname,
          filter_report_frequency: frequency?.value,
          filter_delivery_profile_group_id: deliveryProfileValue?.value,
          filter_scheduler_start_date_time: startAt,
          user_id: localStorage.getItem("loginUserUniqueid"),
          user_name: localStorage.getItem("loginUserName"),
          user_id: localStorage.getItem("loginUserUniqueid"),
          empid: localStorage.getItem("loginUserUniqueid"),
          emp_group: localStorage.getItem("loginUserGroup"),
          emp_type: localStorage.getItem("loginUserType"),
          filter_custom_type_of_evaluation: typeOfEvaluationValue,
          filter_manager2: manager2Val,
          filter_agent_tenure: agentTenureValue,
          filter_custom_customer_state: customerStateValue,
          filter_custom_crm: CRMValue,
          filter_custom_language: languageValue,
          filter_custom_call_type: callTypeValue,
        }),
      };
      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setFavouriteLoader(false);
          if (data?.status == 200 || data?.status == 201) {
            SuccessNotify(data.message);
            return;
          } else if (data?.status >= 400 || data?.status <= 422) {
            if (data?.status == 401) {
              ErrorNotify(data.message, {
                icon: "error",
              });

              localStorage.clear();
              navigate("/");
              return;
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        })
        .catch((err) => {
          setFavouriteLoader(false);
          DismissToast();
          ErrorNotify(
            "Something went wrong, Please contact to Administrator !",
            {
              icon: "error",
            }
          );
          return;
          // console.log('========================', data.data);
        });
    }
  };

  function createDataSheet1(
    data1,
    data2,
    data3,
    data4,
    sheetName,
    title,
    title2,
    title3,
    title4,
    title5
  ) {
    const ws = XLSX.utils.aoa_to_sheet([[]]); // Create an empty sheet

    const boldStyle = { font: { bold: true } };

    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );

    let currentRow = 2; // Start at row 2

    XLSX.utils.sheet_add_aoa(ws, [[title]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[formattedDate]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[title2]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys1 = [];
    const values1 = [];

    for (const key in data1) {
      if (data1.hasOwnProperty(key)) {
        const value = data1[key];
        keys1.push(key);
        values1.push(value);
      }
    }

    const dataRows1 = keys1?.map((key, index) => ({
      Key: key,
      Value: values1[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows1, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows1.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    // XLSX.utils.sheet_add_json(ws, [data1], { origin: `A${currentRow}` });
    // currentRow += 3;

    XLSX.utils.sheet_add_aoa(ws, [[title3]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys = [];
    const values = [];

    for (const key in data2) {
      if (data2.hasOwnProperty(key)) {
        const value = data2[key];
        keys.push(key);
        values.push(value === 0 ? "0" : value.toString());
      }
    }

    const dataRows = keys?.map((key, index) => ({
      Key: key,
      Value: values[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    XLSX.utils.sheet_add_aoa(ws, [[title4]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_json(ws, data3, { origin: `A${currentRow}` });

    // if (thirdLevelView) {
    //   XLSX.utils.sheet_add_aoa(ws, [[title5]], { origin: `A${currentRow}`, style: boldStyle });
    //   currentRow++;

    //   XLSX.utils.sheet_add_json(ws, data4, { origin: `A${currentRow}` });
    // }

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    return wb;
  }

  const handleDownloadXLSX1 = () => {
    const sheet1 = createDataSheet1(
      payload,
      response,
      agentCoachingLevelData,
      totalEvaluationListData,
      "Agent Reports",
      "Agent Coaching Service Level",
      "Parameters",
      "Summary",
      "Reports",
      "Agent Coaching Level Data"
    );

    XLSX.writeFile(sheet1, "Agent_Coaching_Service_Level.xlsx");
    // setTimeout(() => {
    //     window.location.reload(false);
    // }, 100);
  };

  function createDataSheet2(
    data1,
    data2,
    data3,
    data4,
    sheetName,
    title,
    title2,
    title3,
    title4,
    title5
  ) {
    const ws = XLSX.utils.aoa_to_sheet([[]]); // Create an empty sheet

    const boldStyle = { font: { bold: true } };

    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );

    let currentRow = 2; // Start at row 2

    XLSX.utils.sheet_add_aoa(ws, [[{ t: "s", v: title, s: boldStyle }]], {
      origin: `A${currentRow}`,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[formattedDate]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    XLSX.utils.sheet_add_aoa(ws, [[title2]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys1 = [];
    const values1 = [];

    for (const key in data1) {
      if (data1.hasOwnProperty(key)) {
        const value = data1[key];
        keys1.push(key);
        values1.push(value);
      }
    }

    const dataRows1 = keys1?.map((key, index) => ({
      Key: key,
      Value: values1[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows1, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows1.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    XLSX.utils.sheet_add_aoa(ws, [[title3]], {
      origin: `A${currentRow}`,
      style: boldStyle,
    });
    currentRow++;

    const keys = [];
    const values = [];

    for (const key in data2) {
      if (data2.hasOwnProperty(key)) {
        const value = data2[key];
        keys.push(key);
        values.push(value === 0 ? "0" : value.toString());
      }
    }

    const dataRows = keys?.map((key, index) => ({
      Key: key,
      Value: values[index] || "", // Ensure values exist even if they are undefined
    }));

    // Add key-value pairs to the worksheet in separate columns
    XLSX.utils.sheet_add_json(ws, dataRows, { origin: `A${currentRow}` });
    currentRow += Math.max(dataRows.length, 1); // Ensure at least one row is added

    // XLSX.utils.sheet_add_json(ws, [data2], { origin: `A${currentRow}` });
    currentRow += 3;

    if (thirdLevelView) {
      XLSX.utils.sheet_add_aoa(ws, [[title5]], {
        origin: `A${currentRow}`,
        style: boldStyle,
      });
      currentRow++;

      XLSX.utils.sheet_add_json(ws, data4, { origin: `A${currentRow}` });
    }

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    return wb;
  }

  const handleDownloadXLSX2 = () => {
    const sheet1 = createDataSheet2(
      payload,
      response,
      agentCoachingLevelData,
      totalEvaluationListData,
      "Agent Reports",
      "Agent Coaching Service Level",
      "Parameters",
      "Summary",
      "Reports",
      "Agent Coaching Level Data"
    );

    XLSX.writeFile(sheet1, "Agent_Coaching_Service_Level.xlsx");
  };
  const handleDownloadPDF1 = () => {
    // Create a new jsPDF instance
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );
    const pdf = new jsPDF();

    // Define the table columns and styles
    // const columns = ["Parameter", "Value"];
    const options = {
      theme: "striped", // Apply a striped theme to the tables
      styles: { overflow: "linebreak" }, // Handle long text
    };

    // Create a data array for the payload table
    const payloadTableData = Object.entries(payload)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add the first table (payload) to the PDF
    pdf.text("Agent Coaching Service Levels", 10, 10);
    pdf.text(formattedDate, 10, 20);
    pdf.text("Parameters", 10, 30);
    pdf.autoTable(columns, payloadTableData, { ...options, startY: 40 });

    // Create a data array for the response table
    const responseTableData = Object.entries(response)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add a title for the second table (Summary)
    pdf.setFontSize(14);
    pdf.text("Summary", 10, pdf.autoTable.previous.finalY + 20);

    // Add the second table (response) below the title
    pdf.autoTable(columns, responseTableData, {
      ...options,
      startY: pdf.autoTable.previous.finalY + 30,
    });
    // Create a data array for the agent level data table
    const agentLevelTableData = agentCoachingLevelData?.map((item) => [
      item[`${groupByvalue}`],
      item["Total Evaluations"],
      item["QA Score"],
    ]);

    // Add a title for the third table (Agent Level Data)
    pdf.setFontSize(14);

    pdf.text("Reports", 10, pdf.autoTable.previous.finalY + 40);

    // Add the third table (agent level data) below the title
    pdf.autoTable(
      [`${groupByvalue}`, "Total Evaluations", "QA Score"],
      agentLevelTableData,
      { ...options, startY: pdf.autoTable.previous.finalY + 50 }
    );

    // Create a data array for the fourth table (total evaluation list data)
    pdf.save("Agent_Coaching_Service_Levels.pdf");
  };
  const handleDownloadPDF2 = () => {
    // Create a new jsPDF instance
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format(
      "dddd, MMMM D, YYYY [at] hh:mm A"
    );
    const pdf = new jsPDF();

    // Define the table columns and styles
    // const columns = ["Parameter", "Value"];
    const options = {
      theme: "striped", // Apply a striped theme to the tables
      styles: { overflow: "linebreak" }, // Handle long text
    };

    // Create a data array for the payload table
    const payloadTableData = Object.entries(payload)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add the first table (payload) to the PDF
    pdf.text("Agent Coaching Service Levels", 10, 10);
    pdf.text(formattedDate, 10, 20);
    pdf.text("Parameters", 10, 30);
    pdf.autoTable(columns, payloadTableData, { ...options, startY: 40 });

    // Create a data array for the response table
    const responseTableData = Object.entries(response)?.map(([key, value]) => [
      key,
      value,
    ]);

    // Add a title for the second table (Summary)
    pdf.setFontSize(14);
    pdf.text("Summary", 10, pdf.autoTable.previous.finalY + 20);

    // Add the second table (response) below the title
    pdf.autoTable(columns, responseTableData, {
      ...options,
      startY: pdf.autoTable.previous.finalY + 30,
    });
    // Create a data array for the agent level data table
    const agentLevelTableData = agentCoachingLevelData?.map((item) => [
      item[`${groupByvalue}`],
      item["Total Evaluations"],
      item["QA Score"],
    ]);

    // Add a title for the third table (Agent Level Data)
    pdf.setFontSize(14);

    // pdf.text("Reports", 10, pdf.autoTable.previous.finalY + 40);

    // // Add the third table (agent level data) below the title
    // pdf.autoTable([`${groupByvalue}`, "Total Evaluations", "QA Score"], agentLevelTableData, { ...options, startY: pdf.autoTable.previous.finalY + 50 });

    // Create a data array for the fourth table (total evaluation list data)
    if (thirdLevelView) {
      const totalEvaluationTableData = totalEvaluationListData?.map((item) => [
        item[`${firstHeader[0]}`],
        item[`${firstHeader[1]}`],
        item[`${firstHeader[2]}`],
        item[`${firstHeader[3]}`],
        item[`${firstHeader[4]}`],
        item[`${firstHeader[5]}`],
        item[`${firstHeader[6]}`],
        item[`${firstHeader[7]}`],
        item[`${firstHeader[8]}`],
        item[`${firstHeader[9]}`],
        item[`${firstHeader[10]}`],
        item[`${firstHeader[11]}`],
        item[`${firstHeader[12]}`],
        item[`${firstHeader[13]}`],
      ]);

      // Add a title for the fourth table (Total Evaluation List Data)
      pdf.setFontSize(14);
      pdf.text(
        "Agent Coaching Service Levels",
        10,
        pdf.autoTable.previous.finalY + 20
      );

      // Add the fourth table (total evaluation list data) below the title
      pdf.autoTable(
        [
          `${firstHeader[0]?.length > 0 ? firstHeader[0] : ""}`,
          `${firstHeader[1]?.length > 0 ? firstHeader[1] : ""}`,
          `${firstHeader[2]?.length > 0 ? firstHeader[2] : ""}`,
          `${firstHeader[3]?.length > 0 ? firstHeader[3] : ""}`,
          `${firstHeader[4]?.length > 0 ? firstHeader[4] : ""}`,
          `${firstHeader[5]?.length > 0 ? firstHeader[5] : ""}`,
          `${firstHeader[6]?.length > 0 ? firstHeader[6] : ""}`,
          `${firstHeader[7]?.length > 0 ? firstHeader[7] : ""}`,
          `${firstHeader[8]}`,
          `${firstHeader[9]?.length > 0 ? firstHeader[9] : ""}`,
          `${firstHeader[10]?.length > 0 ? firstHeader[10] : ""}`,
          `${firstHeader[11]?.length > 0 ? firstHeader[11] : ""}`,
          `${firstHeader[12]?.length > 0 ? firstHeader[12] : ""}`,
          `${firstHeader[13]?.length > 0 ? firstHeader[13] : ""}`,
        ],
        totalEvaluationTableData,
        { ...options, startY: pdf.autoTable.previous.finalY + 30 }
      );
    }
    pdf.save("Agent_Coaching_Service_Levels.pdf");
  };

  var filter_columns = reqColArrList?.join(",");
  filter_columns = filter_columns + "," + reqColMatArrList?.join(",");
  if (valueDragList2?.length > 0) {
    filter_columns = filter_columns + "," + valueDragList2.join(",");
  } else {
    console.log(filter_columns);
  }
  if (valueDragList4?.length > 0) {
    filter_columns = filter_columns + "," + valueDragList4.join(",");
  } else {
    console.log(filter_columns);
  }
  console.log("filterColumnnnsns", filter_columns);
  const scrollToSection = () => {
    const element = document.getElementById("scrollToSection");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };
  const scrollToSummary = () => {
    const element = document.getElementById("summaryScroll");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };

  const scrollToData2 = () => {
    const element = document.getElementById("scrollToData2");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };

  const allForms = formData?.map((item) => ({
    label: item?.display_name,
    value: item?.form_unique_id,
  }));
  const optionsManager2 = manager2Opt?.map((item) => ({
    label: item?.name,
    value: item?.userId,
  }));

  const optionsC1 = c1Data?.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsC2 = c2Data?.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsC3 = c3Data?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionsC4 = c4Data?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionsDeliveryList =
    scheduleGroupList?.length > 0 &&
    scheduleGroupList?.map((item) => ({
      value: item._id,
      label: item.group_name,
    }));
  const optionsFormData = formData?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionsManager1 = manager1?.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsFrequency = [
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
    { value: "weekly", label: "Weekly" },
  ];

  const agentOptions = [
    { value: "0-2 months", label: "0-2 months" },
    { value: "2-3 months", label: "2-3 months" },
    { value: "3-4 months", label: "3-4 months" },
    { value: "4-6 months", label: "4-6 months" },
    { value: "6-9 months", label: "6-9 months" },
    { value: "9-12 months", label: "9-12 months" },
    { value: "1-3 years", label: "1-3 years" },
    { value: "3-5 years", label: "3-5 years" },
    { value: "5+ years", label: "5+ years" },
  ];
  const Type_of_Evaluation = [
    { value: "Project", label: "Project" },
    { value: "QA", label: "QA" },
    { value: "PWIL", label: "PWIL" },
    { value: "Supervisor", label: "Supervisor" },
    { value: "Training", label: "Training" },
    { value: "Strategic Partner", label: "Strategic Partner" },
    { value: "TSC", label: "TSC" },
    { value: "Coaching", label: "Coaching" },
    { value: "Trainee", label: "Trainee" },
  ];
  const Customer_State = [
    { value: "AB - C", label: "AB - C" },
    { value: "AK", label: "AK" },
    { value: "AL", label: "AL" },
    { value: "AR", label: "AR" },
    { value: "AZ", label: "AZ" },
    { value: "BC - C", label: "BC - C" },
    { value: "CA", label: "CA" },
    { value: "CO", label: "CO" },
    { value: "CT", label: "CT" },
    { value: "DE", label: "DE" },
    { value: "FL", label: "FL" },
    { value: "GA", label: "GA" },
    { value: "HI", label: "HI" },
    { value: "IA", label: "IA" },
    { value: "ID", label: "ID" },
    { value: "IL", label: "IL" },
    { value: "IN", label: "IN" },
    { value: "KS", label: "KS" },
    { value: "KY", label: "KY" },
    { value: "LA", label: "LA" },
    { value: "MA", label: "MA" },
    { value: "MB - C", label: "MB - C" },
    { value: "MD", label: "MD" },
    { value: "ME", label: "ME" },
    { value: "MI", label: "MI" },
    { value: "MN", label: "MN" },
    { value: "MO", label: "MO" },
    { value: "MS", label: "MS" },
    { value: "MT", label: "MT" },
    { value: "NB - C", label: "NB - C" },
    { value: "NC", label: "NC" },
    { value: "ND", label: "ND" },
    { value: "NE", label: "NE" },
    { value: "NH", label: "NH" },
    { value: "NJ", label: "NJ" },
    { value: "NL - C", label: "NL - C" },
    { value: "NM", label: "NM" },
    { value: "NS - C", label: "NS - C" },
    { value: "NT - C", label: "NT - C" },
    { value: "NU - C", label: "NU - C" },
    { value: "NV", label: "NV" },
    { value: "NY", label: "NY" },
    { value: "OH", label: "OH" },
    { value: "OK", label: "OK" },
    { value: "ON - C", label: "ON - C" },
    { value: "OR", label: "OR" },
    { value: "PA", label: "PA" },
    { value: "PE - C", label: "PE - C" },
    { value: "QC - C", label: "QC - C" },
    { value: "RI", label: "RI" },
    { value: "SC", label: "SC" },
    { value: "SD", label: "SD" },
    { value: "SK - C", label: "SK - C" },
    { value: "TN", label: "TN" },
    { value: "TX", label: "TX" },
    { value: "UT", label: "UT" },
    { value: "VA", label: "VA" },
    { value: "VT", label: "VT" },
    { value: "WA", label: "WA" },
    { value: "WI", label: "WI" },
    { value: "WV", label: "WV" },
    { value: "WY", label: "WY" },
    { value: "YT - C", label: "YT - C" },
  ];
  const primaryReasonOption = [
    // { value: '', label: 'Select' },
  ];
  const evaluatorTypeOption = [
    // { value: '', label: 'Select' },
  ];
  const obStateOption = [];
  const tenureGroupOptioon = [];
  const mediaFileOption = [];
  const CRMData = [
    { value: "CRIS", label: "CRIS" },
    { value: "Ensemble", label: "Ensemble" },
    { value: "Not Available", label: "Not Available" },
  ];
  const languageOptions = [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
  ];
  const Call_Type = [
    { value: "Audio", label: "Audio" },
    { value: "Chat", label: "Chat" },
    { value: "Video", label: "Video" },
  ];

  let columnOptions;

  if (filter_columns && filter_columns?.length > 0) {
    columnOptions = [...new Set(filter_columns.split(","))];
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props} className="multiselect-comp">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label style={{ marginLeft: -6 }}>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const keys = Object.keys(response);
  //valueDragList2

  const columns = React.useMemo(
    () => [
      {
        Header: `${groupByvalue === undefined ? "" : groupByvalue}`,
        accessor: "group_by_val",
      },
      {
        Header: "QA Score",
        accessor: "QA_Score",
      },
      {
        Header: "Total Evaluations",
        accessor: "Total_Evaluations",
      },
      // {
      //     Header: 'Group by value',
      //     accessor: 'get_group_by_value',
      // },
      // {
      //     Header: 'Total Evaluation',
      //     accessor: 'get_total_evaluation',
      // },
    ],
    [groupByvalue]
  );

  const data = React.useMemo(() =>
    agentCoachingLevelData
      ?.filter((item) => {
        const lowercaseSearchValue = searchValue?.toLowerCase();
        const convertToLowerCase = (field) => {
          if (typeof field === "string") {
            return field.toLowerCase();
          } else if (Array.isArray(field)) {
            return field.join(", ").toLowerCase();
          } else {
            return "";
          }
        };

        const itemUserId = convertToLowerCase(item[groupByvalue]);
        const itemName = convertToLowerCase(item["QA Score"]);
        const itemC1 = convertToLowerCase(item["Total Evaluations"]);

        return (
          !lowercaseSearchValue ||
          itemUserId.includes(lowercaseSearchValue) ||
          itemName.includes(lowercaseSearchValue) ||
          itemC1.includes(lowercaseSearchValue)
        );
      })
      .map((i, j) => ({
        group_by_val: [<p className="text-nowrap">{i[`${groupByvalue}`]}</p>],
        QA_Score: [<p className="text-nowrap">{i["QA Score"]}</p>],
        Total_Evaluations: [
          <a
            className="text-nowrap font-weight-bold text-primary"
            onClick={() => showTotalEvaluationList(i[`${groupByvalue}`])}
          >
            {i["Total Evaluations"]}
          </a>,
        ],

        get_group_by_value: [
          <p className="text-nowrap">{i["get_group_by_value"]}</p>,
        ],
        // get_total_evaluation:[<p className="text-nowrap">{i["get_total_evaluation"]}</p>],
      }))
  );

  const columns2 = React.useMemo(
    () => [
      // totalEvaluationListData?.map((value,j) => ({
      //     Header: `${firstHeader[j]?.length > 0 ? firstHeader[j] : ''}`,
      //     accessor: `val${j}`
      //   })),

      {
        Header: `${firstHeader[0]?.length > 0 ? firstHeader[0] : ""}`,
        accessor: "val0",
        sortable: true,
      },
      {
        Header: `${firstHeader[1]?.length > 0 ? firstHeader[1] : ""}`,
        accessor: "val1",
        sortable: true,
      },
      {
        Header: `${firstHeader[2]?.length > 0 ? firstHeader[2] : ""}`,
        accessor: "val2",
        sortable: true,
      },
      {
        Header: `${firstHeader[3]?.length > 0 ? firstHeader[3] : ""}`,
        accessor: "val3",
        sortable: true,
      },
      {
        Header: `${firstHeader[4]?.length > 0 ? firstHeader[4] : ""}`,
        accessor: "val4",
        sortable: true,
      },
      {
        Header: `${firstHeader[5]?.length > 0 ? firstHeader[5] : ""}`,
        accessor: "val5",
        sortable: true,
      },
      {
        Header: `${firstHeader[6]?.length > 0 ? firstHeader[6] : ""}`,
        accessor: "val6",
        sortable: true,
      },
      {
        Header: `${firstHeader[7]?.length > 0 ? firstHeader[7] : ""}`,
        accessor: "val7",
        sortable: true,
      },
      {
        Header: `${firstHeader[8]?.length > 0 ? firstHeader[8] : ""}`,
        accessor: "val8",
        sortable: true,
      },
      {
        Header: `${firstHeader[9]?.length > 0 ? firstHeader[9] : ""}`,
        accessor: "val9",
        sortable: true,
      },
      {
        Header: `${firstHeader[10]?.length > 0 ? firstHeader[10] : ""}`,
        accessor: "val10",
        sortable: true,
      },
      {
        Header: `${firstHeader[11]?.length > 0 ? firstHeader[11] : ""}`,
        accessor: "val11",
        sortable: true,
      },
      {
        Header: `${firstHeader[12]?.length > 0 ? firstHeader[12] : ""}`,
        accessor: "val12",
        sortable: true,
      },
      {
        Header: `${firstHeader[13]?.length > 0 ? firstHeader[13] : ""}`,
        accessor: "val13",
        sortable: true,
      },
    ],
    [totalEvaluationListData]
  );

  const data2 = React.useMemo(() =>
    totalEvaluationListData
      ?.filter((item) => {
        const lowercaseSearchValue = searchValue2?.toLowerCase();
        const convertToLowerCase = (field) => {
          if (typeof field === "string") {
            return field.toLowerCase();
          } else if (Array.isArray(field)) {
            return field.join(", ").toLowerCase();
          } else {
            return "";
          }
        };

        const itemUserId = convertToLowerCase(item[firstHeader[0]]);
        const item1 = convertToLowerCase(item[firstHeader[1]]);
        const item2 = convertToLowerCase(item[firstHeader[2]]);
        const item3 = convertToLowerCase(item[firstHeader[3]]);
        const item4 = convertToLowerCase(item[firstHeader[4]]);
        const item5 = convertToLowerCase(item[firstHeader[5]]);
        const item6 = convertToLowerCase(item[firstHeader[6]]);
        const item7 = convertToLowerCase(item[firstHeader[7]]);
        const item8 = convertToLowerCase(item[firstHeader[8]]);
        const item9 = convertToLowerCase(item[firstHeader[9]]);
        const item10 = convertToLowerCase(item[firstHeader[10]]);

        return (
          !lowercaseSearchValue ||
          itemUserId.includes(lowercaseSearchValue) ||
          item1.includes(lowercaseSearchValue) ||
          item2.includes(lowercaseSearchValue) ||
          item3.includes(lowercaseSearchValue) ||
          item4.includes(lowercaseSearchValue) ||
          item5.includes(lowercaseSearchValue) ||
          item6.includes(lowercaseSearchValue) ||
          item8.includes(lowercaseSearchValue) ||
          item9.includes(lowercaseSearchValue) ||
          item10.includes(lowercaseSearchValue) ||
          item7.includes(lowercaseSearchValue)
        );
      })
      ?.map(
        (i, j) => ({
          val0: [
            firstHeader[0] === "Call ID" ? (
              <p className="text-nowrap td-link">
                <Link to="#">{i[`${firstHeader[0]}`]}</Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[0]}`]}</p>
            ),
          ],
          val1: [
            firstHeader[1] === "Call ID" ? (
              <p className="text-nowrap td-link">
                <Link to="#">{i[`${firstHeader[1]}`]}</Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[1]}`]}</p>
            ),
          ],
          val2: [
            firstHeader[2] === "Call ID" ? (
              <p className="text-nowrap td-link">
                <Link to="#">{i[`${firstHeader[2]}`]}</Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[2]}`]}</p>
            ),
          ],
          val3: [
            firstHeader[3] === "Call ID" ? (
              <p className="text-nowrap td-link">
                <Link to="#">{i[`${firstHeader[3]}`]}</Link>
              </p>
            ) : (
              <p className="text-nowrap">{i[`${firstHeader[3]}`]}</p>
            ),
          ],
          val4: [<p className="text-nowrap">{i[`${firstHeader[4]}`]}</p>],
          val5: [<p className="text-nowrap">{i[`${firstHeader[5]}`]}</p>],
          val6: [<p className="text-nowrap">{i[`${firstHeader[6]}`]}</p>],
          val7: [<p className="text-nowrap">{i[`${firstHeader[7]}`]}</p>],
          val8: [<p className="text-nowrap">{i[`${firstHeader[8]}`]}</p>],
          val9: [<p className="text-nowrap">{i[`${firstHeader[9]}`]}</p>],
          val10: [<p className="text-nowrap">{i[`${firstHeader[10]}`]}</p>],
          val11: [<p className="text-nowrap">{i[`${firstHeader[11]}`]}</p>],
          val12: [<p className="text-nowrap">{i[`${firstHeader[12]}`]}</p>],
          val13: [<p className="text-nowrap">{i[`${firstHeader[13]}`]}</p>],
        }),
        [totalEvaluationListData]
      )
  );

  return (
    <>
      <main>
        {/* main-contents start */}
        <ToastContainer />
        <div className="main-contents">
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div className="alert alert-info text-center" id="success_msg" />
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg" />
            </div>
          </div>
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                  <span>Reports</span>
                </h3>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <span> Smartspeech</span>
              </li>
              <li className="breadcrumb-item active">
                <span>Reports</span>
              </li>
            </ul>
          </nav>

          <div className="report-filter" data-reference="reports_common_filter">
            <div className="container-fluid" style={{ paddingBottom: "2rem" }}>
              <div className="report-filter-inner">
                <ul className="nav nav-tabs" id="reportTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="report01-tab"
                      data-toggle="tab"
                      href="#report01"
                      role="tab"
                      aria-controls="report01"
                      aria-selected="true"
                    >
                      Date
                    </a>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report02-tab"
                      data-toggle="tab"
                      href="#report02"
                      role="tab"
                      aria-controls="report02"
                      aria-selected="false"
                    >
                      Filter
                    </a>
                  </li> */}
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="report03-tab"
                      data-toggle="tab"
                      href="#report03"
                      role="tab"
                      aria-controls="report03"
                      aria-selected="false"
                    >
                      Custom Filter
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="reportContent">
                  <div
                    className="tab-pane fade show active"
                    id="report01"
                    role="tabpanel"
                    aria-labelledby="report01-tab"
                  >
                    <div className="tab-pane-part">
                      <h3 className="tab-pane-title mt-0">
                        Date <span className="mandatory">*</span>
                      </h3>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_day"
                          data-get="1 Day"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Day"
                          checked={dateRangeValue == "1 Day"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_day"
                          className="custom-control-label"
                        >
                          1 Day
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_dayweek"
                          data-get="Week to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Week to Date"
                          checked={dateRangeValue == "Week to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_dayweek"
                          className="custom-control-label"
                        >
                          Week to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_week1"
                          data-get="1 Week"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Week"
                          checked={dateRangeValue == "1 Week"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_week1"
                          className="custom-control-label"
                        >
                          1 Week
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_week2"
                          data-get="2 Week"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="2 Week"
                          checked={dateRangeValue == "2 Week"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_week2"
                          className="custom-control-label"
                        >
                          2 Week
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_daymonth"
                          data-get="Month to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Month to Date"
                          checked={dateRangeValue == "Month to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_daymonth"
                          className="custom-control-label"
                        >
                          Month to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_monthprev"
                          data-get="Previous Month"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Previous Month"
                          checked={dateRangeValue == "Previous Month"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_monthprev"
                          className="custom-control-label"
                        >
                          Previous Month
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_month1"
                          data-get="1 Month"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Month"
                          checked={dateRangeValue == "1 Month"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_month1"
                          className="custom-control-label"
                        >
                          1 Month
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_month2"
                          data-get="2 Month"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="2 Month"
                          checked={dateRangeValue == "2 Month"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_month2"
                          className="custom-control-label"
                        >
                          2 Month
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_dayquarter"
                          data-get="Quarter to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Quarter to Date"
                          checked={dateRangeValue == "Quarter to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_dayquarter"
                          className="custom-control-label"
                        >
                          Quarter to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_dayyear"
                          data-get="Year to Date"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="Year to Date"
                          checked={dateRangeValue == "Year to Date"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_dayyear"
                          className="custom-control-label"
                        >
                          Year to Date
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_year1"
                          data-get="1 Year"
                          name="filter_date"
                          className="custom-control-input"
                          defaultValue="1 Year"
                          checked={dateRangeValue == "1 Year"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_year1"
                          className="custom-control-label"
                        >
                          1 Year
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="filter_datecustom"
                          data-get="Custom Date"
                          name="filter_date"
                          defaultValue="filter_customdate"
                          className="custom-control-input"
                          checked={dateRangeValue == "filter_customdate"}
                          onChange={handleDateRangeChange}
                        />
                        <label
                          htmlFor="filter_datecustom"
                          className="custom-control-label"
                        >
                          Custom Date
                        </label>
                      </div>

                      <div className="form-row">
                        <div
                          className={`form-group col-sm-6 col-md-4 col-lg-3 filter_dates ${
                            dateRangeValue === "filter_customdate"
                              ? ""
                              : "d-none"
                          }`}
                        >
                          <label>
                            <span>From Date</span>
                          </label>
                          <input
                            type="date"
                            className="form-control custom-date-input"
                            max={toDate}
                            placeholder="From Date"
                            required
                            onChange={(e) => setFromDateFunc(e.target.value)}
                            value={fromDate}
                          />
                        </div>

                        <div
                          className={`form-group col-sm-6 col-md-4 col-lg-3 filter_dates ${
                            dateRangeValue === "filter_customdate"
                              ? ""
                              : "d-none"
                          }`}
                        >
                          <label>
                            <span>To Date</span>
                          </label>
                          <input
                            type="date"
                            className="form-control custom-date-input"
                            min={fromDate}
                            max={today}
                            placeholder="To Date"
                            required
                            onChange={(e) => setToDateFunc(e.target.value)}
                            value={toDate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="report03"
                    role="tabpanel"
                    aria-labelledby="report03-tab"
                  >
                    <div className="form-row">
                      {/* <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                      <label>
                          <span>Folders</span>
                        </label>

                        <Select
                          options={folderListData}
                          placeholder="Select Folder"
                          className="multiSelect"
                          isSearchable={true}
                          isMulti
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              height: "32px!important",
                              fontSize: "11px",
                              minHeight: 0,
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // valueContainer:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            placeholder: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // input:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                            ValueContainer: (provided) => ({
                              ...provided,
                              // marginTop: "-2px",
                            }),
                          }}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          components={{
                            Option,
                            ValueContainer: ValueContainer2,
                          }}
                          value={folderValue}
                          onChange={(e, event) => handleFolderChange(e, event)}
                          
                        />
                      </div> */}
                      <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                        <label>
                          <span>Type of Evaluation</span>
                        </label>

                        <select
                          className="form-control "
                          onChange={(e) => onChangeTypEval(e)}
                        >
                          <option selected={true} value="All">
                            All
                          </option>
                          <option value="speech_analytics">
                            Speech Analytics
                          </option>
                          <option value="manual_qa">Manual QA</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                        <label className="form-label">
                          <span>Call Duration</span>
                        </label>
                        <MultiRangeSlider
                          style={{ height: "15vh" }}
                          defaultMaxFlag={true}
                          className="max-parent"
                          min={0}
                          type="time"
                          maxButton={onMaxClick}
                          max={1800000}
                          setMaxFlag={setMaxFlag}
                          maxFlag={maxFlag}
                          onChange={({ min, max }) =>
                            onChangeDuration(min, max)
                          }
                        />
                      </div>
                      {typeEval === "All" || typeEval === "speech_analytics" ? (
                        <>
                          <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                            <label className="form-label">
                              <span>Silence %</span>
                            </label>
                            <MultiRangeSlider
                              rangeLabel={"%"}
                              min={0}
                              max={100}
                              onChange={({ min, max }) =>
                                onChangeSlienceTime(min, max)
                              }
                            />
                          </div>

                          <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                            <label className="form-label">
                              <span>Agent Emotion</span>
                            </label>
                            <Select
                              options={emotionOptions}
                              className="multiSelect"
                              placeholder="Select Emotion"
                              isSearchable={true}
                              isMulti
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: "100px",
                                  height: "30px!important",
                                  fontSize: "11px",
                                  minHeight: 0,
                                  boxShadow: "none", // Remove the blue box shadow
                                  borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                  "&:hover": {
                                    borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                  },
                                  "&:focus": {
                                    outline: "none", // Remove the blue outline on focus
                                    borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                    boxShadow: "none", // Remove the blue box shadow on focus
                                  },
                                  "&:active": {
                                    borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                    boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                  },
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                clearIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),

                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // valueContainer:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // input:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                option: (provided, state) => ({
                                  ...provided,
                                  background: state.isSelected
                                    ? "#fff"
                                    : "transparent", // Remove background highlight from non-selected options
                                  color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                }),
                              }}
                              components={{
                                Option,
                                ValueContainer: ValueContainerAgentEmotion,
                              }}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              allowSelectAll={true}
                              value={agentEmotion}
                              onChange={(e, event) =>
                                handleAgentEmotion(e, event)
                              }
                            />
                          </div>

                          <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                            <label className="form-label">
                              <span>Client Emotion</span>
                            </label>
                            <Select
                              options={emotionOptions}
                              className="multiSelect"
                              placeholder="Select Emotion"
                              isSearchable={true}
                              isMulti
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: "100px",
                                  height: "30px!important",
                                  fontSize: "11px",
                                  minHeight: 0,
                                  boxShadow: "none", // Remove the blue box shadow
                                  borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                  "&:hover": {
                                    borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                  },
                                  "&:focus": {
                                    outline: "none", // Remove the blue outline on focus
                                    borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                    boxShadow: "none", // Remove the blue box shadow on focus
                                  },
                                  "&:active": {
                                    borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                    boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                  },
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                clearIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),

                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // valueContainer:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // input:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                option: (provided, state) => ({
                                  ...provided,
                                  background: state.isSelected
                                    ? "#fff"
                                    : "transparent", // Remove background highlight from non-selected options
                                  color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                }),
                              }}
                              components={{
                                Option,
                                ValueContainer: ValueContainerClientEmotion,
                              }}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              allowSelectAll={true}
                              value={clientEmotion}
                              onChange={(e, event) =>
                                handleClientEmotion(e, event)
                              }
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                        <label className="form-label">
                          <span>Standard Meta data</span>
                        </label>
                        <Select
                          options={standMetaData}
                          className="multiSelect"
                          placeholder="Select Standard Meta Data"
                          isSearchable={true}
                          isMulti
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              height: "30px!important",
                              fontSize: "11px",
                              minHeight: 0,
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            clearIndicator: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),

                            dropdownIndicator: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // valueContainer:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            placeholder: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // input:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainerStandardMeta,
                          }}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          value={standardMeta}
                          onChange={(e, event) => handleStandardMeta(e, event)}
                        />
                      </div>
                      <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                        <label className="form-label">
                          <span>Custom Meta data</span>
                        </label>
                        <Select
                          options={customMetaData}
                          className="multiSelect"
                          placeholder="Select Custom Meta Data"
                          isSearchable={true}
                          isMulti
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              height: "30px!important",
                              fontSize: "11px",
                              minHeight: 0,
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            clearIndicator: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),

                            dropdownIndicator: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // valueContainer:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            placeholder: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // input:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer: ValueContainerCustomMeta,
                          }}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          value={customMeta}
                          onChange={(e, event) => handleCustomMeta(e, event)}
                        />
                      </div> */}

                      {typeEval === "All" || typeEval === "speech_analytics" ? (
                        <>
                          {/* <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                            <label className="form-label">
                              <span>Call Driver</span>
                            </label>
                            <Select
                              options={callDriverData}
                              className="multiSelect"
                              placeholder="Select Call Driver"
                              isSearchable={true}
                              isMulti
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: "100px",
                                  height: "30px!important",
                                  fontSize: "11px",
                                  minHeight: 0,
                                  boxShadow: "none", // Remove the blue box shadow
                                  borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                  "&:hover": {
                                    borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                  },
                                  "&:focus": {
                                    outline: "none", // Remove the blue outline on focus
                                    borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                    boxShadow: "none", // Remove the blue box shadow on focus
                                  },
                                  "&:active": {
                                    borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                    boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                  },
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                clearIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),

                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // valueContainer:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // input:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                option: (provided, state) => ({
                                  ...provided,
                                  background: state.isSelected
                                    ? "#fff"
                                    : "transparent", // Remove background highlight from non-selected options
                                  color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                }),
                              }}
                              components={{
                                Option,
                                ValueContainer: valueCallDriverContainer,
                              }}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              allowSelectAll={true}
                              value={callDriver}
                              onChange={(e, event) =>
                                handleCallDriver(e, event)
                              }
                            />
                          </div>

                          <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                            <label className="form-label">
                              <span>DSAT</span>
                            </label>
                            <select
                              className="form-control "
                              onChange={(e) =>
                                setDSAT(
                                  e.target.value !== "select"
                                    ? e.target.value
                                    : ""
                                )
                              }
                            >
                              <option selected={true} value="select">
                                Select
                              </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>

                          <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                            <label className="form-label">
                              <span>Compliance Adherence</span>
                            </label>

                            <select
                              className="form-control "
                              onChange={(e) =>
                                setCompAdherence(
                                  e.target.value !== "select"
                                    ? e.target.value
                                    : ""
                                )
                              }
                            >
                              <option selected={true} value="select">
                                Select
                              </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div> */}

                          <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                            <label className="form-label">
                              <span>List of All Applications</span>
                            </label>

                            <Select
                              options={appListData}
                              className="multiSelect"
                              placeholder="Select Application"
                              isSearchable={true}
                              isMulti
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: "100px",
                                  height: "30px!important",
                                  fontSize: "11px",
                                  minHeight: 0,
                                  boxShadow: "none", // Remove the blue box shadow
                                  borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                  "&:hover": {
                                    borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                  },
                                  "&:focus": {
                                    outline: "none", // Remove the blue outline on focus
                                    borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                    boxShadow: "none", // Remove the blue box shadow on focus
                                  },
                                  "&:active": {
                                    borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                    boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                  },
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                clearIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),

                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // valueContainer:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  marginTop: "-4px",
                                }),
                                // input:(provided)=>({
                                //   ...provided,
                                //   marginTop:"-4px"
                                // }),
                                option: (provided, state) => ({
                                  ...provided,
                                  background: state.isSelected
                                    ? "#fff"
                                    : "transparent", // Remove background highlight from non-selected options
                                  color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                }),
                              }}
                              components={{
                                Option,
                                ValueContainer: valueAllAppContainer,
                              }}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              allowSelectAll={true}
                              value={application}
                              onChange={(e, event) =>
                                handleAppChange(e, event)
                              }
                            />
                          </div>
                          {
                       (process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3fo") &&
                       
                       <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                        <label>
                          <span>Hunt Group</span>
                        </label>
                        <Select
                          options={huntGroupData}
                          value={selectedHuntData}
                           
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                              minHeight:0,
                              height: "29px!important",
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            clearIndicator: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),

                            dropdownIndicator: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // valueContainer:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            placeholder: (provided) => ({
                              ...provided,
                              marginTop: "-4px",
                            }),
                            // input:(provided)=>({
                            //   ...provided,
                            //   marginTop:"-4px"
                            // }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                        
                          }}
                          
                          components={{
                            Option,
                            ValueContainer: ValueContainer5,
                          }}
                          isMulti
                          placeholder="Select"
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          //value={formData}
                          onChange={(e,event) => [
                            handleHuntGroupData(e,event),
                            // setSelectedHuntData(e?.map((i) => i?.value)),
                            setSelectedVendorData(""),
                            setSelectedLocData(""),
                          ]}
                        />
                      </div>
                    }
                          {/* {appCatData?.length > 0 ? (
                            <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                              <label className="form-label">
                                <span>Application Category</span>
                              </label>

                              <Select
                                options={appCatData}
                                className="multiSelect"
                                placeholder="Select Category"
                                isSearchable={true}
                                isMulti
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: "100px",
                                    height: "30px!important",
                                    fontSize: "11px",
                                    minHeight: 0,
                                    boxShadow: "none", // Remove the blue box shadow
                                    borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                    "&:hover": {
                                      borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                    },
                                    "&:focus": {
                                      outline: "none", // Remove the blue outline on focus
                                      borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                      boxShadow: "none", // Remove the blue box shadow on focus
                                    },
                                    "&:active": {
                                      borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                      boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                    },
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  clearIndicator: (provided) => ({
                                    ...provided,
                                    marginTop: "-4px",
                                  }),

                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    marginTop: "-4px",
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    marginTop: "-4px",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    background: state.isSelected
                                      ? "#fff"
                                      : "transparent", // Remove background highlight from non-selected options
                                    color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                  }),
                                }}
                                components={{
                                  Option,
                                  ValueContainer: valueAppCatContainer,
                                }}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isClearable={true}
                                allowSelectAll={true}
                                value={category}
                                onChange={(e, event) =>
                                  handleCategoryChange(e, event)
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )} */}

                          {/* {typeEval === "All" ? (
                            <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                              <label className="form-label">
                                <span>Hot Words</span>
                              </label>

                              <Select
                                options={
                                  typeEval == "All"
                                    ? hotWordsData
                                    : hotWordsNormal
                                }
                                className="multiSelect"
                                placeholder="Select Hot Words"
                                isSearchable={true}
                                isMulti
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: "100px",
                                    height: "30px!important",
                                    fontSize: "11px",
                                    minHeight: 0,
                                    boxShadow: "none", // Remove the blue box shadow
                                    borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                    "&:hover": {
                                      borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                    },
                                    "&:focus": {
                                      outline: "none", // Remove the blue outline on focus
                                      borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                      boxShadow: "none", // Remove the blue box shadow on focus
                                    },
                                    "&:active": {
                                      borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                      boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                    },
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  clearIndicator: (provided) => ({
                                    ...provided,
                                    marginTop: "-4px",
                                  }),

                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    marginTop: "-4px",
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    marginTop: "-4px",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    background: state.isSelected
                                      ? "#fff"
                                      : "transparent", // Remove background highlight from non-selected options
                                    color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                  }),
                                }}
                                components={{
                                  Option,
                                  ValueContainer: valueHotWordsContainer,
                                }}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isClearable={true}
                                allowSelectAll={true}
                                value={hotWords}
                                onChange={(e, event) =>
                                  handleHotWords(e, event)
                                }
                              />
                            </div>
                          ) : (
                            <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                              <label className="form-label">
                                <span>Hot Words</span>
                              </label>
                              <select
                                className="form-control "
                                onChange={(e) =>
                                  setOptionHotWord(e.target.value)
                                }
                              >
                                <option selected={true} value="yes">
                                  Yes
                                </option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          )} */}

                          {/* {typeEval !== "manual_qa" ? (
                            <>
                              <div className="col-md-3 col-lg-3 col-sm-3 form-group">
                                <label className="form-label">
                                  <span>Application Hit Phrases</span>
                                </label>
                                <select
                                  className="form-control "
                                  onChange={(e) =>
                                    setAppHitPhrase(e.target.value)
                                  }
                                >
                                  <option selected={true} value="yes">
                                    Yes
                                  </option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </>
                          ) : (
                            ""
                          )} */}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <hr className="mb-3" />

                <div className="form-row">
                  <div className="col-md-12 col-lg-12 justify-content-center d-flex align-items-center mb-3">
                  {/* <Tippy placement='bottom' content="Check Report Status Here !">
                    <button
                      type="button"
                      className={`btn btn-primary filter_submit ${
                        reportStatusBtnLoader ? "loading" : ""
                      }`}
                      onClick={checkReportStatus}
                      style={{marginRight:8}}
                    >
                      <span><i class="las la-redo-alt"></i></span>
                      <span className="loader">
                        <span
                          className="spinner-border"
                          style={{width:15,height:15,margin:"-8px 0 0 -8px"}}
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    </button>
                    </Tippy> */}
                    <Tippy placement='bottom' content={submitBtnStatus && "Once Current report will download, you can submit next report !"}></Tippy>
                    <button
                      type="button"
                      // disabled={submitBtnStatus}
                      className={`btn btn-primary filter_submit ${
                        submitBtnLoader ? "loading" : ""
                      }`}
                      onClick={submitReport}
                    >
                      <span>Submit</span>
                      <span className="loader">
                        <span
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    </button>

                     
                    {/* {reportStatus &&  (
                    <button
                      type="button"
                      className={`btn btn-primary filter_submit ${
                        downloadBtnLoader ? "loading" : ""
                      }`}
                      style={{marginLeft:8}}
                        onClick={downloadExcelReport}
                    >
                      <span>Download Report</span>
                      <span className="loader">
                        <span
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    </button>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {downloadBtnLoader && (
          <div className="page-loader align-items-center justify-content-center loading">
            <div className="loader-dots d-flex align-items-center justify-content-center">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default Reports;
