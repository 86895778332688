import React from "react";
import $, { data } from "jquery";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { ErrorNotify, SuccessNotify } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import ReactFC from "react-fusioncharts";

import moment from "moment";
import Select from "react-select";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import {
  Chart,
  getElementAtEvent,
  Line,
  Bar,
  Doughnut,
  Scatter,
  Pie,
} from "react-chartjs-2";
import { WordCloudController, WordElement } from "chartjs-chart-wordcloud";
import "../../../shared/Style.css";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import DataTableView from "../../../shared/DataTable";
// import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

var csvReport = {
  data: [],
};
var subCatLabels = [];
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};
var subCatsData = [];
let totCall = "";
let sub_cat_call_ids_val = [];
let catBgColor = "";
let headers = [
  { label: "File Name", key: "wav" },
  { label: "Agent ID", key: "agentid" },
  { label: "Time", key: "datetime" },
  { label: "Duration", key: "duration" },
  { label: "Silence Time", key: "silence_percentage" },
  { label: "Overtalk", key: "overtalk" },
  { label: "Diarization", key: "diarization" },
  { label: "Agent Clarity", key: "agent_clarity" },
  { label: "Agent Gender", key: "agent_gender" },
  { label: "Client Gender", key: "client_gender" },
  { label: "Overall Emotion", key: "overall_emotion" },
  { label: "Agent Emotion", key: "agent_emotion" },
  { label: "Client Emotion", key: "client_emotion" },
  { label: "Transcript ID", key: "tran_id" },
  { label: "Request ID", key: "request_id" },
  { label: "Score", key: "score" },
];

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  // hoverLabel,
  WordElement,
  WordCloudController
);

const scttr_options = {
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      beginAtZero: true,
    },
  },
};

const countArr = [3, 2, 20, 1, 14, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const hourLabels = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm",
];

const barData = {
  labels: [
    "jan",
    "feb",
    "march",
    "april",
    "may",
    "june",
    "july",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ],
  datasets: [
    {
      label: "My First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(201, 203, 207, 0.2)",
      ],
      borderColor: [
        "rgb(255, 99, 132)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(153, 102, 255)",
        "rgb(201, 203, 207)",
      ],
      borderWidth: 1,
    },
  ],
};

const pieData = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      label: "My First Dataset",
      data: [300, 50, 100],
      backgroundColor: [
        "rgb(255, 99, 132)",
        "rgb(54, 162, 235)",
        "rgb(255, 205, 86)",
      ],
      hoverOffset: 4,
    },
  ],
};

const pieConfig = {
  type: "pie",
  data: pieData,
};

const scttr_data = {
  datasets: [
    {
      label: "A dataset",
      data: [
        { x: 10, y: 5 },
        { x: 1, y: 3 },
        { x: 6, y: 9 },
        { x: 4, y: 10 },

        { x: 4, y: 1 },
        { x: 5, y: 6 },
        { x: 6, y: 8 },
        { x: 1, y: 3 },

        { x: 2, y: 5 },
        { x: 3, y: 3 },
        { x: 7, y: 9 },
        { x: 4, y: 10 },
      ],
      backgroundColor: "rgba(255, 99, 132, 1)",
    },
  ],
};
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const today = new Date();
const todaydate =
  months[today.getMonth()] + " " + today.getDate() + ", " + today.getFullYear();

const options = {
  responsive: true,
  maxBarThickness: 80,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: false,
      text: "",
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          // console.log('context=====', context)
        },
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      beginAtZero: true,
    },
  },
};

const words = [
  { key: "word", value: 10 },
  { key: "words", value: 8 },
  { key: "sprite", value: 7 },
  { key: "placed", value: 5 },
  { key: "layout", value: 4 },
  { key: "algorithm", value: 4 },
  { key: "area", value: 4 },
  { key: "without", value: 3 },
  { key: "step", value: 3 },
  { key: "bounding", value: 3 },
  { key: "retrieve", value: 3 },
  { key: "operation", value: 3 },
  { key: "collision", value: 3 },
  { key: "candidate", value: 3 },
  { key: "32", value: 2 },
  { key: "placement", value: 2 },
  { key: "time", value: 2 },
  { key: "possible", value: 2 },
  { key: "even", value: 2 },
  { key: "simple", value: 2 },
  { key: "starting", value: 2 },
  { key: "previously", value: 2 },
  { key: "move", value: 2 },
  { key: "perform", value: 2 },
  { key: "hierarchical", value: 2 },
  { key: "draw", value: 2 },
  { key: "pixel", value: 2 },
  { key: "data", value: 2 },
  { key: "separately", value: 2 },
  { key: "expensive", value: 2 },
  { key: "pixels", value: 2 },
  { key: "masks", value: 2 },
  { key: "implementation", value: 2 },
  { key: "detection", value: 2 },
  { key: "larger", value: 2 },
  { key: "whole", value: 2 },
  { key: "comparing", value: 2 },
  { key: "box", value: 2 },
  { key: "large", value: 2 },
  { key: "think", value: 2 },
  { key: "version", value: 2 },
  { key: "single", value: 2 },
  { key: "tree", value: 2 },
  { key: "Cloud", value: 1 },
  { key: "Generator", value: 1 },
  { key: "Works", value: 1 },
  { key: "positioning", value: 1 },
  { key: "overlap", value: 1 },
  { key: "available", value: 1 },
  { key: "GitHub", value: 1 },
  { key: "open", value: 1 },
  { key: "source", value: 1 },
  { key: "license", value: 1 },
  { key: "d3cloud", value: 1 },
  { key: "Note", value: 1 },
  { key: "code", value: 1 },
  { key: "converting", value: 1 },
  { key: "text", value: 1 },
  { key: "rendering", value: 1 },
  { key: "final", value: 1 },
  { key: "output", value: 1 },
  { key: "requires", value: 1 },
  { key: "additional", value: 1 },
  { key: "development", value: 1 },
  { key: "quite", value: 1 },
  { key: "slow", value: 1 },
  { key: "hundred", value: 1 },
  { key: "run", value: 1 },
  { key: "asynchronously", value: 1 },
  { key: "configurable", value: 1 },
  { key: "size", value: 1 },
  { key: "makes", value: 1 },
  { key: "animate", value: 1 },
  { key: "stuttering", value: 1 },
  { key: "recommended", value: 1 },
  { key: "always", value: 1 },
  { key: "use", value: 1 },
  { key: "animations", value: 1 },
  { key: "prevents", value: 1 },
  { key: "browsers", value: 1 },
  { key: "event", value: 1 },
  { key: "loop", value: 1 },
  { key: "blocking", value: 1 },
  { key: "placing", value: 1 },
  { key: "incredibly", value: 1 },
  { key: "important", value: 1 },
  { key: "Attempt", value: 1 },
  { key: "place", value: 1 },
  { key: "point", value: 1 },
  { key: "usually", value: 1 },
  { key: "near", value: 1 },
  { key: "middle", value: 1 },
  { key: "somewhere", value: 1 },
  { key: "central", value: 1 },
  { key: "horizontal", value: 1 },
  { key: "line", value: 1 },
  { key: "intersects", value: 1 },
  { key: "one", value: 1 },
  { key: "along", value: 1 },
  { key: "increasing", value: 1 },
  { key: "spiral", value: 1 },
  { key: "Repeat", value: 1 },
  { key: "intersections", value: 1 },
  { key: "found", value: 1 },
  { key: "hard", value: 1 },
  { key: "part", value: 1 },
  { key: "making", value: 1 },
  { key: "efficiently", value: 1 },
  { key: "According", value: 1 },
  { key: "Jonathan", value: 1 },
  { key: "Feinberg", value: 1 },
  { key: "Wordle", value: 1 },
  { key: "uses", value: 1 },
  { key: "combination", value: 1 },
  { key: "boxes", value: 1 },
  { key: "quadtrees", value: 1 },
  { key: "achieve", value: 1 },
  { key: "reasonable", value: 1 },
  { key: "speeds", value: 1 },
  { key: "Glyphs", value: 1 },
  { key: "JavaScript", value: 1 },
  { key: "isnt", value: 1 },
  { key: "way", value: 1 },
  { key: "precise", value: 1 },
  { key: "glyph", value: 1 },
  { key: "shapes", value: 1 },
  { key: "via", value: 1 },
  { key: "DOM", value: 1 },
  { key: "except", value: 1 },
  { key: "perhaps", value: 1 },
  { key: "SVG", value: 1 },
  { key: "fonts", value: 1 },
  { key: "Instead", value: 1 },
  { key: "hidden", value: 1 },
  { key: "canvas", value: 1 },
  { key: "element", value: 1 },
  { key: "Retrieving", value: 1 },
  { key: "many", value: 1 },
  { key: "batch", value: 1 },
  { key: "Sprites", value: 1 },
  { key: "initial", value: 1 },
  { key: "performed", value: 1 },
  { key: "using", value: 1 },
  { key: "doesnt", value: 1 },
  { key: "copy", value: 1 },
  { key: "appropriate", value: 1 },
  { key: "position", value: 1 },
  { key: "representing", value: 1 },
  { key: "advantage", value: 1 },
  { key: "involves", value: 1 },
  { key: "relevant", value: 1 },
  { key: "rather", value: 1 },
  { key: "previous", value: 1 },
  { key: "Somewhat", value: 1 },
  { key: "surprisingly", value: 1 },
  { key: "lowlevel", value: 1 },
  { key: "hack", value: 1 },
  { key: "made", value: 1 },
  { key: "tremendous", value: 1 },
  { key: "difference", value: 1 },
  { key: "constructing", value: 1 },
  { key: "compressed", value: 1 },
  { key: "blocks", value: 1 },
  { key: "1bit", value: 1 },
  { key: "32bit", value: 1 },
  { key: "integers", value: 1 },
  { key: "thus", value: 1 },
  { key: "reducing", value: 1 },
  { key: "number", value: 1 },
  { key: "checks", value: 1 },
  { key: "memory", value: 1 },
  { key: "times", value: 1 },
  { key: "fact", value: 1 },
  { key: "turned", value: 1 },
  { key: "beat", value: 1 },
  { key: "quadtree", value: 1 },
  { key: "everything", value: 1 },
  { key: "tried", value: 1 },
  { key: "areas", value: 1 },
  { key: "font", value: 1 },
  { key: "sizes", value: 1 },
  { key: "primarily", value: 1 },
  { key: "needs", value: 1 },
  { key: "test", value: 1 },
  { key: "per", value: 1 },
  { key: "whereas", value: 1 },
  { key: "compare", value: 1 },
  { key: "every", value: 1 },
  { key: "overlaps", value: 1 },
  { key: "slightly", value: 1 },
  { key: "Another", value: 1 },
  { key: "possibility", value: 1 },
  { key: "merge", value: 1 },
  { key: "fairly", value: 1 },
  { key: "though", value: 1 },
  { key: "compared", value: 1 },
  { key: "analagous", value: 1 },
  { key: "mask", value: 1 },
  { key: "essentially", value: 1 },
  { key: "ORing", value: 1 },
  { key: "block", value: 1 },
];

// var maxValueIndex='1';

const options1 = {
  indexAxis: "y",
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  maxBarThickness: 80,
  scales: {
    // x: {
    //     stacked: true,
    //     beginAtZero: true,
    //     ticks: {
    //         callback: function(value, index, values) {
    //             {console.log("shadow",values.length-1)}
    //             // Show only two labels: 0 and the maximum value
    //             if (index === 0 ) {
    //                 return value.toString();
    //             }
    //             if( index === values.length - 1){
    //                 return maxValueIndex.toString();
    //             }
    //             return ''; // Hide other labels
    //         }
    //     }
    // },
    x: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        callback: function (value, index, values) {
          // Show only two labels: 0 and the maximum value
          if (index === 0 || index === values.length - 1) {
            return Math.ceil(parseFloat(value)).toString();
          }
          return ""; // Hide other labels
        },
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      // ticks: {
      //     callback: function(value, index, values) {
      //         return value ;
      //     }
      // }
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const options11 = {
  indexAxis: "y",

  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  maxBarThickness: 80,
  scales: {
    x: {
      stacked: true,
      beginAtZero: true,
      max: 100,
      barPercentage: 0.5,
      categoryPercentage: 1,
      ticks: {
        maxRotation: 0, // Keep labels horizontal
        maxTicksLimit: 11, // Adjust the number of visible ticks
        callback: function (value, index, values) {
          // Wrap the labels with a max width and include %
          const maxWidth = 100; // Adjust the max width as needed
          const formattedValue = value + "%";
          console.log("value", values);
          return formattedValue.length > maxWidth
            ? formattedValue.slice(0, maxWidth) + "..."
            : formattedValue;
        },
      },
    },
    y: {
      beginAtZero: true,
      categoryPercentage: 1,
      ticks: {
        autoSkip: false, // Prevent automatic skipping of labels
        maxRotation: 90, // Rotate labels to 90 degrees
        minRotation: 0, // Keep labels vertical
      },
    },
    responsive: true,
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "",
    },
    tooltip: {
      // position: 'bottom',
      callbacks: {
        label: (context) => {
          console.log("context=====", context?.label);

          const datasetIndex = context.datasetIndex;
          const dataPointIndex = context.dataIndex;
          const scoredCount = sub_cat_call_ids_val[dataPointIndex];
          console.log("subCatLabels", subCatLabels);
          const percentage = ((scoredCount / totCall) * 100).toFixed(2);
          const subCatLabel = subCatLabels[dataPointIndex];
          return `${subCatLabel}: ${percentage}% - ${scoredCount} of ${totCall} calls scored`;
        },
      },
    },
  },
};

const options3 = {
  responsive: true,
  maxBarThickness: 80,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    title: {
      display: false,
      text: "",
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          // console.log('context=====', context)
        },
      },
    },
  },
};

const options2 = {
  indexAxis: "x",
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  maxBarThickness: 80,
  scales: {
    x: {
      stacked: true,
      beginAtZero: true,
    },
    y: {
      stacked: true,
      beginAtZero: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
    },
    title: {
      display: false,
      text: "kkl",
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          console.log("context=====", context);
          const datasetIndex = context.datasetIndex;
          const dataPointIndex = context.dataIndex;
          const scoredCount =
            Data10.datasets[datasetIndex].data[dataPointIndex];
          const percentage = ((scoredCount / totCall) * 100).toFixed(2);
          return `${context?.dataset?.label}: ${percentage}% - ${scoredCount} of ${totCall} calls scored`;
        },
      },
    },
  },
};

const options12 = {
  indexAxis: "x",
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  maxBarThickness: 80,
  scales: {
    x: {
      stacked: true,
      beginAtZero: true,
    },
    y: {
      stacked: true,
      beginAtZero: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

let selectedDatasetIndex = undefined;
let selectedIndex = undefined;

// const hoverLabel = {
//     id: 'hoverLabel',
//     afterDraw: (chart, args, options) => {
//         console.log('selectedIndex----------', selectedIndex)

//         const { ctx } = chart;
//         const { chartArea: { width, height, top } } = chart;

//         if (selectedIndex >= 0) {
//             const sum = chart._metasets[selectedIndex].total;
//             console.log('sum----', sum);
//             // const value = chart._metasets[selectedIndex]._parsed[selectedIndex];
//             // let percentage = value / sum * 100;
//             // if(isNaN(percentage)) {
//             //     percentage = ''
//             // }
//             // percentage = percentage === NaN ? '' : percentage;
//             // const color = chart.data.datasets[selectedDatasetIndex].borderColor[selectedIndex];
//             // console.log('percentage-------------', percentage)
//             ctx.save();

//             ctx.font = 'bolder 20px sans-serif';
//             ctx.fillStyle = '#000';
//             ctx.textAlign = 'center';
//             ctx.textBaseLine = 'middle';

//             ctx.fillText(1, width / 2, height / 2 + top);
//             ctx.restore();
//         }
//     }
// }

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var globalColorsArr = ["#ebedf0", "#c6e48b", "#40c463", "#30a14e", "#216e39"];
var clickedAppId = "";
var clickedCatId = "";
var clickedSubCatId = "";

var loadedAppId = "";
var loadedAppName = "";
var lastAppId = "";
var lastCatId = "";

var catIDs = [];
var subCatIDs = [];
var stackValues = [];
var emotionForDonutChart = [];
// var dynamicHeadersArr = [];
// var dataArr = [];

var dynamicHeadersArr = [
  { Header: "No Data", accessor: "" },
  // { Header: 'Call Volume', accessor: 'Call_Volume' },
  // { Header: 'Avg Call Duration', accessor: 'Avg_Call_Duration' },
  // { Header: 'Avg Agent TalkTime', accessor: 'Avg_Agent_TalkTime' },
  // { Header: 'Avg Client TalkTime', accessor: 'Avg_Client_TalkTime' },
  // { Header: 'Positive Agent Emotion', accessor: 'Positive_Agent_Emotion' },
  // { Header: 'Positive Client Emotion', accessor: 'Positive_Client_Emotion' },
];

var dynamicHeadersForAppTable = [
  { Header: "File Name", accessor: "call_id" },
  // { Header: "Agent ID", accessor: "agentid" },
  { Header: "Duration", accessor: "duration" },
  { Header: "Silence Time", accessor: "silence_percentage" },
  // { Header: "Overtalk", accessor: "overtalk" },
  // { Header: "Diarization", accessor: "" },
  // { Header: "Agent Clarity", accessor: "" },
  { Header: "Datetime", accessor: "datetime" },

  { Header: "Agent Gender", accessor: "agent_gender" },
  { Header: "Client Gender", accessor: "client_gender" },
  { Header: "Overall Emotion", accessor: "overall_emotion" },
  { Header: "Agent Emotion", accessor: "agent_emotion" },
  { Header: "Client Emotion", accessor: "client_emotion" },

  // { Header: "Transcript ID", accessor: "tran_id" },
  // { Header: "Request ID", accessor: "" },
  // { Header: "Score", accessor: "" },
];

var dataArr = [
  {
    date: [<p>NA</p>],
    Call_Volume: [<p>NA</p>],
    Avg_Call_Duration: [<p>NA</p>],
    Avg_Agent_TalkTime: [<p>NA</p>],
    Avg_Client_TalkTime: [<p>NA</p>],
    Positive_Agent_Emotion: [<p>NA</p>],
    Positive_Client_Emotion: [<p>NA</p>],
  },
];

var columnsArr1 = [
  "Date",
  "Call volume",
  "Avg call duration",
  "Avg calls per agent",
  "Avg agent talktime",
  "Avg client talktime",
  "Positive agent emotion",
  "Positive client emotion",
];

var chartDatas = [];
var labels = [];
var datasets = [];

var Data2 = {
  labels,
  datasets: datasets,
};

var Data3 = {
  labels,
  datasets: datasets,
};

var Data4 = {
  labels,
  datasets: datasets,
};

var Data5 = {
  labels,
  datasets: datasets,
};

var Data7 = {
  labels,
  datasets: datasets,
};
var Data8 = {
  labels,
  datasets: datasets,
};

var Data9 = {
  labels,
  datasets: datasets,
};

var Data10 = {
  labels,
  datasets: datasets,
};

var Data11 = {
  labels,
  datasets: datasets,
};

var data12 = {
  labels,
  datasets: datasets,
};

var data13 = {
  labels,
  datasets: datasets,
};

var data14 = {
  labels,
  datasets: datasets,
};
var optionsAppList = [];
var optionsFolderList = [];

let dataFilter = [];

const wLabels = ["a", "v", "c", "d", "w", "f", "g", "h", "i"];
const wdatasets = [
  {
    type: "wordCloud",
    label: "DS",
    // size in pixel
    data: [90, 80, 70, 60, 50, 40, 30, 20, 10],
  },
];
// const wdatasets = {
//     type: 'wordCloud',
//     data: {
//         // text
//         labels: wLabels,
//         datasets: ,
//     },
// };
const data22 = {
  wLabels,
  datasets: wdatasets,
};

const Dashboard6 = ({
  filterData,
  IdOfFolder,
  appListData,
  setAppListData,
  filterPayload,
  fromDateForApp,
  toDateForApp,
  setFromDateForApp,
  setToDateForApp,
  fromDate1,
  toDate1,
  appDataForTable,
  setAppDataForTable,
  loaderSubCat,
  setLoaderSubCat,
  endDuration,
  startDuration,
  filteredFolders,
  selectedValueFolder,
  setSelectedValueFolder,
  selectedValue,
  setSelectedValue,
  filterReset,
  setFilterReset,
  tableLoad,
  setTableload,
  huntsId
}) => {
  TabTitle("SmartSpeech-Dashboard :: NEQQO");
  console.log("appDataForTable", appDataForTable);
  const arrVal = {
    0: {
      Positive: 0,
      Negative: 0,
      Worsening: 0,
      Improving: 0,
      total: 0,
      range: "0-1",
    },
    1: {
      Positive: 0,
      Negative: 0,
      Worsening: 0,
      Improving: 0,
      total: 0,
      range: "1-5",
    },
    2: {
      Positive: 1,
      Negative: 0,
      Worsening: 0,
      Improving: 1,
      total: 2,
      range: "5-10",
    },
    3: {
      Positive: 0,
      Negative: 0,
      Worsening: 0,
      Improving: 2,
      total: 2,
      range: "10-15",
    },
    4: {
      Positive: 1,
      Negative: 0,
      Worsening: 1,
      Improving: 1,
      total: 3,
      range: "15-20",
    },
    5: {
      Positive: 0,
      Negative: 0,
      Worsening: 0,
      Improving: 0,
      total: 0,
      range: "20-25",
    },
  };
  console.log("startDuration", typeof startDuration, startDuration);
  const navigate = useNavigate();
  console.log("IdOfFolderIdOfFolder", IdOfFolder);

  const [folderListData, setFolderListData] = useState([]);
  const [allFoldersList, setAllFoldersList] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [folderID, setFolderID] = useState(IdOfFolder ? IdOfFolder : "all");
  const [catListData, setCatListData] = useState([]);
  const [subCatListData, setSubCatListData] = useState([]);
  const [subCatListDDData, setSubCatListDDData] = useState([]);
  const [phraseListData, setPhraseListData] = useState([]);

  const [subCatHeader, setSubCatHeader] = useState([]);
  const [subCatHeaderData, setSubCatHeaderData] = useState([]);

  const [termText, setTermText] = useState("");
  const [termPhrase, setTermPhrase] = useState("");

  const [catValue, setCategoryValue] = useState("");
  const [subCatValue, setSubCatValue] = useState("");

  const [excludePhraseList, setExcludePhraseList] = useState([]);
  const [includePhraseList, setIncludePhraseList] = useState([]);

  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [from_date2, setFromDate2] = useState("");
  const [to_date2, setToDate2] = useState("");

  const [toDateDisabled, setToDateDisabled] = useState(true);

  const [level_from_date, setLevelFromDate] = useState(fromDateForApp);
  const [level_to_date, setLevelToDate] = useState(toDateForApp);

  const [catReportData, setCatReportData] = useState([]);

  const [isHeadersLoaded, setIsHeadersLoaded] = useState(false);
  const [appName, setAppName] = useState("");

  const [catName, setCatName] = useState("");
  const [subCatName, setSubCatName] = useState("");
  const [agentsValue, setAgentsValue] = useState("");

  const [refreshScree, setRefreshScree] = useState(false);

  const [overviewPortionData, setOverviewPortionData] = useState([]);

  const [callStaticsDataToday, setCallStaticsDataToday] = useState([]);
  const [columnsArr, setColumnsArr] = useState([]);

  const [overviewText, setOverviewText] = useState("call_volumn");
  const [appText, setAppText] = useState("");
  const [applicationId, setApplicationId] = useState("");

  const [selectedApps, setSelectedApps] = useState([]);
  const [clickedCallDurationDate, setClickedCallDurationDate] = useState(false);
  const [clickedCallDurationStartDate, setClickedCallDurationStartDate] =
    useState(false);

  const [clickedClientEmotionDate, setClickedClientEmotionDate] =
    useState(false);
  const [clickedClientEmotionStartDate, setClickedClientEmotionStartDate] =
    useState(false);

  const [clickedAgentEmotionDate, setClickedAgentEmotionDate] = useState(false);
  const [clickedAgentEmotionStartDate, setClickedAgentEmotionStartDate] =
    useState(false);

  const [clickedAppCatData, setClickedAppCatData] = useState(false);
  const [clickedCallVolumnDate, setClickedCallVolumnDate] = useState(false);
  const [clickedAgentEmotionBarsDate, setClickedAgentEmotionBarsDate] =
    useState("");
  const [clickedCDBarsDate, setClickedCDBarsDate] = useState("");
  const [clickedCDBarsEmotionDate, setClickedCDBarsEmotionDate] = useState("");
  const [clickedCEBarsEmotionDate, setClickedCEBarsEmotionDate] = useState("");
  const [clickedCEBarsDate, setClickedCEBarsDate] = useState("");
  const [clickedSubCat, setClickedSubCat] = useState("");
  const [clickedCatID, setClickedCatID] = useState("");
 


  const [isCallDurationClicked, setIsCallDurationClicked] = useState(false);
  const [isClientEmotionClicked, setIsClientEmotionClicked] = useState(false);
  const [isAgentEmotionClicked, setIsAgentEmotionClicked] = useState(false);
  const [isAppCatClicked, setIsAppCatClicked] = useState(false);
  const [filesOverviewData, setFilesOverviewData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  console.log("appListData?.length", appListData?.length);
  const [isRenderData, setIsRenderData] = useState(
    appListData?.length === 0 ? true : false
  );
  const [isDataFound, setIsDataFound] = useState(false);
  const [file, setFile] = useState([]);

  const [displayCharts, setDisplayCharts] = useState("application");

  const [isCallDurationChartLoaded, setIsCallDurationChartLoaded] =
    useState(false);
  const [isAgentEmotionChartLoaded, setIsAgentEmotionChartLoaded] =
    useState(false);
  const [isClientEmotionChartLoaded, setIsClientEmotionChartLoaded] =
    useState(false);
  const [isCallVolumnChartLoaded, setIsCallVolumnChartLoaded] = useState(false);

  const [overHighlight, setOverHighlight] = useState("active");

  const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
  const [evaName, setEvaName] = useState("");
  const [call_id, setCallId] = useState();
  const [columnDetails, setColumnDetails] = useState([]);
  const [graphLoader, setGraphLoader] = useState(false);
  const [loadingLoader, setLoadingLoader] = useState(false);

  const [weeklyFromDate, setWeeklyFromDate] = useState("");
  const [weeklyToDate, setWeeklyToDate] = useState("");
  const [tableData, setTableData] = useState([]);

  

  const [subCatData, setSubCatData] = useState([]);
  console.log("subCatData", filteredFolders);
  let getUrl=""
  if(window.location.href?.split("/")[window.location.href?.split("/")?.length-1]==="smartspeech-app-dashboard"){
    getUrl="application"
  }
  else{
      getUrl="applications"
  }
  if (subCatData?.length > 0) {
    dataArr = subCatData
      ?.filter((i) => {
        return searchValue === ""
          ? i && i
          : (i?.call_id && i?.call_id?.toLowerCase().includes(searchValue)) ||
              (i?.agentid && i?.agentid?.toLowerCase().includes(searchValue)) ||
              (i?.datetime &&
                i?.datetime?.toLowerCase().includes(searchValue)) ||
              (i?.duration &&
                i?.duration?.toLowerCase().includes(searchValue)) ||
              (i?.silence_percentage &&
                i?.silence_percentage?.toLowerCase().includes(searchValue)) ||
              (i?.diarization &&
                i?.diarization?.toLowerCase().includes(searchValue)) ||
              (i?.diarization &&
                i?.diarization?.toLowerCase().includes(searchValue)) ||
              (i?.agent_clarity &&
                i?.agent_clarity?.toLowerCase().includes(searchValue)) ||
              (i?.agent_gender &&
                i?.agent_gender?.toLowerCase().includes(searchValue)) ||
              (i?.client_gender &&
                i?.client_gender?.toLowerCase().includes(searchValue)) ||
              (i?.overall_emotion &&
                i?.overall_emotion?.toLowerCase().includes(searchValue)) ||
              (i?.agent_emotion &&
                i?.agent_emotion?.toLowerCase().includes(searchValue)) ||
              (i?.client_emotion &&
                i?.client_emotion?.toLowerCase().includes(searchValue)) ||
              (i?.tran_id && i?.tran_id?.toLowerCase().includes(searchValue)) ||
              (i?.req_id && i?.req_id?.toLowerCase().includes(searchValue)) ||
              (i?.score && i?.score?.toLowerCase().includes(searchValue));
      })
      .map((i, j) => ({
        File_Name: (
          <p>
            <Link
              to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i?.call_id}`}
              target="_blank"
            >
              {i.wav}
            </Link>
          </p>
        ),
        Agent: [<p className="  td-link">{i?.agentid ? i?.agentid : "NA"}</p>],
        Time: [<p className="  td-link">{i?.datetime ? i?.datetime : "NA"}</p>],
        Duration: [
          <p className="  td-link">{i?.duration ? i?.duration : "NA"}</p>,
        ],
        Silence_Time: [
          <p className="  td-link">
            {i?.silence_percentage ? i?.silence_percentage : "NA"}
          </p>,
        ],
        Diarization: [
          <p className="  td-link">{i?.diarization ? i?.diarization : "NA"}</p>,
        ],
        Agent_Clarity: [
          <p className="  td-link">
            {i?.agent_clarity ? i?.agent_clarity : "NA"}
          </p>,
        ],
        Agent_Gender: [
          <p className="  td-link">
            {i?.agent_gender ? i?.agent_gender : "NA"}
          </p>,
        ],
        Client_Gender: [
          <p className="  td-link">
            {i?.client_gender ? i?.client_gender : "NA"}
          </p>,
        ],
        Overall_Emotion: [
          <p className="  td-link">
            {i?.overall_emotion ? i?.overall_emotion : "NA"}
          </p>,
        ],
        Agent_Emotion: [
          <p className="  td-link">
            {i?.agent_emotion ? i?.agent_emotion : "NA"}
          </p>,
        ],
        Client_Emotion: [
          <p className="  td-link">
            {i?.client_emotion ? i?.client_emotion : "NA"}
          </p>,
        ],
        Transcript_ID: [
          <p className="  td-link">{i?.tran_id ? i?.tran_id : "NA"}</p>,
        ],
        Request_ID: [
          <p className="  td-link">{i?.req_id ? i?.req_id : "NA"}</p>,
        ],
        Score: [<p className="  td-link">{i?.score ? i?.score : "NA"}</p>],
      }));
  }
  console.log("appListData", appListData);

  const allApplications = appListData?.map((item) => ({
    value: item?.application_id,
    label: item?.application_name,
  }));

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  if (agentsValue !== "") {
    optionsAppList = [];
    optionsFolderList = [];
  }
  optionsAppList = [];
  optionsFolderList = [];
  // if (localStorage.getItem('userRole') === 'Quality Supervisor') {
  //     if (optionsAppList !== undefined && !optionsAppList.length) {
  //     }
  //     console.log('optionsAppList-----------', optionsAppList)
  // }else {
  //     if(displayCharts === 'overview') {
  //         if (optionsAppList !== undefined && !optionsAppList.length) {
  //             optionsAppList = [];
  //             optionsFolderList = [];
  //         }
  //         else {
  //             if(agentsValue === '') {
  //                 if(optionsAppList !== undefined && !optionsAppList.length) {
  //                     optionsAppList = [];
  //                     optionsFolderList = [];
  //                 }
  //             }
  //         }
  //         // return
  //     }else {
  //         optionsAppList = [];
  //         optionsFolderList = [];
  //     }
  //     // if (optionsAppList !== undefined && !optionsAppList.length) {
  //     //     alert('ehre')
  //     // }
  // }
  const [allAppId, setAllAppId] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    const AppIds = selectedOptions.map((option) => option?.application_id);
    console.log("1111111111", AppIds);
    setSelectedApps(selectedOptions);
    setAllAppId(AppIds);
  };

  const handleFromDateChange = (e) => {
    console.log(">>>>>>>>>>>>>>>>>", e);
    const selectedDate = e.target.value;
    setFromDate(selectedDate);
    // Disable future dates in the to_date input
    const maxToDate = moment(selectedDate).endOf("month").format("YYYY-MM-DD");
    const minToDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    setToDate("");
    document.getElementById("toDateInput").setAttribute("max", maxToDate);
    document.getElementById("toDateInput").setAttribute("min", minToDate);
    //setToDateDisabled(false)
  };

  const handleToDateChange = (e) => {
    const selectedDate = e.target.value;
    setToDate2(selectedDate);
  };

  const handleFromDateChange2 = (e) => {
    console.log(">>>>>>>>>>>>>>>>>", e);
    const selectedDate = e.target.value;
    setFromDate2(selectedDate);
    // Disable future dates in the to_date input
    const maxToDate = moment(selectedDate).endOf("month").format("YYYY-MM-DD");
    const minToDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    setToDate2("");
    document.getElementById("toDateInput2").setAttribute("max", maxToDate);
    document.getElementById("toDateInput2").setAttribute("min", minToDate);
    //setToDateDisabled(false)
  };

  const handleToDateChange2 = (e) => {
    const selectedDate = e.target.value;
    setToDate2(selectedDate);
  };

  var pluginLegendVal = "";
  const options13 = {
    id: "textCenter",
    onClick: function (hover, element, chart) {
      if (element[0]) {
        selectedDatasetIndex = element[0].datasetIndex;
        selectedIndex = element[0].index;

        const { ctx } = chart;
        const {
          chartArea: { width, height, top },
        } = chart;

        if (selectedIndex >= 0) {
          console.log("chart----", chart);
          console.log("chart----", chart.tooltip.body[0].lines[0]);
          setIsLoading(true);
          var url = `${base_url}smartspeech/files-overview`;

          if (overviewText === "call_duration") {
            var requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("loginToken"),
              },
              body: JSON.stringify({
                from_date:
                  fromDateForApp && fromDateForApp !== ""
                    ? fromDateForApp
                    : filterPayload?.overall_from_date,
                to_date:
                  toDateForApp && toDateForApp !== ""
                    ? toDateForApp
                    : filterPayload?.overall_to_date,
                overall_emotion: filterPayload?.emotion,
                duration: `${
                  startDuration || endDuration !== ""
                    ? startDuration + "-" + endDuration
                    : ""
                }`,
                folder_id: IdOfFolder,
              }),
            };
          }

          if (overviewText === "agent_emotion") {
            pluginLegendVal = chart.tooltip.body[0].lines[0] =
              emotionForDonutChart[selectedIndex];
            var requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("loginToken"),
              },
              body: JSON.stringify({
                from_date: fromDateForApp,
                to_date: toDateForApp,
                agent_emotion: emotionForDonutChart[selectedIndex],
                duration: "",
                folder_id: folderID === "all" ? "" : folderID,
              }),
            };
          }

          if (overviewText === "client_emotion") {
            var requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("loginToken"),
              },
              body: JSON.stringify({
                from_date: fromDateForApp,
                to_date: toDateForApp,
                client_emotion: emotionForDonutChart[selectedIndex],
                duration: "",
                folder_id: folderID === "all" ? "" : folderID,
              }),
            };
          }

          callApi(url, requestOptions, "files-overview");

          // const sum = chart._metasets[selectedIndex].total;
          // ctx.save();

          // ctx.font = 'bolder 20px sans-serif';
          // ctx.fillStyle = '#000';
          // ctx.textAlign = 'center';
          // ctx.textBaseLine = 'middle';

          // ctx.fillText(1, width / 2, height / 2 + top);
          // ctx.restore();
        }
        chart.draw();
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: false,
        text: "kkl",
      },

      // tooltip: {
      //     callbacks: {
      //         label: (context) => {
      //             console.log('context=====', context)
      //             return pluginLegendVal
      //             // emotionForDonutChart.map((i, j) => {
      //             //     console.log('stacked of i---', i)
      //             //     return context.raw = i
      //             // })
      //         }
      //     }
      // }
    },

    // elements: {
    //     arc: {
    //         borderWidth: 10,
    //         onClick: function (data) {
    //             console.log(data);
    //         }
    //     }
    // },
    // onmouseout: function (hover, element, chart) {
    //     alert('here')
    // },
    // plugins: [hoverLabel],
  };

  useEffect(() => {
    getOtherEvaluators();
  }, []);

  const getFoldersApplications = (folder_id) => {
    console.log("folder_id", folder_id);
    // setAppName("");
    // setAppID([]);
    var url = `${base_url}folder/all-application-folder-wise/${
      folder_id?.length > 0 && folder_id
    }`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-wise-app-list");
  };

  const getOtherEvaluators = async () => {
    var url = `${base_url}calls/get-other-evaluators`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        userId: localStorage.getItem("loginUserId"),
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_by:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("loginUserUniqueid")
            : "",
      }),
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("setOtherEvaluatorsData data======", data);

        if (data.status != 200) {
          ErrorNotify(data.message);
          return;
        }
        // var allRec = data.data.filter((i, j) => i.agent_id !== localStorage.getItem('loginUserUniqueid')).map(record => record);
        setOtherEvaluatorsData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateAssignment = () => {
    if (evaName === "") {
      ErrorNotify("Please select Evaluator name");
      return;
    }

    var url = `${base_url}callspeech/call-assign`;
    setIsDataFound(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        // evaluator: evaName,
        id: call_id,
        assign_user_id: evaName,
      }),
    };

    callApi(url, requestOptions, "call-assign");
  };

  useEffect(() => {
    setIsCallDurationClicked(false);
    if (clickedCallDurationDate !== "") {
      setIsLoading(true);
      // setIsClientEmotionClicked(false);
      // setIsAgentEmotionClicked(false);
      var url = `${base_url}callspeech/call-charts-duration-emotion`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: clickedCallDurationStartDate,
          to_date: clickedCallDurationDate,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "call-charts-duration-emotion");

      var url = `${base_url}callspeech/call-breakdown-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: clickedCallDurationStartDate,
          to_date: clickedCallDurationDate,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "call-breakdown-duration");
    }
  }, [clickedCallDurationDate, clickedCallDurationStartDate]);

  // file data table when change call duration bar chart
  // useEffect(() => {
  //     if (overviewText === 'call_duration' || agentsValue === 'Files') {

  //         var url = `${base_url}smartspeech/files-overview`;

  //         var requestOptions = {
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
  //             },
  //             body: JSON.stringify({
  //                 "from_date": fromDateForApp,
  //                 "to_date": toDateForApp,
  //                 "overall_emotion": "",
  //                 "duration": ""
  //             })
  //         };
  //         callApi(url, requestOptions, 'files-overview');
  //     }
  // }, [overviewText])

  useEffect(() => {
    setIsClientEmotionClicked(true);
    setIsLoading(true);
    setIsCallDurationClicked(false);
    setIsAgentEmotionClicked(false);

    if (clickedClientEmotionDate !== "") {
      var url = `${base_url}callspeech/client-emotion-average-call-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: clickedClientEmotionStartDate,
          to_date: clickedClientEmotionDate,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-average-call-duration");

      var url = `${base_url}callspeech/client-emotion-individual-count`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: clickedClientEmotionStartDate,
          to_date: clickedClientEmotionDate,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-individual-count");
    }
    setClickedClientEmotionDate("");
  }, [clickedClientEmotionDate]);

  useEffect(() => {
    setIsClientEmotionClicked(false);
    setIsCallDurationClicked(false);
    setIsLoading(true);
    setIsAgentEmotionClicked(true);

    if (clickedAgentEmotionDate !== "") {
      var url = `${base_url}callspeech/agent-emotion-average-call-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: clickedAgentEmotionStartDate,
          to_date: clickedAgentEmotionDate,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-average-call-duration");

      var url = `${base_url}callspeech/agent-emotion-individual-count`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: clickedAgentEmotionStartDate,
          to_date: clickedAgentEmotionDate,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-individual-count");
    }

    setClickedAgentEmotionDate("");
  }, [clickedAgentEmotionDate]);

  // when got change on click of call duration bar charts
  useEffect(() => {
    // setIsClientEmotionClicked(false);
    setIsCallDurationClicked(true);
    // setIsAgentEmotionClicked(false);

    if (clickedCDBarsDate !== false && clickedCDBarsDate !== "") {
      setIsLoading(true);
      var url = `${base_url}smartspeech/files-overview`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
          overall_emotion: clickedCDBarsEmotionDate,
          duration: `${
            startDuration || endDuration !== ""
              ? startDuration + "-" + endDuration
              : ""
          }`,
          folder_id: IdOfFolder,
        }),
      };
      callApi(url, requestOptions, "files-overview");
    }
    setClickedCDBarsDate("");
  }, [clickedCDBarsDate]);

  useEffect(() => {
    console.log("MNBVCXZXCVBN", clickedAppCatData);
    if (
      clickedAppCatData !== null &&
      clickedAppCatData !== undefined &&
      clickedAppCatData !== false
    ) {
      console.log("FFFFFFFFFFFFF", filterPayload);
      setIsClientEmotionClicked(false);
      setIsCallDurationClicked(false);
      setIsAgentEmotionClicked(false);
      setIsAppCatClicked(false);

      let subcatPayload = {
        application: clickedAppId,
          category_id: clickedAppCatData,
          from_date: weeklyFromDate,
          to_date: weeklyToDate,
          folder_id: [folderID],
          silent_time: filterPayload?.silent_time,
          start_silent_time:filterPayload?.start_silent_time,
          duration: filterPayload?.duration,
          start_duration: filterPayload?.start_duration,
          campaign: filterPayload?.campaign,
          gender: filterPayload?.gender,
          gender_type: filterPayload?.gender_type,
          emotion: filterPayload?.emotion,
          emotion_type: filterPayload?.emotion_type

      }
      if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
        subcatPayload["group_hunt"] = huntsId?.filter(f=>f?.value!=="All")?.map(i=>i?.value)
      }

      var url = `${base_url}smartspeech/sub-category-overview-data`;
      setAppDataForTable([])
      subCatLabels = [];
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify(
          // application: clickedAppId,
          // category_id: clickedAppCatData,
          // from_date: fromDateForApp && fromDateForApp !== ""? fromDateForApp: filterPayload?.overall_from_date,
          // to_date: toDateForApp && toDateForApp !== ""? toDateForApp: filterPayload?.overall_to_date,
          // folder_id: filterPayload?.folder_id && filterPayload?.folder_id?.length>0?filterPayload?.folder_id:folderID,
          // silent_time: filterPayload?.silent_time,
          // start_silent_time:filterPayload?.start_silent_time,
          // duration: filterPayload?.duration,
          // start_duration: filterPayload?.start_duration,
          // campaign: filterPayload?.campaign,
          // gender: filterPayload?.gender,
          // gender_type: filterPayload?.gender_type,
          // emotion: filterPayload?.emotion,
          // emotion_type: filterPayload?.emotion_type
          subcatPayload
        ),
      };

      // gender: genderOF,
      // gender_type: genderType,
      // emotion: emotionOF ? emotionOF : "",
      // emotion_type: emotionType ? emotionType : [],
      // overall_from_date: props?.fromDate1,
      // overall_to_date: props?.toDate1,
      // silent_time: endSlTime,
      // start_silent_time: startSlTime,
      // duration: props?.endDuration,
      // start_duration: props?.startDuration,
      // folder_id: folderID,

      callApi(url, requestOptions, "sub-category-overview-data");
    }
  }, [clickedAppCatData, weeklyFromDate, weeklyToDate]);

  useEffect(() => {
    console.log("IdOfFolderIdOfFolder1222", IdOfFolder);
    setIsClientEmotionClicked(false);
    setIsCallDurationClicked(false);
    setIsAgentEmotionClicked(false);

    setIsCallVolumnChartLoaded(false);

    var url = `${base_url}callspeech/call-statistics-data`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        to_date: clickedCallVolumnDate === "" ? to_date : clickedCallVolumnDate,
        from_date:
          clickedCallVolumnDate === "" ? from_date : clickedCallVolumnDate,
        folder_id: folderID === "all" ? "" : folderID,
      }),
    };
    var requestOptions2 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDateForApp,
        to_date: toDateForApp,
        overall_emotion: filterPayload?.emotion,
        duration: `${
          startDuration || endDuration !== ""
            ? startDuration + "-" + endDuration
            : ""
        }`,
        folder_id: IdOfFolder,
      }),
    };
    var url2 = `${base_url}smartspeech/files-overview`;
    callApi(url, requestOptions, "call-statistics-data");
    callApi(url2, requestOptions2, "files-overview");
  }, [clickedCallVolumnDate]);

  useEffect(() => {
    // var url = `${base_url}callspeech/application-list`;
    // var requestOptions = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     }
    // };
    // callApi(url, requestOptions, 'app-list');
    // if (localStorage.getItem('userRole') !== 'Quality Supervisor') {
    //     var url = `${base_url}callspeech/qa-overview-call-data`;
    //     var requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //         },
    //         body: JSON.stringify({
    //             'from_date': fromDateForApp && fromDateForApp !== "" ? fromDateForApp : filterPayload?.overall_from_date,
    //             'to_date': toDateForApp && toDateForApp !== "" ? toDateForApp : filterPayload?.overall_to_date,
    //             'folder_id': IdOfFolder,
    //         })
    //     };
    //     callApi(url, requestOptions, 'overview-list');
    // }
  }, [displayCharts]);

  // useeffect for overview portion
  useEffect(() => {
    if (overviewText === "call_volumn") {
      getOverviewDataOnLoad("");
    } else {
      handleOverviewData();
    }
  }, [overviewText]);

  // to load call duration chart on change of dropdown values
  useEffect(() => {
    loadCallDuationChart();
  }, [isCallDurationChartLoaded]);

  const loadCallDuationChart = () => {
    return Data3?.labels?.length === 0 && Data3?.datasets?.length === 0 ? (
      <div
        className="spinner-border text-black"
        style={{ marginLeft: "280px", marginTop: "110px" }}
        role="status"
      ></div>
    ) : (
      <Line
        data={Data3}
        options={options3}
        width="600%"
        onClick={onClick}
        ref={chartRef}
      ></Line>
    );
  };

  // to load agent emotion chart on change of dropdown values
  useEffect(() => {
    setIsCallDurationClicked(false);
    setIsLoading(true);
    loadAgentEmotionChart();
  }, [isAgentEmotionChartLoaded]);

  const loadAgentEmotionChart = () => {
    console.log("Data4Data4", Data4);
    return Data4?.labels?.length === 0 && Data4?.datasets?.length === 0 ? (
      <div
        className="spinner-border text-black"
        style={{ marginLeft: "280px", marginTop: "110px" }}
        role="status"
      ></div>
    ) : (
      <Line
        data={Data4}
        options={options}
        width="600%"
        onClick={onClickAgent}
        ref={chartRefAgent}
      ></Line>
    );
  };

  // to load client emotion chart on change of dropdown values
  useEffect(() => {
    loadClientEmotionChart();
  }, [isClientEmotionChartLoaded]);

  const loadClientEmotionChart = () => {
    return Data5?.labels?.length === 0 && Data5?.datasets?.length === 0 ? (
      <div
        className="spinner-border text-black"
        style={{ marginLeft: "280px", marginTop: "110px" }}
        role="status"
      ></div>
    ) : (
      <Line
        data={Data5}
        options={options}
        width="600%"
        onClick={onClickClient}
        ref={chartRefClient}
      ></Line>
    );
  };

  // to load call volumn chart on change of dropdown values
  useEffect(() => {
    loadCallVolumnChart();
  }, [isCallVolumnChartLoaded]);

  const loadCallVolumnChart = () => {
    console.log("Data2", Data2?.labels?.length);
    return Data2?.labels?.length === 0 && Data2?.datasets?.length === 0 ? (
      <div
        className="spinner-border text-black"
        style={{ marginLeft: "280px", marginTop: "110px" }}
        role="status"
      ></div>
    ) : (
      <Line
        data={Data2}
        options={options}
        width="600%"
        onClick={onClickVolumn}
        ref={chartRefVolumn}
      ></Line>
    );
  };

  // to load data table
  // useEffect(() => {
  //     loadDT();
  // }, [agentsValue]);
  // const loadDT = () => {
  //     return <DataTableView isAppFilter={false} isQAAutoFilter={true} columns={columns} data={data} loading={false} totalRec={data.length} quickSearch={false} evaluatorSearch={false} />
  // }

  // to load application charts on change application
  // useEffect(() => {
  //     loadApplicationsCharts();
  // }, [displayCharts]);
  // const loadApplicationsCharts = () => {
  //     return <SSApplicationCharts Data10={Data10} Data11={Data11} appText={appText} appName={appName} isLoading={isLoading} onClickApp={onClickApp} chartRefApp={chartRefApp} isAppCatClicked={isAppCatClicked} />
  // }

  // get overview data on page load
  const getOverviewDataOnLoad = (val = "") => {
    Data10.labels = [];
    Data10.datasets = [];
    Data2.labels = [];
    Data2.datasets = [];

    setIsRenderData(appListData?.length === 0 ? true : false);
    var url = `${base_url}callspeech/overview-data`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDateForApp,
        to_date: toDateForApp,
        folder_id: folderID === "all" ? "" : folderID,
      }),
    };

    callApi(url, requestOptions, "overview-portion-data");

    var url = `${base_url}callspeech/call-statistics-data`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDateForApp
          ? fromDateForApp
          : moment().startOf("month").format("YYYY-MM-DD"),
        to_date: toDateForApp ? toDateForApp : moment().format("YYYY-MM-DD"),
        folder_id: folderID === "all" ? "" : folderID,
      }),
    };

    callApi(url, requestOptions, "call-statistics-data");

    let catPayload = {
      application: applicationId === "all" ? "" : applicationId,
      from_date: fromDateForApp,
      to_date: toDateForApp,
      folder_id: [folderID],
    }

    if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
      catPayload["group_hunt"] = huntsId?.filter(f=>f?.value!=="All")?.map(i=>i?.value)
    }
    setTimeout(() => {
      if (appName !== "" && applicationId !== "") {
        var url = `${base_url}smartspeech/category-overview-data`;

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify(catPayload),
        };
        callApi(url, requestOptions, "category-overview-data");
      }
    }, 1000);

    if (agentsValue !== "" && agentsValue === "Files") {
      var url = `${base_url}smartspeech/files-overview`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
          overall_emotion: filterPayload?.emotion,
          duration: `${
            startDuration || endDuration !== ""
              ? startDuration + "-" + endDuration
              : ""
          }`,
          folder_id: IdOfFolder,
        }),
      };
      callApi(url, requestOptions, "files-overview");
    }

    if (overviewText === "call_duration") {
      var url = `${base_url}callspeech/call-duration-overview-data`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp
            ? fromDateForApp
            : moment().startOf("month").format("YYYY-MM-DD"),
          to_date: toDateForApp ? toDateForApp : moment().format("YYYY-MM-DD"),
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };

      callApi(url, requestOptions, "call-duration-overview-data");

      var url = `${base_url}callspeech/call-charts-duration-emotion`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp
            ? fromDateForApp
            : moment().startOf("month").format("YYYY-MM-DD"),
          to_date: toDateForApp ? toDateForApp : moment().format("YYYY-MM-DD"),
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "call-charts-duration-emotion");

      var url = `${base_url}callspeech/call-breakdown-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp
            ? fromDateForApp
            : moment().startOf("month").format("YYYY-MM-DD"),
          to_date: toDateForApp ? toDateForApp : moment().format("YYYY-MM-DD"),
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "call-breakdown-duration");
    }

    if (overviewText === "agent_emotion") {
      var url = `${base_url}callspeech/agent-emotion-overview-data`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-overview-data");
      var url = `${base_url}callspeech/agent-emotion-average-call-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-average-call-duration");

      var url = `${base_url}callspeech/agent-emotion-individual-count`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-individual-count");
    }

    if (overviewText === "client_emotion") {
      var url = `${base_url}callspeech/client-emotion-overview-data`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-overview-data");

      var url = `${base_url}callspeech/client-emotion-average-call-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-average-call-duration");

      var url = `${base_url}callspeech/client-emotion-individual-count`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-individual-count");
    }
  };

  useEffect(() => {
    getAllFolders();
    // getCategories(loadedAppId);
    setAppName(loadedAppName);
    // setActiveClass(loadedAppId);
  }, [loadedAppId]);

  // useEffect(() => {
  //     if (folderID !== 'all') {
  //         getCategories(clickedAppId, appName);
  //     }
  // }, [folderID, clickedAppId]);

  const setActiveClass = (app_id) => {
    if (app_id === "") {
      $(`#${clickedAppId}`).removeClass("active");
    } else {
      $(`#${app_id}`).addClass("active");
      if (lastAppId !== "") {
        $(`#${lastAppId}`).removeClass("active");
      }
      lastAppId = app_id;
    }
  };

  // getting all folders on dashboard
  const getAllFolders = () => {
    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "all-folders-list");
  };

  // getting applications based on folder id

  // getting folder based on application id
  const getFolders = (app_id) => {
    var url = `${base_url}folder/all-application-folder-list/${app_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-list");
  };

  // useEffect(() => {
  //     if(appName !== '') {
  //         getCategories(clickedAppId, appName);
  //     }
  // }, [appName]);

  // calling, on change of folders
  const handleFolderChange = (e) => {
    // setFolderID('all');

    setFolderID(e.value);
    setFolderName(e.label);

    // getFoldersApplications(e.value);
    setAppName(e.label);

    setIsLoading(true);

    // setOverHighlight('');
    // getCategories(e.value, e.label);
    // setActiveClass(e.value);
    // setFolderListData([]);
    // optionsFolderList = [{ value: '', label: '' }];

    // getCategories(clickedAppId, appName, e.value);

    // e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
  };

  // calling, on change of application
  const handleAppChange = (e) => {
    // window.location.reload();
    // e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
    // setFolderID('all');
    // setFolderListData([]);

    // getFolders(e.value);
    getCategories(e.value, e.label);
    // setActiveClass(e.value);
    setAppName(e.label);

    // setOverHighlight('');
    setIsLoading(true);
    // optionsFolderList = [{ value: '', label: '' }];
  };

  const getCategories = (app_id, app_name, folder_id) => {
    console.log("folder_id4444", app_id);
    Data11.labels = [];
    Data11.datasets = [];
    sub_cat_call_ids_val = [];
    setLoadingLoader(true);
    if (app_id === "") {
      setDisplayCharts("overview");
      setOverHighlight("active");
      setDisplayCharts("overview");
    } else {
      setIsLoading(true);
      setIsRenderData(appListData?.length === 0 ? true : false);
      setAppText(app_name);

      labels = [];
      datasets = [];
      chartDatas = [];

      setDisplayCharts("application");
      setIsAppCatClicked(false);

      if (refreshScree) {
        dataArr = [];
        dynamicHeadersArr = [];
      }

      clickedAppId = app_id;

      var url = app_id && `${base_url}callspeech/category-list/${app_id}`;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      };
      callApi(url, requestOptions, "cat-list");
    }
    // var url = `${base_url}callspeech/categoryreport`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application: app_id,
        from_date: fromDate1,
        to_date: toDate1,
        folder_id: IdOfFolder,
      }),
    };
    // callApi(url, requestOptions, "cat-report");

    if (app_id !== "") {
      var url = `${base_url}smartspeech/category-overview-data`;

      let catPayload = {
        application: app_id === "all" ? "" : app_id,
        from_date: fromDate1,
        to_date: toDate1,
        folder_id: [folderID],
      }
      
      if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
        catPayload["group_hunt"] = huntsId?.filter(f=>f?.value!=="All")?.map(i=>i?.value)
      }
      console.log("JKJKDUEUEUEUIEUIEUIEIUIZSHHJS",huntsId,catPayload);

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify(catPayload),
      };
      callApi(url, requestOptions, "category-overview-data");
    }
  };

  useEffect(()=>{
    if(filterReset){
    console.log("dataFilter454565656566",dataFilter);
    setTableData([])
       Data10 = {
        labels:[],
        datasets: [],
      };
      Data11 = {
        labels:[],
        datasets: [],
      };
      setLoadingLoader(false)
      setSubCatHeader("Application Sub Categories")
      

      
    }
  },[filterReset])

  // useEffect(() => {
  //     if (clickedAppId !== '') {
  //         var url = `${base_url}callspeech/category-overview-data`;

  //         var requestOptions = {
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
  //             },
  //             body: JSON.stringify({
  //                 'application': clickedAppId,
  //                 "from_date": fromDateForApp,
  //                 'to_date': to_date
  //             })
  //         };
  //         setAppText(appName);
  //         callApi(url, requestOptions, 'category-overview-data');
  //     }

  // }, [appName])

  const getSubCategories = (cat_id, type = "") => {
    setLoadingLoader(true);
    clickedCatId = cat_id;

    var url = `${base_url}callspeech/sub-category-list/${clickedAppId}/${cat_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "sub-cat-list", type);
  };

  const exportCalls = () => {
    var url = `${base_url}folder/excel-report`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application: allAppId,
        //application:["168779134411374","168779134411374"],
        folder_id: folderID,
        from_date: from_date2,
        to_date: to_date2,
      }),
    };

    callApi(url, requestOptions, "export-all-calls");
  };

  const addCategory = () => {
    var url = `${base_url}callspeech/addcategory`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        category_name: catValue,
      }),
    };

    callApi(url, requestOptions, "add-cat");
  };

  const addSubCategory = () => {
    var url = `${base_url}callspeech/addsubcategory`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        category_id: clickedCatId,
        subcategory_name: subCatValue,
      }),
    };

    callApi(url, requestOptions, "add-sub-cat");
  };

  const getPhraseData = (app_id, cat_id, sub_cat_id) => {
    clickedSubCatId = sub_cat_id;
    var url = `${base_url}callspeech/phrase-list/${app_id}/${sub_cat_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    callApi(url, requestOptions, "phrase-list");
  };

  const addTermsPhrase = () => {
    var url = `${base_url}callspeech/addphrases`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application_id: clickedAppId,
        subcategory_id: clickedSubCatId,
        terms: [
          {
            terms_text: termText,
            phrase_text: termPhrase,
          },
        ],
      }),
    };

    callApi(url, requestOptions, "add-phrase");
  };

  const setTabVisibleClass = (id) => {
    $(`#${id}`).addClass("tab-visible");
    if (lastCatId !== "") {
      $(`#${lastCatId}`).removeClass("tab-visible");
    }
    lastCatId = id;
  };

  const handleSummaryReportData = (val) => {
    // setAgentsValue(val)

    if (val === "Files") {
      var url = `${base_url}smartspeech/files-overview`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          overall_emotion: filterPayload?.emotion,
          duration: `${
            startDuration || endDuration !== ""
              ? startDuration + "-" + endDuration
              : ""
          }`,
          folder_id: IdOfFolder,
        }),
      };
      callApi(url, requestOptions, "files-overview");
    }

    if (val === "Agent") {
      var url = `${base_url}callspeech/summaryreport`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          application: clickedAppId,
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
        }),
      };

      callApi(url, requestOptions, "summary-report");
    }
  };

  const handleOverviewData = (val) => {
    if (overviewText === "call_volumn") {
      getOverviewDataOnLoad();
    }

    if (overviewText === "call_duration") {
      var url = `${base_url}callspeech/call-duration-overview-data`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp
            ? fromDateForApp
            : moment().startOf("month").format("YYYY-MM-DD"),
          to_date: toDateForApp ? toDateForApp : moment().format("YYYY-MM-DD"),
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "call-duration-overview-data");

      var url = `${base_url}callspeech/call-charts-duration-emotion`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp
            ? fromDateForApp
            : moment().startOf("month").format("YYYY-MM-DD"),
          to_date: toDateForApp ? toDateForApp : moment().format("YYYY-MM-DD"),
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "call-charts-duration-emotion");

      var url = `${base_url}callspeech/call-breakdown-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp
            ? fromDateForApp
            : moment().startOf("month").format("YYYY-MM-DD"),
          to_date: toDateForApp ? toDateForApp : moment().format("YYYY-MM-DD"),
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "call-breakdown-duration");
    }

    if (overviewText === "agent_emotion") {
      console.log("uuuuuuuuuu");
      var url = `${base_url}callspeech/agent-emotion-overview-data`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-overview-data");
      var url = `${base_url}callspeech/agent-emotion-average-call-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-average-call-duration");

      var url = `${base_url}callspeech/agent-emotion-individual-count`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "agent-emotion-individual-count");
    }

    if (overviewText === "client_emotion") {
      console.log("ttttttttttttt");
      var url = `${base_url}callspeech/client-emotion-overview-data`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-overview-data");

      callApi(url, requestOptions, "client-emotion-overview-data");

      var url = `${base_url}callspeech/client-emotion-average-call-duration`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-average-call-duration");

      var url = `${base_url}callspeech/client-emotion-individual-count`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: fromDateForApp,
          to_date: toDateForApp,
          folder_id: folderID === "all" ? "" : folderID,
        }),
      };
      callApi(url, requestOptions, "client-emotion-individual-count");
    }
  };

  const callFileApiFunc = () => {
    console.log("IdOfFolder12222", IdOfFolder);
    setLoaderSubCat(false);
    if (agentsValue?.length === 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    if (agentsValue === "Files") {
      var url = `${base_url}smartspeech/files-overview`;

      let fileOverviewPayload = {
        from_date: fromDateForApp && fromDateForApp !== ""? fromDateForApp: filterPayload?.overall_from_date,
        to_date: toDateForApp && toDateForApp !== ""? toDateForApp: filterPayload?.overall_to_date,
        overall_emotion: filterPayload?.emotion,
        silent_time: filterPayload?.silent_time,
      start_silent_time:filterPayload?.start_silent_time,
      duration: filterPayload?.duration,
      start_duration: filterPayload?.start_duration,
      campaign: filterPayload?.campaign,
      gender: filterPayload?.gender,
      gender_type: filterPayload?.gender_type,
      emotion: filterPayload?.emotion,
      emotion_type: filterPayload?.emotion_type,
        folder_id: [folderID],
      }
      if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
        fileOverviewPayload["group_hunt"] = huntsId?.filter(f=>f?.value!=="All")?.map(i=>i?.value)
      }

      if (displayCharts === "overview") {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify(fileOverviewPayload),
        };
      } else {
        let fileOverviewPayload2 = {
          from_date: fromDateForApp && fromDateForApp !== ""? fromDateForApp: filterPayload?.overall_from_date,
          to_date: toDateForApp && toDateForApp !== ""? toDateForApp: filterPayload?.overall_to_date,
          overall_emotion: filterPayload?.emotion,
          silent_time: filterPayload?.silent_time,
        start_silent_time:filterPayload?.start_silent_time,
        duration: filterPayload?.duration,
        start_duration: filterPayload?.start_duration,
        campaign: filterPayload?.campaign,
        gender: filterPayload?.gender,
        gender_type: filterPayload?.gender_type,
        emotion: filterPayload?.emotion,
        emotion_type: filterPayload?.emotion_type,
          folder_id: [folderID],
          application: clickedAppId,
        }
        if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
          fileOverviewPayload2["group_hunt"] = huntsId?.filter(f=>f?.value!=="All")?.map(i=>i?.value)
        }
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify(fileOverviewPayload2),
        };
      }
      callApi(url, requestOptions, "files-overview");
    }

    if (agentsValue === "Agent") {
      var url = `${base_url}callspeech/summaryreport`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          application: clickedAppId,
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
          folder_id: IdOfFolder,
        }),
      };

      callApi(url, requestOptions, "summary-report");
    }

    if (displayCharts === "application" && agentsValue === "Summary") {
      // var url = `${base_url}callspeech/categoryreport`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          application: clickedAppId,
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
          folder_id: IdOfFolder,
        }),
      };
      callApi(url, requestOptions, "cat-report");
    }

    if (displayCharts === "overview" && agentsValue === "Summary") {
      var url = `${base_url}callspeech/qa-overview-call-data`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
          folder_id: folderID,
        }),
      };

      callApi(url, requestOptions, "overview-list");
    }
  };

  // time chart creation function
  const createScatterChart = () => {
    return (
      <></>
      // <ChartContainer width={800}>
      //     <ChartRow height="200">
      //         <YAxis id="axis1" label="AUD" min={0.5} max={1.5} width="60" type="linear" format="$,.2f" />
      //         <Charts>
      //             <LineChart axis="axis1" />
      //             <LineChart axis="axis2" />
      //         </Charts>
      //         <YAxis id="axis2" label="Euro" min={0.5} max={1.5} width="80" type="linear" format="$,.2f" />
      //     </ChartRow>
      // </ChartContainer>
    );
  };

  // word chart creation function
  const createChart = () => {
    const chart = new ChartJS(
      document.getElementById("canvas").getContext("2d"),
      {
        type: "wordCloud",
        data: {
          labels: words.map((d) => d.key),
          datasets: [
            {
              label: "",
              data: words.map((d) => 10 + d.value * 10),
            },
          ],
        },
        options: {
          title: {
            display: false,
            text: "Chart.js Word Cloud",
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      }
    );
  };

  useEffect(() => {
    headers = [];
    console.log("columnDetailssss", columnDetails);
    // columnDetails?.map((i, j) => {
    //     headers[j] = { label: i?.Header, key: i?.id }
    //     console.log("JJJJJJJJJ", Object.keys(dataArr));

    // })
    // headers?.map((i, j) => {
    //     dataArr = dataArr?.filter(k => Object.keys(k).includes(i?.key))
    //     console.log("IIIIIIIIIIIII", dataArr?.filter(k => Object.keys(k).includes(i?.key)));
    // })

    csvReport = {
      data: subCatData,
      header: headers,
      filename: "sub_category_report_file.csv",
    };
    xlsReport = {
      data: subCatData,
      header: headers,
      filename: "file.xslx",
    };
    console.log("sortedData", headers);
  }, [columnDetails, subCatData]);

  // handle modal while assigning call to evaluator
  const handleModal = (j) => {
    $("#assign_call_" + j).attr("href", "#assign-call");
  };

  // navigate to call register page
  const registerCall = (call_id, lob, campaign, vendor, location, allData) => {
    const allDataString = JSON.stringify(allData);
    const queryString = new URLSearchParams({
      call_id: call_id,
      lob: lob,
      campaign: campaign,
      vendor: vendor,
      location: location,
      allDataString: allDataString,
    }).toString();
    navigate(`/add-call?${queryString}`);
    //navigate(`/add-call?call_id=${call_id}&lob=${lob}&campaign=${campaign}&vendor=${vendor}&location=${location}`);
  };
  const downloadCSV = (data) => {
    const applicationNames = [];
    const categoryNames = [];
    const subCategoryNames = [];
    const applicationData = [];

    data?.forEach((app) => {
      const applicationName = app?.applications?.application_name;
      const categories = app?.applications?.category;
      const subCategories = app?.applications?.category?.flatMap(
        (cat) => cat?.sub_category
      );

      if (applicationName) {
        applicationNames.push(applicationName);
      }

      if (categories) {
        categories.forEach((category) => {
          const categoryName = category?.cat_name;
          if (categoryName) {
            categoryNames.push(`${applicationName}.${categoryName}`);
            subCategories.forEach((subCategory) => {
              const subCategoryName = subCategory?.sub_cat_name;
              if (subCategoryName) {
                categoryNames.push(
                  `${subCategory?.application_name}.${categoryName}.${subCategoryName}`
                );
                subCategoryNames.push(
                  `${subCategory?.application_name}.${categoryName}.${subCategoryName}`
                );
              }
            });
          }
        });
      }
    });

    const headerRow = [
      "agent_emotion",
      "agent_gender",
      "agentid",
      "call_id",
      "campaign",
      "client_emotion",
      "client_gender",
      "datetime",
      "duration",
      "folder_name",
      "id",
      "lob",
      "location",
      "overall_emotion",
      "silence_percentage",
      "tags",
      "tid",
      "vendor",
      "wav",
      ...categoryNames,
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      encodeURIComponent(
        `${headerRow.join(",")}\n` +
          data
            ?.map((record) => {
              let row = `${record.agent_emotion},${record.agent_gender},${record.agentid},${record.call_id},${record.campaign},${record.client_emotion},${record.client_gender},${record.datetime},${record.duration},${record.folder_name},${record.id},${record.lob},${record.location},${record.overall_emotion},${record.silence_percentage},${record.tags},${record.tran_id},${record.vendor},${record.wav}`;

              if (
                applicationNames.length > 0 &&
                !categoryNames.length &&
                !subCategoryNames.length
              ) {
                // Only application exists
                console.log("11111111111111");
                return row;
              } else if (
                applicationNames.length > 0 &&
                categoryNames.length > 0 &&
                !subCategoryNames.length
              ) {
                console.log("22222222222222", subCategoryNames.length);
                // Application and category exist
                row += `,${
                  record?.applications?.category
                    ?.map((i) => i?.phrase_count_percentage)
                    ?.join(",") || ""
                }`;
                return row;
              } else if (
                applicationNames.length > 0 &&
                categoryNames.length > 0 &&
                subCategoryNames.length > 0
              ) {
                console.log("333333333333");
                // Application, category, and subcategory exist
                row += `,${
                  record?.applications?.category
                    ?.map((i) => i?.phrase_count_percentage)
                    ?.join(",") || ""
                },${
                  record?.applications?.category
                    ?.map((i) => i?.tot_cat_wise_match_phrase_count)
                    ?.join(",") || ""
                }`;
                return row;
              } else {
                // None of the conditions are met, handle the case where no relevant data is available
                return row;
              }
            })
            .join("\n")
      );

    const downloadLink = document.createElement("a");
    downloadLink.href = csvContent;
    downloadLink.download = "call_lists.csv";
    downloadLink.click();
  };

  const callApi = async (url, requestOptions, flag = "", type = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setGraphLoader(true);
        switch (flag) {
          case "call-assign":
            console.log("call-assign--------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setTimeout(() => {
                setIsDataFound(false);
                window.location.reload();
              }, 5600);
              // $("#assign_call_" + j).removeAttr('href');
              return;
            }
            break;

          case "upload-phrase":
            console.log("upload phrase-------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setTimeout(() => {
                setIsDataFound(false);
                // navigate('/smartspeech-call-list')
                window.location.reload();
                return;
              }, 5600);
            }
            break;

          case "export-all-calls":
            console.log("export-all-calls---------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              downloadCSV(data?.data);
              // setTimeout(() => {
              //     setIsDataFound(false);
              //     // navigate('/smartspeech-call-list')
              //     window.location.reload();
              //     return;
              // }, 5600);
            }
            break;

          case "download-phrase":
            console.log("donwload-phrase-----", data.data);
            downloadTxtFile("text", data.data);
            break;

          case "folder-wise-app-list":
            console.log("folder-wise-app-list---------", data);
            if (data.status === 200) {
              console.log("IIIIIIII");
              setGraphLoader(false);
              setIsLoading(false);
              setAppListData(data.data);
              // setDisplayCharts('overview')
              if (localStorage.getItem("userRole") === "Quality Supervisor") {
                setDisplayCharts("application");
              }
            }
            break;

          case "all-folders-list":
            console.log("all-folders-list------", data.data);
            if (data.status === 200) {
              setAllFoldersList(data.data);
            }
            break;

          case "folder-list":
            console.log("folder-list--------", data);
            if (data.status) {
              setFolderListData(data.data);
            }
            break;

          case "files-overview":
            if (data.status === 200) {
              console.log("BJFBFFJFFHFHJFFJHF",data?.data?.length);
              
              if(data?.data?.length>0){
                setAppDataForTable(data?.data)
                setIsLoading(false)
                setLoaderSubCat(false)
              }
              setIsLoading(false)
              setLoaderSubCat(false)
            }
            break;
          case "sub-category-files-report":
            
            console.log("data is here--------", data);
            // setSubCatData(data?.data)
            // dataArr = [];
            // dynamicHeadersArr = [];
            if (localStorage.getItem("userRole") === "Quality Supervisor") {
              dynamicHeadersArr = [
                { Header: "Action", accessor: "Action" },
                { Header: "File Name", accessor: "File_Name" },
                { Header: "Agent ID", accessor: "Agent" },
                { Header: "Time", accessor: "Time" },
                { Header: "Duration", accessor: "Duration" },
                { Header: "Silence Time", accessor: "Silence_Time" },
                { Header: "Overtalk", accessor: "Overtalk" },
                { Header: "Diarization", accessor: "Diarization" },
                { Header: "Agent Clarity", accessor: "Agent_Clarity" },
                { Header: "Agent Gender", accessor: "Agent_Gender" },
                { Header: "Client Gender", accessor: "Client_Gender" },
                { Header: "Overall Emotion", accessor: "Overall_Emotion" },
                { Header: "Agent Emotion", accessor: "Agent_Emotion" },
                { Header: "Client Emotion", accessor: "Client_Emotion" },
                { Header: "Transcript ID", accessor: "Transcript_ID" },
                { Header: "Request ID", accessor: "Request_ID" },
                { Header: "Score", accessor: "Score" },
              ];
            } else {
              dynamicHeadersArr = [
                { Header: "File Name", accessor: "File_Name" },
                { Header: "Agent ID", accessor: "Agent" },
                { Header: "Time", accessor: "Time" },
                { Header: "Duration", accessor: "Duration" },
                { Header: "Silence Time", accessor: "Silence_Time" },
                { Header: "Overtalk", accessor: "Overtalk" },
                { Header: "Diarization", accessor: "Diarization" },
                { Header: "Agent Clarity", accessor: "Agent_Clarity" },
                { Header: "Agent Gender", accessor: "Agent_Gender" },
                { Header: "Client Gender", accessor: "Client_Gender" },
                { Header: "Overall Emotion", accessor: "Overall_Emotion" },
                { Header: "Agent Emotion", accessor: "Agent_Emotion" },
                { Header: "Client Emotion", accessor: "Client_Emotion" },
                { Header: "Transcript ID", accessor: "Transcript_ID" },
                { Header: "Request ID", accessor: "Request_ID" },
                { Header: "Score", accessor: "Score" },
              ];
            }

            console.log("34555555555", data?.data?.length);
            if (data?.data?.length > 0) {
              dataArr = [];

              data.data.map((i, j) => {
                if (localStorage.getItem("userRole") === "Quality Supervisor") {
                  setSubCatData(data.data);
                  dataArr.push({
                    Action: (
                      <p className="text-Center">
                        <a
                          onClick={() =>
                            registerCall(
                              i.call_id,
                              i.lob,
                              i.campaign,
                              i.vendor,
                              i.location,
                              data?.data
                            )
                          }
                          className="badge beta declined"
                        >
                          {(i.assign_user_id === null ||
                            i.assign_user_id === undefined) &&
                            i.lob !== "NA" &&
                            i.campaign !== "NA" &&
                            i.location !== "NA" &&
                            i.vendor !== "NA" &&
                            "Register"}
                        </a>
                        <a
                          style={{ marginLeft: "5px" }}
                          data-toggle="modal"
                          href="javascript:void(0);"
                          className="badge beta accepted"
                          data-id={i.id}
                          id={`assign_call_${j}`}
                          onClick={() => [handleModal(j), setCallId(i.id)]}
                        >
                          {(i.assign_user_id === null ||
                            i.assign_user_id === undefined) &&
                          i.lob !== "NA" &&
                          i.campaign !== "NA" &&
                          i.location !== "NA" &&
                          i.vendor !== "NA"
                            ? "Assign"
                            : ""}
                        </a>
                      </p>
                    ),
                    File_Name: (
                      <p>
                        <Link
                          to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i?.call_id}`}
                          target="_blank"
                        >
                          {i.wav}
                        </Link>
                      </p>
                    ),
                    Agent: <p>{i.agentid}</p>,
                    Time: <p>{i.datetime}</p>,
                    Duration: <p>{i.duration}</p>,
                    Silence_Time: <p>{i.silence_percentage}</p>,
                    Overtalk: <p>{i.overtalk}</p>,
                    Diarization: <p>{i.diarization}</p>,
                    Agent_Clarity: <p>{i.agent_clarity}</p>,
                    Agent_Gender: <p>{i.agent_gender}</p>,
                    Client_Gender: <p>{i.client_gender}</p>,
                    Overall_Emotion: <p>{i.overall_emotion}</p>,
                    Agent_Emotion: <p>{i.agent_emotion}</p>,
                    Client_Emotion: <p>{i.client_emotion}</p>,
                    Transcript_ID: <p>{i.tran_id}</p>,
                    Request_ID: <p>{i.req_id}</p>,
                    Score: <p>{i.score}</p>,
                  });
                  setTableData(dataArr)
                } else {
                  setLoaderSubCat(false);
                  setSubCatData(data.data);
                  dataArr.push({
                    File_Name: (
                      <p>
                        <Link
                          to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i?.call_id}`}
                          target="_blank"
                        >
                          {i.wav}
                        </Link>
                      </p>
                    ),
                    Agent: <p>{i.agentid}</p>,
                    Time: <p>{i.datetime}</p>,
                    Duration: <p>{i.duration}</p>,
                    Silence_Time: <p>{i.silence_percentage}</p>,
                    Overtalk: <p>{i.overtalk}</p>,
                    Diarization: <p>{i.diarization}</p>,
                    Agent_Clarity: <p>{i.agent_clarity}</p>,
                    Agent_Gender: <p>{i.agent_gender}</p>,
                    Client_Gender: <p>{i.client_gender}</p>,
                    Overall_Emotion: <p>{i.overall_emotion}</p>,
                    Agent_Emotion: <p>{i.agent_emotion}</p>,
                    Client_Emotion: <p>{i.client_emotion}</p>,
                    Transcript_ID: <p>{i.tran_id}</p>,
                    Request_ID: <p>{i.req_id}</p>,
                    Score: <p>{i.score}</p>,
                  });
                  setTableData(dataArr)
                }
                console.log("columnDetails", columnDetails);

                xlsReport = {
                  data: data?.data,
                  headers: headers,
                  filename: "files.xlsx",
                };
              });

              setIsLoading(false);
              setClickedSubCat("");
              console.log("dataArr----------", dataArr);
            } else {
              dataArr = [];
              setIsLoading(false);
              // ErrorNotify('No Data Available')
              setClickedSubCat("");
              console.log("dataArr----------", dataArr);
              return;
            }
            // if(agentsValue === 'Files') {
            //     return <SSDatatable agentsValue={agentsValue} isAppFilter={false} isQAAutoFilter={true} columns={columns} data={data} loading={false} totalRec={data.length} quickSearch={false} evaluatorSearch={false} />
            // }
            break;

          case "sub-category-overview-data":
            subCatsData = [];
            
            console.log("sub-category-overview-data-----------", data);

            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];
              sub_cat_call_ids_val = [];
              labels = [];
              datasets = [];
              chartDatas = [];
              subCatIDs = [];
              // setClickedAppCatData(false)
              // labels.push('improve', 'negative', 'positive', 'worse');

              // colorsArr.push('red', 'black', 'yellow', 'green', 'pink')
              let call_ids_val = [];
              let total_calls = "";
              console.log("myreqdata", data.category_id);
              subCatHeaderData.map((i, j) => {
                console.log("IHIHIHIHIHI", i.cat_id);
                console.log("IHIHIHGGGGG", data.category_id);
                if (i.cat_id === data.category_id) {
                  setSubCatHeader(i.cat_name);
                }
              });
              data?.data?.map((i, j) => {
                call_ids_val.push(i.call_ids);
                sub_cat_call_ids_val.push(i.call_ids);
                total_calls = i.tot_calls + "min";

                subCatIDs.push(i.subcategory_id);
                console.log("IUIUPPOOOPPOPO", i.subcategory_name?.split(" "));
                labels.push(i.subcategory_name?.split(" ")[0] + "...");
                subCatLabels.push(i.subcategory_name);
                const percentage = ((i.call_ids / totCall) * 100).toFixed(2);
                console.log("percentage", percentage);
                chartDatas.push(percentage);
                console.log("catBgColor", catBgColor);
                function rgbStringToObject(rgbString) {
                  // Split the string by comma and remove the 'rgb(' and ')' parts
                  var rgbArray = rgbString.slice(4, -1).split(",").map(Number);

                  // Create an object with r, g, and b properties
                  var colorObject = {
                    r: rgbArray[0],
                    g: rgbArray[1],
                    b: rgbArray[2],
                  };

                  return colorObject;
                }

                var colorObject = rgbStringToObject(catBgColor);
                console.log("colorObject", colorObject);
                function rgbToHex(r, g, b) {
                  return (
                    "#" +
                    componentToHex(r) +
                    componentToHex(g) +
                    componentToHex(b)
                  );
                }

                function componentToHex(c) {
                  var hex = c?.toString(16);
                  return hex?.length == 1 ? "0" + hex : hex;
                }

                var hexColor = rgbToHex(
                  colorObject.r,
                  colorObject.g,
                  colorObject.b
                );
                console.log("hexColorhexColor", hexColor);
                subCatsData?.push({
                  label: i.subcategory_name,
                  value: percentage,
                  color: hexColor,
                  tooltext: `${i.subcategory_name} : ${percentage}% - ${i.call_ids} of ${totCall} calls scored`,
                });
              });

              datasets.push({
                type: "bar",
                label: "",
                data: chartDatas,
                // fill: true,
                backgroundColor: catBgColor,
                barPercentage: 1,
              });
              console.log("labels555555", labels);
              setIsLoading(false);

              Data11 = {
                labels,
                datasets: datasets,
              };
              console.log("data11---------", Data11);

              setIsLoading(false);
              setIsAppCatClicked(true);
              // setIsClientEmotionClicked(true);

              chartDatas = [];
            }
            break;

          case "agent-emotion-individual-count":
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];

              labels = [];
              let fromDateLabels = [];
              emotionForDonutChart = [];
              datasets = [];
              chartDatas = [];

              let iArr = [];
              let nArr = [];
              let pArr = [];
              let wArr = [];

              let iArrLabel = [];
              let nArrLabel = [];
              let pArrLabel = [];
              let wArrLabel = [];

              labels.push("Positive");
              chartDatas.push(data.data.positive_number);
              emotionForDonutChart.push("Positive");

              labels.push("Negative");
              chartDatas.push(data.data.negative_number);
              emotionForDonutChart.push("Negative");

              labels.push("Improving");
              chartDatas.push(data.data.improve_number);
              emotionForDonutChart.push("Improving");

              labels.push("Worsening");
              chartDatas.push(data.data.worse_number);
              emotionForDonutChart.push("Worsening");

              datasets.push({
                // type: 'bar',
                // label: emotionForDonutChart[0],
                data: chartDatas,
                borderWidth: 0.1,
                radius: "70%",
                // fill: true,
                backgroundColor: [
                  "red",
                  "green",
                  "yellow",
                  "blue",
                  "pink",
                  "grey",
                ],
                // borderColor: 'rgb(194,213,238)'
                // barPercentage: 0.5,
              });
              chartDatas = [];

              data13 = {
                labels,
                emotionForDonutChart,
                datasets: datasets,
              };
              console.log("data13----------", data13);
              setIsLoading(false);
              // setIsCallDurationClicked(true);
            }
            break;

          case "agent-emotion-average-call-duration":
            // console.log('agent-emotion-average-call-duration------------', data)
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];

              labels = [];
              datasets = [];
              chartDatas = [];

              labels.push("Improving", "Negative", "Positive", "Worsening");

              // colorsArr.push('red', 'black', 'yellow', 'green', 'pink')

              [data.data].map((i, j) => {
                chartDatas.push(i.improve, i.negative, i.positive, i.worse);
                //  maxValueIndex = Math.max(i.improve, i.negative, i.positive, i.worse);
                //  {console.log("agent-emotionttttt",maxValueIndex)}
              });

              datasets.push({
                type: "bar",
                label: "agent emotion",
                data: chartDatas,
                // fill: true,
                backgroundColor: [
                  "rgb(194,213,238)",
                  "rgb(249,153,68)",
                  "rgb(31,119,180)",
                  "rgb(249,198,148)",
                ],
                barPercentage: 1,
              });

              Data9 = {
                labels,
                datasets: datasets,
              };
              // console.log('data8---------', Data9)

              setIsLoading(false);
              setIsCallDurationClicked(false);
              setIsClientEmotionClicked(true);
              setIsAgentEmotionClicked(true);

              chartDatas = [];
            }
            // setClickedAgentEmotionDate('')
            break;

          case "client-emotion-individual-count":
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];

              labels = [];
              let fromDateLabels = [];
              emotionForDonutChart = [];
              datasets = [];
              chartDatas = [];

              let iArr = [];
              let nArr = [];
              let pArr = [];
              let wArr = [];

              let iArrLabel = [];
              let nArrLabel = [];
              let pArrLabel = [];
              let wArrLabel = [];

              labels.push("Positive");
              chartDatas.push(data.data.positive_number);
              emotionForDonutChart.push("Positive");

              labels.push("Negative");
              chartDatas.push(data.data.negative_number);
              emotionForDonutChart.push("Negative");

              labels.push("Improving");
              chartDatas.push(data.data.improve_number);
              emotionForDonutChart.push("Improving");

              labels.push("Worsening");
              chartDatas.push(data.data.worse_number);
              emotionForDonutChart.push("Worsening");

              datasets.push({
                // type: 'bar',
                // label: labels,
                data: chartDatas,
                borderWidth: 0.1,
                radius: "70%",
                // fill: true,
                backgroundColor: [
                  "red",
                  "green",
                  "yellow",
                  "blue",
                  "pink",
                  "grey",
                ],
                // borderColor: 'rgb(194,213,238)'
                // barPercentage: 0.5,
              });
              chartDatas = [];

              data14 = {
                labels,
                emotionForDonutChart,
                datasets: datasets,
              };
              console.log("data14----------", data14);
              setIsLoading(false);
              // setIsCallDurationClicked(true);
            }
            break;

          case "client-emotion-average-call-duration":
            // console.log('client-emotion-average-call-duration------------', data)
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];

              labels = [];
              datasets = [];
              chartDatas = [];

              labels.push("improving", "negative", "positive", "worsening");

              // colorsArr.push('red', 'black', 'yellow', 'green', 'pink')

              [data.data].map((i, j) => {
                chartDatas.push(i.improve, i.negative, i.positive, i.worse);
                // maxValueIndex = Math.max(i.improve, i.negative, i.positive, i.worse);
                // {console.log("agent-emotionttttt",maxValueIndex)}
              });

              datasets.push({
                type: "bar",
                label: "client emotion",
                data: chartDatas,
                // fill: true,
                backgroundColor: [
                  "rgb(194,213,238)",
                  "rgb(249,153,68)",
                  "rgb(31,119,180)",
                  "rgb(249,198,148)",
                ],
                barPercentage: 1,
              });

              Data8 = {
                labels,
                datasets: datasets,
              };
              // console.log('data8---------', Data8)

              setIsLoading(false);
              setIsCallDurationClicked(true);
              setIsClientEmotionClicked(true);

              chartDatas = [];
            }
            break;

          case "call-breakdown-duration":
            console.log("call-breakdown-duration-----", data);
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];

              labels = [];
              let fromDateLabels = [];
              datasets = [];
              chartDatas = [];

              let iArr = [];
              let nArr = [];
              let pArr = [];
              let wArr = [];

              let iArrLabel = [];
              let nArrLabel = [];
              let pArrLabel = [];
              let wArrLabel = [];

              data.data.map((i, j) => {
                labels.push(i.range + "min");
                chartDatas.push(i.count);
              });
              console.log("value of i----", chartDatas);
              datasets.push({
                // type: 'bar',
                // label: labels,
                data: chartDatas,
                borderWidth: 0.1,
                radius: "70%",
                // fill: true,
                backgroundColor: [
                  "red",
                  "green",
                  "yellow",
                  "blue",
                  "pink",
                  "grey",
                ],
                // borderColor: 'rgb(194,213,238)'
                // barPercentage: 0.5,
              });
              chartDatas = [];

              data12 = {
                labels,
                datasets: datasets,
              };
              console.log("data12----------", data12);
              setIsLoading(false);
              // setIsCallDurationClicked(true);

              chartDatas = [];
            }
            break;

          case "call-charts-duration-emotion":
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];

              labels = [];
              let fromDateLabels = [];
              let fromEmotionLabels = [];
              datasets = [];
              chartDatas = [];

              let iArr = [];
              let nArr = [];
              let pArr = [];
              let wArr = [];

              let iArrLabel = [];
              let nArrLabel = [];
              let pArrLabel = [];
              let wArrLabel = [];
              // maxValueIndex = 0;

              [data].map((i, j) => {
                Object.keys(i).forEach((a, b) => {
                  i[a].range !== undefined && labels.push(i[a].range + "min");

                  if (i[a].Improving !== undefined) {
                    iArr.push(i[a].Improving);
                    iArrLabel.push("improving");
                    fromEmotionLabels.push("Improving");
                  }
                  if (i[a].Negative !== undefined) {
                    nArr.push(i[a].Negative);
                    nArrLabel.push("negative");
                    fromEmotionLabels.push("Negative");
                  }
                  if (i[a].Positive !== undefined) {
                    pArr.push(i[a].Positive);
                    pArrLabel.push("positive");
                    fromEmotionLabels.push("Positive");
                  }
                  if (i[a].Worsening !== undefined) {
                    wArr.push(i[a].Worsening);
                    wArrLabel.push("worsening");
                    fromEmotionLabels.push("Worsening");
                  }
                  // if (i[a].total !== undefined) {

                  //     maxValueIndex = Math.max(maxValueIndex, i[a].total);
                  // }
                  // {console.log("totallllll",maxValueIndex)}
                });
                datasets.push(
                  {
                    type: "bar",
                    label: "improving",
                    data: iArr,
                    fill: true,
                    backgroundColor: ["rgb(194,213,238)"],
                    borderColor: "rgb(194,213,238)",
                    // barPercentage: 0.5,
                  },
                  {
                    type: "bar",
                    label: "negative",
                    data: nArr,
                    fill: true,
                    backgroundColor: ["rgb(249,153,68)"],
                    borderColor: "rgb(249,153,68)",
                    // barPercentage: 0.5,
                  },
                  {
                    type: "bar",
                    label: "positive",
                    data: pArr,
                    fill: true,
                    backgroundColor: ["rgb(31,119,180)"],
                    borderColor: "rgb(31,119,180)",
                    // barPercentage: 0.5,
                  },
                  {
                    type: "bar",
                    label: "worsening",
                    data: wArr,
                    fill: true,
                    backgroundColor: ["rgb(249,198,148)"],
                    borderColor: "rgb(249,198,148)",
                    // barPercentage: 0.5,
                  }
                );
                chartDatas = [];
              });

              Data7 = {
                labels,
                fromEmotionLabels,
                datasets: datasets,
              };
              console.log("data7----------", Data7);
              setIsLoading(false);
              setIsCallDurationClicked(true);

              chartDatas = [];
            }
            break;

          case "call-duration-overview-data":
            // console.log('call-duration-overview-data--------', data)
            if (data.status === 200) {
              // setCallDurationOverviewData(data.data);
              labels = [];
              let fromDateLabels = [];
              datasets = [];
              chartDatas = [];

              let attArr = [];
              let cdArr = [];
              let cttArr = [];
              let sdArr = [];

              let attArrLabel = [];
              let cdArrLabel = [];
              let cttArrLabel = [];
              let sdArrLabel = [];

              data.data.map((i, j) => {
                fromDateLabels.push(i.start_date);
                labels.push(i.end_date);

                attArr.push(i.agent_talk_time);
                attArrLabel.push("Agent Talk Time");

                cdArr.push(i.call_duration);
                cdArrLabel.push("Call Duration");

                cttArr.push(i.client_talk_time);
                cttArrLabel.push("Client Talk Time");

                sdArr.push(i.silence_duration);
                sdArrLabel.push("Silence Duration");
              });

              // data.data.map((i, j) => {
              //     labels.push(i.end_date);
              //     chartDatas.push(i.agent_talk_time, i.call_duration, i.client_talk_time, i.silence_duration);
              //     datasets.push(
              //         {
              //             type: 'line',
              //             // label: i.seriesname,
              //             data: chartDatas,
              //             fill: true,
              //             backgroundColor: ['rgba(6, 132, 208, 0.5)'],
              //             tension: 0.4
              //             // barPercentage: 0.5,
              //         },
              //     )
              //     chartDatas = [];
              // })

              datasets.push(
                {
                  type: "line",
                  label: "Agent Talk Time",
                  data: attArr,
                  fill: true,
                  backgroundColor: ["rgb(194,213,238)"],
                  borderColor: "rgb(194,213,238)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Call Duration",
                  data: cdArr,
                  fill: true,
                  backgroundColor: ["rgb(249,153,68)"],
                  borderColor: "rgb(249,153,68)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Client Talk Time",
                  data: cttArr,
                  fill: true,
                  backgroundColor: ["rgb(31,119,180)"],
                  borderColor: "rgb(31,119,180)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Silence Duration",
                  data: sdArr,
                  fill: true,
                  backgroundColor: ["rgb(249,198,148)"],
                  borderColor: "rgb(249,198,148)",
                  // barPercentage: 0.5,
                }
              );
              chartDatas = [];

              Data3 = {
                labels,
                fromDateLabels,
                datasets: datasets,
              };
              // Data2.push(labels)
              // console.log('call-duration-overview-data datasets are here--------', Data3)
              setIsCallDurationClicked(true);
              // setIsAgentEmotionChartLoaded(false);
              setIsCallDurationChartLoaded(true);
              setIsLoading(false);
              chartDatas = [];
            }
            break;

          case "agent-emotion-overview-data":
            //console.log('agent-emotion-overview-data--------', data);
            if (data.status === 200) {
              // setCallDurationOverviewData(data.data);
              labels = [];
              let fromDateLabels = [];
              datasets = [];
              chartDatas = [];

              let iArr = [];
              let nArr = [];
              let pArr = [];
              let wArr = [];

              let iArrLabel = [];
              let nArrLabel = [];
              let pArrLabel = [];
              let wArrLabel = [];

              data.data.map((i, j) => {
                fromDateLabels.push(i.start_date);
                labels.push(i.end_date);

                iArr.push(i.improve);
                iArrLabel.push("Positive");

                nArr.push(i.negative);
                nArrLabel.push("Negative");

                pArr.push(i.positive);
                pArrLabel.push("positive");

                wArr.push(i.worse);
                wArrLabel.push("worsening");
              });

              // chartDatas.push(iArr, nArr, pArr, wArr);
              datasets.push(
                {
                  type: "line",
                  label: " Positive",
                  data: iArr,
                  fill: true,
                  backgroundColor: ["rgb(194,213,238)"],
                  borderColor: "rgb(194,213,238)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Negative",
                  data: nArr,
                  fill: true,
                  backgroundColor: ["rgb(249,153,68)"],
                  borderColor: "rgb(249,153,68)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Improving",
                  data: pArr,
                  fill: true,
                  backgroundColor: ["rgb(31,119,180)"],
                  borderColor: "rgb(31,119,180)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Worsening",
                  data: wArr,
                  fill: true,
                  backgroundColor: ["rgb(249,198,148)"],
                  borderColor: "rgb(249,198,148)",
                  // barPercentage: 0.5,
                }
              );
              chartDatas = [];

              // data.data.map((i, j) => {
              //     labels.push(i.end_date);
              //     chartDatas.push(i.improve, i.negative, i.positive, i.worse);
              //     datasets.push({
              //         type: 'line',
              //         // label: i.seriesname,
              //         data: chartDatas,
              //         fill: true,
              //         backgroundColor: ['rgba(6, 132, 208, 0.5)'],
              //         // barPercentage: 0.5,
              //     })
              //     chartDatas = [];
              // })

              Data4 = {
                labels,
                fromDateLabels,
                datasets: datasets,
              };
              // Data2.push(labels)
              console.log("data4--------", Data4);
              setIsAgentEmotionClicked(true);
              setIsAgentEmotionChartLoaded(true);
              // setIsCallDurationChartLoaded(false);
              setIsLoading(false);
              chartDatas = [];
            }

            break;

          case "client-emotion-overview-data":
            // console.log('client-emotion-overview-data--------', data);
            if (data.status === 200) {
              // setCallDurationOverviewData(data.data);
              labels = [];
              datasets = [];
              chartDatas = [];
              let fromDateLabels = [];

              let iArr = [];
              let nArr = [];
              let pArr = [];
              let wArr = [];

              data.data.map((i, j) => {
                fromDateLabels.push(i.start_date);
                labels.push(i.end_date);
                iArr.push(i.improve);
                nArr.push(i.negative);
                pArr.push(i.positive);
                wArr.push(i.worse);
                // maxValueIndex = Math.max(i.improve, i.negative, i.positive, i.worse);
              });

              // chartDatas.push(iArr, nArr, pArr, wArr);
              datasets.push(
                {
                  type: "line",
                  label: "Positive",
                  data: iArr,
                  fill: true,
                  backgroundColor: ["rgb(194,213,238)"],
                  borderColor: "rgb(194,213,238)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Negative",
                  data: nArr,
                  fill: true,
                  backgroundColor: ["rgb(249,153,68)"],
                  borderColor: "rgb(249,153,68)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Improving",
                  data: pArr,
                  fill: true,
                  backgroundColor: ["rgb(31,119,180)"],
                  borderColor: "rgb(31,119,180)",
                  // barPercentage: 0.5,
                },
                {
                  type: "line",
                  label: "Worsening",
                  data: wArr,
                  fill: true,
                  backgroundColor: ["rgb(249,198,148)"],
                  borderColor: "rgb(249,198,148)",
                  // barPercentage: 0.5,
                }
              );
              chartDatas = [];

              // data.data.map((i, j) => {
              //     labels.push(i.end_date);
              //     chartDatas.push(i.improve, i.negative, i.positive, i.worse);
              //     datasets.push({
              //         type: 'line',
              //         // label: i.seriesname,
              //         data: chartDatas,
              //         fill: true,
              //         backgroundColor: ['rgba(6, 132, 208, 0.5)'],
              //         // barPercentage: 0.5,
              //     })
              //     chartDatas = [];
              // })

              Data5 = {
                labels,
                fromDateLabels,
                datasets: datasets,
              };
              // console.log('data5-------', Data5)
              // setIsClientEmotionClicked(true);
              setIsCallDurationClicked(false);
              setIsClientEmotionChartLoaded(true);
              setIsLoading(false);
              chartDatas = [];
            }

            break;

          case "overview-list":
            console.log("data.length-----------", data.data.length);
            if (data.data.length > 0) {
              dataArr = [];
              dynamicHeadersArr = [{ Header: "Date", accessor: "date" }];
              //let dummyDataArr = [];
              const dummyDataObj = {};
              const addedAccessors = new Set(); // Set to track added accessors

              data?.data?.forEach((item) => console.log("dafagaga", item.date));

              data?.data?.forEach((item, index) => {
                //     const date = item.date;
                //     dynamicHeadersArr.push({ Header: "Date", accessor: "date" });
                //     Object.keys(item).forEach((key) => {
                //         if (key !== "date") {
                //             const catName = item[key].cat_name;
                //             const percentage = key.startsWith("4") || key.startsWith("5") ?
                //                 `${item[key].phrase_count_percentage}%` : item[key].phrase_count_percentage;
                //             dynamicHeadersArr.push({ Header: catName, accessor: catName.replace(/ /g, "_") });
                //             dummyDataObj[catName.replace(/ /g, "_")] = percentage;
                //         }
                //     });

                // dummyDataObj["date"] = date;
                const date = item.date;
                dummyDataObj["date"] = date;

                Object.keys(item).forEach((key) => {
                  if (key !== "date") {
                    const catName = item[key].cat_name;
                    const accessor = catName.replace(/ /g, "_");
                    const percentage =
                      key.startsWith("4") || key.startsWith("5")
                        ? `${item[key].phrase_count_percentage}%`
                        : item[key].phrase_count_percentage;

                    if (!addedAccessors.has(accessor)) {
                      dynamicHeadersArr.push({
                        Header: catName,
                        accessor: accessor,
                      });
                      addedAccessors.add(accessor);
                    }

                    dummyDataObj[accessor] = percentage;
                  }
                });
                console.log("dummyDataObjdummy", Object.keys(dummyDataObj));
                dataArr.push({ ...dummyDataObj });
                csvReport = {
                  data: dataArr,
                  headers: headers,
                  filename: "summary.csv",
                };
              });
              setIsLoading(false);
              // let result = Object.keys(dataArr).map((key) => [(key), dataArr[key]]);
              // if(typeof dataArr === 'object') {
              //     dataArr = Object.entries(dataArr);
              // }
              // console.log('result is-----------', result)
            }

            break;

          case "call-statistics-data":
            console.log("call-statistics-data--------", data);
            if (data.status === 200) {
              setCallStaticsDataToday(data);
              setColumnsArr(data.column);
            }
            break;

          case "overview-portion-data":
            // console.log('overview-portion-data---------------------', data)
            if (data.status === 200) {
              setOverviewPortionData(data.data);

              labels = [];
              datasets = [];
              chartDatas = [];

              data.data.map((i, j) => {
                labels.push(i.end_date);
                chartDatas.push(i.tot_res);
              });

              datasets.push({
                type: "line",
                label: "Total Calls",
                data: chartDatas,
                fill: true,
                backgroundColor: ["rgba(6, 132, 208, 0.5)"],
                // barPercentage: 0.5,
              });

              Data2 = {
                labels,
                datasets: datasets,
              };
              // console.log('overview-portion-data datasets are here--------', Data2)
              chartDatas = [];
              setIsLoading(false);
            }
            break;

          case "app-list":
            // console.log('application list data===========', data);
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              setAppListData(
                data.data.application
                  .filter((i, j) => i.application_type === "general")
                  .map((app) => app)
              );
              // SuccessNotify(data.message);
              return;
            }
            break;
          case "cat-list":
            // console.log('cat list========', data);
            if (data.status === 200) {
              setLoadingLoader(false);
              setCatListData(data.data.category);

              return;
            }
            if (data.status === 302) {
              ErrorNotify(data.message);
              return;
            }
            setCatListData([]);
            setSubCatListData([]);
            break;
          case "add-cat":
            // console.log('add category=========', data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              getCategories(clickedAppId);
              return;
            }
            break;
          case "sub-cat-list":
            // console.log('sub-cat-list==========', data);
            setPhraseListData([]);
            if (!data.data.subcategorylist.length) {
              setSubCatListData([]);
              setSubCatListDDData([]);
              ErrorNotify("No sub category found");
              return;
            }
            if (data.status === 200) {
              setLoadingLoader(false);
              if (type === "dropdown") {
                setSubCatListDDData(data.data.subcategorylist);
              } else {
                setSubCatListData(data.data.subcategorylist);
              }
              return;
            }
            ErrorNotify(data.message);
            break;
          case "add-sub-cat":
            // console.log('sub cateory added=====', data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              getSubCategories(clickedCatId);
              return;
            }
            ErrorNotify(data.message);
            break;
          case "phrase-list":
            // console.log('phrase-list--------', data);

            if (!data.exclude_phrase_list.length) {
              setExcludePhraseList(data.exclude_phrase_list);
              !data.exclude_phrase_list && ErrorNotify("No Phrase List found");
              // return;
            }
            setExcludePhraseList(data.exclude_phrase_list);

            if (!data.include_phrase_list.length) {
              setIncludePhraseList(data.include_phrase_list);
              !data.include_phrase_list.length &&
                ErrorNotify("No Phrase List found");
              // return;
            }
            setIncludePhraseList(data.include_phrase_list);
            // console.log('phrase-list--------', data);
            // if (!data.data.phraseslist.length) {
            //     setPhraseListData(data.data.phraseslist);
            //     ErrorNotify('No Phrase List found');
            //     return;
            // }
            // if (data.status === 200) {
            //     setPhraseListData(data.data.phraseslist);
            //     return;
            // }
            // console.log('hre')
            // ErrorNotify(data.message);
            break;
          case "add-phrase":
            // console.log('add phrase list data', data);
            if (data.status === 200) {
              setTermText("");
              setTermPhrase("");
              SuccessNotify(data.message);
              getPhraseData(clickedAppId, clickedCatId, clickedSubCatId);
              return;
            }
            break;
          case "cat-report":
            // console.log('cat-report data---------', data)

            if (data.status === 200) {
              setCatReportData(data.data);
              setLoadingLoader(false);

              dataArr = [];
              dynamicHeadersArr = [];
              let dynamicHeadersArr2 = [];
              let dummyDataArr = [];

              data.data.map((i, j) => {
                dynamicHeadersArr?.push({
                  Header: i?.cat_name,
                  accessor: i?.cat_name.replace(/ /g, "_"),
                });
                dynamicHeadersArr2?.push({
                  label: i?.cat_name,
                  key: i?.cat_name,
                });
                // dataArr.push([i.cat_name.replace(/ /g, "_")] +':'+ <p>{i.phrase_count_percentage}</p>)
                // dataArr.push(
                //     {
                //         [i.cat_name.replace(/ /g, "_")]: <p>{i.phrase_count_percentage}</p>
                //     }
                // )
                dummyDataArr[i?.cat_name.replace(/ /g, "_")] = (
                  <p>{i.phrase_count_percentage}</p>
                );
              });
              csvReport = {
                data: dataArr,
                headers: dynamicHeadersArr2,
                filename: "application_data.csv",
              };
              setIsLoading(false);
              dataArr.push(dummyDataArr);
              console.log("data arr is here--------", dataArr);
              // setSubCatData(dataArr)
            }
            setIsLoading(false);
            break;

          case "summary-report":
            // console.log('summary-report-------------', data.data[0].category)
            console.log("data.length------", data.data.length);
            if (data.data.length > 0) {
              dataArr = [];

              let dummyDataArr1 = [];
              data.data.map((i, j) => {
                dynamicHeadersArr = [];

                i.category.map((a, b) => {
                  dynamicHeadersArr.push({
                    Header: a.cat_name,
                    accessor: a.cat_name.replace(/ /g, "_"),
                  });
                  dummyDataArr1[a.cat_name.replace(/ /g, "_")] = (
                    <p>{a.phrase_count_percentage}</p>
                  );
                });

                dataArr.push(dummyDataArr1);
              });
              setIsLoading(false);
            }
            setIsLoading(false);
            break;
          case "category-overview-data":
            console.log("category-overview-data----------", data);
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              let colorsArr = [];

              labels = [];
              datasets = [];
              // data?.label?.map(i=>{
              //     labels.push(i)
              // })
              //labels.push('2023-04-01', '2023-04-08', '2023-04-15', '2023-04-22', '2023-04-30');
              //console.log("data?.data",data?.data);
              colorsArr.push("red", "black", "yellow", "green", "pink");

              catIDs = [];
              let lastVal = 0;
              setSubCatHeaderData(data.data);
              totCall = data?.tot_count;
              setLoadingLoader(false);
              // data.data.map((i, j) => {
              //     const uniqueCallIds = [...new Set(i.call_ids)]; // Remove duplicates
              //     if (uniqueCallIds.length > 0) {
              //         catIDs.push(i.cat_id);

              //         uniqueCallIds.forEach((a, b) => {
              //             if (lastVal !== a) {
              //                 stackValues.push(a);
              //             }
              //         });
              //         console.log("catIDs",catIDs);

              //         datasets.push({
              //             type: 'bar',
              //             label: i.catname,
              //             data: uniqueCallIds,
              //             //backgroundColor: [colorsArr[Math.floor(Math.random() * colorsArr.length)]],
              //             backgroundColor:"red",
              //             barPercentage: 1,
              //         });
              //     }

              //     lastVal = j;
              // });
              let usedColors = new Set();
              const maxDataLength = Math.max(
                data.label.length,
                data.data.length
              );

              for (let index = 0; index < data.data.length; index++) {
                //const label = data.label[index] || ''; // Use the label if available, or an empty string if not
                //labels.push(label);

                const category = data.data[index] || {
                  catname: "",
                  call_ids: [],
                }; // Use an empty category if data is not available
                const uniqueCallIds = category.call_ids;
                //const backgroundColor = colorsArr[index % colorsArr.length];
                const backgroundColor = getRandomUniqueColor();
                catIDs.push(data.data[index]?.cat_id);
                console.log("index.cat_id", catIDs);
                datasets.push({
                  type: "bar",
                  label: category.cat_name,
                  data: uniqueCallIds,
                  backgroundColor,
                  barPercentage: 1,
                });
              }
              for (let j = 0; j < data.label.length; j++) {
                labels.push(data.label[j]);
              }
              function getRandomUniqueColor() {
                let randomColor;
                do {
                  randomColor = getRandomColor();
                } while (usedColors.has(randomColor));

                usedColors.add(randomColor);
                return randomColor;
              }

              // Function to generate a random RGB color
              function getRandomColor() {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                return `rgb(${r}, ${g}, ${b})`;
              }
              setIsRenderData(true);
              console.log("filterReset",filterReset);
              
                Data10 = {
                  labels,
                  datasets: datasets,
                };
              
              
              
              console.log("data10---------", Data10);

              setIsLoading(false);
              // setIsCallDurationClicked(true);
              // setIsClientEmotionClicked(true);
            }
            setIsLoading(false);
            break;
          default:
            break;
        }
      });
  };

  const chartRefApp = useRef();
  // const onClickApp = (event) => {
  //     setLoadingLoader(true)
  //     const points = getElementAtEvent(chartRefApp.current, event);

  //     if (points.length > 0) {

  //         // const bg = points[0].element.options.backgroundColor;
  //         // console.log(points[0].element.options.backgroundColor)
  //         // console.log(points[0].element.options.borderColor)
  //         // console.log(points[0].element.$context.raw.x)
  //         // console.log(points[0].element.$context.raw.cost)

  //         const clickDatasetIndex = getElementAtEvent(chartRefApp.current, event)[0].datasetIndex;
  //         console.log("clickDatasetIndex",event);
  //         const dataPoint = getElementAtEvent(chartRefApp.current, event)[0].datasetIndex;
  //         const dataPoint2 = points[0].index; // Get the index of the clicked bar
  //     const date2 = Data10.labels[dataPoint]; // Get the date corresponding to the clicked bar
  //     console.log('Clicked bar date:', date2);
  //         console.log("Data10",Data10);
  //         const label = Data10.labels[dataPoint];
  //         const date = new Date(label);

  //     // Calculate the start and end dates of the week containing the clicked date
  //         const startDate = new Date(date);
  //         startDate.setDate(date.getDate() - date.getDay()); // Set to the start of the week (Sunday)
  //         const endDate = new Date(date);
  //         endDate.setDate(date.getDate() - date.getDay() + 6); // Set to the end of the week (Saturday)

  //     console.log('Clicked bar label (date):', label);
  //     console.log('Week start date:', moment(startDate).format("YYYY-MM-DD"));
  //     console.log('Week end date:', moment(endDate).format("YYYY-MM-DD"));
  //         //setWeeklyFromDate(moment(startDate).format("YYYY-MM-DD"))
  //         //setWeeklyToDate(moment(endDate).format("YYYY-MM-DD"))

  //         console.log('getElementAtEvent(chartRefApp.current, event)--------', getElementAtEvent(chartRefApp.current, event))
  //         console.log('points are here----------', points[0]);
  //         console.log('catIDs is here-----', catIDs)
  //         console.log('dataPoint--------', dataPoint)
  //         console.log('catIDs[dataPoint]-----', catIDs[dataPoint])
  //         // console.log('Data10.datasets[dataPoint].backgroundColor---------',Data10.datasets[dataPoint].backgroundColor)
  //         // Data10.datasets[dataPoint].backgroundColor = bg;

  //         setClickedAppCatData(catIDs[dataPoint])
  //         // setClickedAppCatData('7462645391')

  //     }
  // }

  const onClickApp = (event) => {
    setLoadingLoader(true);
    const points = getElementAtEvent(chartRefApp.current, event);

    if (points.length > 0) {
      setWeeklyFromDate("");
      setWeeklyToDate("");

      const dataPoint = points[0].index; // Get the index of the clicked bar
      const label = Data10.labels[dataPoint]; // Get the label (date) corresponding to the clicked bar
      const dataPoint2 = getElementAtEvent(chartRefApp.current, event)[0]
        .datasetIndex;
      catBgColor = Data10.datasets[dataPoint2].backgroundColor;

      const date = new Date(label);
      console.log("dataPoint2", catIDs[dataPoint2]);
      // Calculate the start and end dates of the week containing the clicked date
      const startDate = new Date(date);
      startDate.setDate(date.getDate() - date.getDay()); // Set to the start of the week (Sunday)
      const endDate = new Date(date);
      endDate.setDate(date.getDate() - date.getDay() + 6); // Set to the end of the week (Saturday)

      console.log("Clicked bar label (date):", label);
      console.log("Week start date:", moment(startDate).format("YYYY-MM-DD"));
      console.log("Week end date:", moment(endDate).format("YYYY-MM-DD"));
      setWeeklyFromDate(moment(startDate).format("YYYY-MM-DD"));
      setWeeklyToDate(moment(endDate).format("YYYY-MM-DD"));
      const clickDatasetIndex = points[0].datasetIndex;
      console.log("Clicked dataset index:", clickDatasetIndex);
      setClickedAppCatData(catIDs[dataPoint2]);

      // Now, you have the start and end dates of the week containing the clicked date.
    }
  };

  // call file listing api when click on agent emotion line chart starts here

  useEffect(() => {
    if (clickedAgentEmotionBarsDate !== "") {
      setIsLoading(true);
      var url = `${base_url}smartspeech/files-overview`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
          agent_emotion: clickedAgentEmotionBarsDate,
          duration: `${
            startDuration || endDuration !== ""
              ? startDuration + "-" + endDuration
              : ""
          }`,
          folder_id: IdOfFolder,
        }),
      };
      callApi(url, requestOptions, "files-overview");
    }
  }, [clickedAgentEmotionBarsDate]);

  // call file listing api when click on client emotion line chart starts here

  useEffect(() => {
    if (clickedCEBarsDate !== "") {
      setIsLoading(true);
      var url = `${base_url}smartspeech/files-overview`;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date:
            fromDateForApp && fromDateForApp !== ""
              ? fromDateForApp
              : filterPayload?.overall_from_date,
          to_date:
            toDateForApp && toDateForApp !== ""
              ? toDateForApp
              : filterPayload?.overall_to_date,
          client_emotion: clickedCEBarsDate,
          duration: `${
            startDuration || endDuration !== ""
              ? startDuration + "-" + endDuration
              : ""
          }`,
          folder_id: IdOfFolder,
        }),
      };
      callApi(url, requestOptions, "files-overview");
    }
  }, [clickedCEBarsDate]);

  // load file data when clicked on sub category starts
  useEffect(() => {
    if (clickedSubCat !== "") {
      var url = `${base_url}callspeech/sub-category-files-report`;
      setAppDataForTable([])

      let subcatFileReportPayload = {
        from_date: weeklyFromDate,
        to_date: weeklyToDate,
        application: clickedAppId,
        category_id: clickedAppCatData,
        subcategory_id: clickedSubCat,
        folder_id: folderID,
      }
      if(process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbank3po" || process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "rogersbankfpo"){
        subcatFileReportPayload["group_hunt"] = huntsId?.filter(f=>f?.value!=="All")?.map(i=>i?.value)
      }

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify(subcatFileReportPayload),
      };
      callApi(url, requestOptions, "sub-category-files-report");
    }
  }, [clickedSubCat]);
  // load file data when clicked on sub category ends

  // getting clicked on sub categories of application
  const chartRefSubCat = useRef();
  const onClickSubCat = (event) => {
    setLoaderSubCat(true);
    console.log("454545454545455");
    if (getElementAtEvent(chartRefSubCat.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(
        chartRefSubCat.current,
        event
      )[0].datasetIndex;
      const dataPoint = getElementAtEvent(chartRefSubCat.current, event)[0]
        .index;
      console.log("subCatIDs", subCatIDs);
      setClickedSubCat(subCatIDs[dataPoint]);
      setClickedCatID(catIDs[dataPoint]);
      // setClickedAgentEmotionBarsDate('2023-03-01')
    }
  };

  // when clicked on agent bar chart to get file data
  const chartRefAgentBars = useRef();
  const onClickAgentBars = (event) => {
    if (getElementAtEvent(chartRefAgentBars.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(
        chartRefAgentBars.current,
        event
      )[0].datasetIndex;
      const dataPoint = getElementAtEvent(chartRefAgentBars.current, event)[0]
        .index;

      setClickedAgentEmotionBarsDate(Data9.labels[dataPoint]);
      // setClickedAgentEmotionBarsDate('2023-03-01')
    }
  };

  // when clicked on call duration bar charts to get file data
  const chartRefCDBars = useRef();
  const onClickCDBars = (event) => {
    if (getElementAtEvent(chartRefCDBars.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(
        chartRefCDBars.current,
        event
      )[0].datasetIndex;
      const dataPoint = getElementAtEvent(chartRefCDBars.current, event)[0]
        .index;
      const dataPoint1 = getElementAtEvent(chartRefCDBars.current, event)[0]
        .datasetIndex;

      setClickedCDBarsDate(Data7.labels[dataPoint]);
      setClickedCDBarsEmotionDate(Data7.fromEmotionLabels[dataPoint1]);
      // setClickedCDBarsDate('2023-03-01')
    }
  };

  // call file listing api when click on agent emotion line chart ends here

  const chartRefAgent = useRef();
  const onClickAgent = (event) => {
    if (getElementAtEvent(chartRefAgent.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(
        chartRefAgent.current,
        event
      )[0].datasetIndex;
      const dataPoint = getElementAtEvent(chartRefAgent.current, event)[0]
        .index;

      setClickedAgentEmotionStartDate(Data4.fromDateLabels[dataPoint]);
      setClickedAgentEmotionDate(Data4.labels[dataPoint]);
      // setClickedAgentEmotionDate('2023-03-01')
    }
  };

  const chartRefClient = useRef();
  const onClickClient = (event) => {
    if (getElementAtEvent(chartRefClient.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(
        chartRefClient.current,
        event
      )[0].datasetIndex;
      const dataPoint = getElementAtEvent(chartRefClient.current, event)[0]
        .index;

      setClickedClientEmotionStartDate(Data5.fromDateLabels[dataPoint]);
      setClickedClientEmotionDate(Data5.labels[dataPoint]);
      // setClickedClientEmotionDate('2023-03-01')
    }
  };

  // when clicked on client emotion bar charts to get file data
  const chartRefCEBars = useRef();
  const onClickCEBars = (event) => {
    if (getElementAtEvent(chartRefCEBars.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(
        chartRefCEBars.current,
        event
      )[0].datasetIndex;
      const dataPoint = getElementAtEvent(chartRefCEBars.current, event)[0]
        .index;
      const dataPoint1 = getElementAtEvent(chartRefCEBars.current, event)[0]
        .datasetIndex;

      setClickedCEBarsDate(Data8.labels[dataPoint]);
      setClickedCEBarsEmotionDate(Data8.fromDateLabels[dataPoint1]);
      // setClickedCDBarsDate('2023-03-01')
    }
  };

  const chartRefVolumn = useRef();
  const onClickVolumn = (event) => {
    if (getElementAtEvent(chartRefVolumn.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(
        chartRefVolumn.current,
        event
      )[0].datasetIndex;
      const dataPoint = getElementAtEvent(chartRefVolumn.current, event)[0]
        .index;

      setClickedCallVolumnDate(Data2.labels[dataPoint]);
      // setClickedClientEmotionDate('2023-03-01')
    }
  };

  const chartRef = useRef();
  const onClick = (event) => {
    if (getElementAtEvent(chartRef.current, event).length > 0) {
      const clickDatasetIndex = getElementAtEvent(chartRef.current, event)[0]
        .datasetIndex;
      const dataPoint = getElementAtEvent(chartRef.current, event)[0].index;

      setClickedCallDurationStartDate(Data3.fromDateLabels[dataPoint]);
      setClickedCallDurationDate(Data3.labels[dataPoint]);
      // setClickedCallDurationDate('2023-03-01')
    }
  };

  // for downloading phrase data when clicked on download button of settings of an application
  const downloadPhrase = () => {
    var url = `${base_url}callspeech/download-phrase`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        application: clickedAppId,
      }),
    };

    callApi(url, requestOptions, "download-phrase");
  };

  // for downloading call datails in text format
  const downloadTxtFile = (type = "", data) => {
    if (type === "text") {
      const element = document.createElement("a");
      const file = new Blob([data], { type: "text/plain;charset=utf-8" });
      element.href = URL.createObjectURL(file);
      element.download = `${clickedAppId}.txt`;
      document.body.appendChild(element);
      element.click();
    }
    if (type === "json") {
      const element = document.createElement("a");
      const file = new Blob([data], { type: "application/json" });
      element.href = URL.createObjectURL(file);
      element.download = "myFile.json";
      document.body.appendChild(element);
      element.click();
    }
  };

  // for uploading phrase data when clicked on upload button of settings of an application
  const uploadPhrase = (e) => {
    e.preventDefault();

    const data = new FormData();
    setIsDataFound(true);

    if (file.length > 0) {
      if (file.length > 1) {
        ErrorNotify("You can upload more than 1 file at a time");
        return;
      }
      Object.keys(file).map((i, j) => {
        let fileType = file[i].name.split(".").pop();
        // setIsDataFound(false);
        // if (fileType !== 'zip') {
        //     ErrorNotify(`'${fileType}' file type is not supported! Please select only zip file types.`);
        //     return;
        // }
        data.append("text", file[i]);
      });
    } else {
      setIsDataFound(false);
      ErrorNotify("Please select file");
      return;
    }

    var url = `${base_url}callspeech/upload-phrase`;

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: data,
    };

    callApi(url, requestOptions, "upload-phrase");
  };

  const columns = React.useMemo(() => dynamicHeadersArr, [dynamicHeadersArr]);

  const modifiedColumns = dynamicHeadersForAppTable.map((column) => {
    if (column.accessor === "call_id") {
      return {
        ...column,
        Cell: ({ row }) => (
          <Link
            to={`/smartspeech-call-details?callId=${row.original.call_id}&wavId=${row.original.call_id}`}
            target="_blank"
          >
            {row.original.call_id?.slice(0, 40)}
          </Link>
        ),
      };
    }
    return column;
  });

  const columns2 = React.useMemo(
    () => modifiedColumns,
    [dynamicHeadersForAppTable]
  );

  dataFilter = React.useMemo(() => dataArr, [dataArr]);
  console.log("data arr would be here-", dataArr);
  const defDrop = () => {
    var folderDrop = document.getElementById("folderDropdown");
    var appDrop = document.getElementById("applicationDropdown");

    folderDrop.selectedIndex = 0;
    appDrop.selectedIndex = 0;
  };

  const allFoldersOptions = allFoldersList?.map((item) => ({
    value: item?.folder_id,
    label: item?.folder_name,
  }));
  const allAppsOptions = appListData?.map((item) => ({
    value: item?.application_id,
    label: item?.application_name,
  }));

  // useEffect(() => {
  //     // Trigger onChange event on window load
  //     handleOnChange(allApplications[6]?.value, allApplications[6]?.label); // Pass default value
  //   }, [appListData]); // Empty dependency array to run this effect only once on mount

  useEffect(() => {
    if (allApplications.length > 0 && selectedValue === "") {
      // If options exist, set the default value to the first option's value
      setSelectedValue(allApplications[0]?.value);
      handleOnChange(allApplications[0]?.value, allApplications[0]?.label);
    }
  }, [appListData]);
  const handleOnChange = (value, label) => {
    setTableData([])
    setAppDataForTable([])
    setWeeklyFromDate("");
    setWeeklyToDate("");
    setSubCatData([]);
    getCategories(value, label);
    setToDateDisabled(true);
    setApplicationId(value);
    setAppName(label);
    setIsLoading(true);
    setSelectedValue(value);
    
  };

  var data2 = {
    type: "bar2d",
    renderAt: "chart-container",
    width: "100%",
    height: "320",
    dataFormat: "json",
    dataSource: {
      chart: {
        // caption: subCatHeader,
        subCaption: "",
        yAxisName: subCatHeader,
        numberPrefix: "",
        theme: "fusion",
        forceDecimal: 0,
        xAxisNameFontBold: 1,
        yAxisNameFontBold: 1,
        xAxisNameFontBold: 0,
        xAxisNameWidth: "1px",
        forceDecimals: "1",
        decimals: 0,
        yAxisValueDecimals: 2,
        forceYAxisValueDecimals: 1,
        showLimits: 1,
        // minLabelWidthPercent:1-100,
        maxLabelWidthPercent: 10,
        toolTipBgColor: "#000000",
        toolTipColor: "#ffffff",
        tooltipborderradius: 5,
      },

      data: subCatsData,
    },
    events: {
      dataplotClick: function (eventObj, dataObj) {
        console.log("eventObj", eventObj, dataObj);
        setClickedSubCat(subCatIDs[dataObj.dataIndex]);
        setIsLoading(true);
        setTimeout(() => {
          window.scroll({
            top: 4982,
            behavior: "smooth",
          });
        }, 0);
        // Add your function call here, using dataObj to access the clicked data
        // For example:
        // console.log("Bar clicked. Data: ", dataObj);
        // YourFunction(dataObj); // Call your function with the dataObj
      },
    },
  };

  return (
    <div class="ip-section mt-5">
      <ToastContainer />

      <div className="ip-header level-02" style={{ marginTop: "-60px" }}>
        <div className="container-fluid">
          <div className="iph-inner">
            <nav className="iph-nav">
              <ul className="d-flex flex-wrap">
                {localStorage.getItem("userRole") !== "Quality Supervisor" && (
                  <>
                    {/* <li className={`${overHighlight}`} >
                                                    <button className="btn-primary" style={{fontSize:"11px!important",padding:"7px",marginTop:"3px",width:"80px"}} onClick={(e) => [setDisplayCharts('overview'), setOverHighlight('active'), defDrop()]}>Overview</button>
                                                </li> */}
                    {/* <li>
                                                    <a className="btn" href="javascript:void(0)">Reports</a>
                                                </li> */}
                  </>
                )}

                {/* all folder listing here */}
                <div className="col-sm-4 col-md-4 col-lg-4 form-group">
                  {allFoldersList.map((i, j) => {
                    optionsFolderList.push({
                      value: i.folder_id,
                      label: i.folder_name,
                    });
                  })}
                  {/* <Select options={allApplications}
                                            placeholder="All Applications"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} 
                                                    onChange={(e) => [getCategories(e.value,
                                                      e.label), 
                                                      setToDateDisabled(true), setApplicationId(e.value),
                                                      setAppName(e.label),
                                                      setIsLoading(true)]}
                                                  /> */}

                  {/*                                             
                                             <Select options={allFoldersOptions}
                                             placeholder="All Folders"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            height:"40px",
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} 
                                                    onChange={(e) => [console.log("onChangeonChange",e),setFolderID(e.value),
                                                                    //   getFoldersApplications(e.value), 
                                                                      setIsLoading(true), 
                                                                      setFolderName(e.label),
                                                                      setAppName(e.label),
                                                                      setAppListData([])]
                                                              }
                                                    /> */}
                  {/* <select className="form-control" onChange={(e) => [getCategories(clickedAppId, appName, e.target.value), setFolderID(e.target.value), setFolderName(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)]}>
                                                <option value=''>Select</option>
                                                <option value='all' selected>All</option>
                                                {folderListData.map((i, j) => {
                                                    return (
                                                        <option value={i.folder_id}>{i.folder_name}</option>
                                                    )
                                                })}
                                            </select> */}
                </div>

                {/* all folder listing here */}

                {/* all application listing here */}
                <div className="col-sm-4 col-md-4 col-lg-4 form-group">
                  {/* <label className="form-label">Applications</label> */}

                  {console.log("allApplications", allApplications)}
                </div>
                {/* all application listing here */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="d-none" id="success_section">
        <div className="page_error success mb-12">
          <div className="alert alert-info text-center" id="success_msg"></div>
        </div>
      </div>
      <div className="d-none" id="failure_section">
        <div className="page_error failure mb-12">
          <div className="alert alert-info text-center" id="failure_msg"></div>
          <button className="remove_error_msg"></button>
        </div>
      </div>
      {localStorage.getItem("userRole") !== "Quality Supervisor" &&
        displayCharts === "overview" && (
          <div className="ip-section mt-2 mt-5">
            <div className="container-fluid">
              <div className="form-row">
                <div className="col-md-6">
                  <div
                    id="zoom_chart1"
                    className="chart-card chart_zoom_outer p-4 h-100"
                  >
                    <div className="chart-header d-flex justify-content-between">
                      <div
                        className="col-auto chart-header-left"
                        style={{
                          width: "calc(100% - 30px)",
                        }}
                      >
                        <div className="row" style={{ margin: "0 5px" }}>
                          {/* <div className="col-3" style={{ padding: '0 5px' }}>
                                                        <label className="" style={{ padding: '0 5px' }}><span>Overview</span></label>
                                                        <select className="form-control" onChange={(e) => [setOverviewText(e.target.value)]}>
                                                            <option value='call_volumn'>Call Volume</option>
                                                            <option value='call_duration'>Call Duration</option>
                                                            <option value='agent_emotion'>Agent Emotion</option>
                                                            <option value='client_emotion'>Client Emotion</option>
                                                        </select>
                                                    </div> */}
                          <div className="col-md-6">
                            <Select
                              options={allApplications}
                              placeholder="All Applications"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: "100px",
                                  boxShadow: "none", // Remove the blue box shadow
                                  borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                  "&:hover": {
                                    borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                  },
                                  "&:focus": {
                                    outline: "none", // Remove the blue outline on focus
                                    borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                    boxShadow: "none", // Remove the blue box shadow on focus
                                  },
                                  "&:active": {
                                    borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                    boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                  },
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  background: state.isSelected
                                    ? "#fff"
                                    : "transparent", // Remove background highlight from non-selected options
                                  color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                }),
                              }}
                              isSearchable={true}
                              required={true}
                              // onChange={(e) => [getCategories(e.value,
                              //   e.label),
                              //   setToDateDisabled(true), setApplicationId(e.value),
                              //   setAppName(e.label),
                              //   setIsLoading(true)]}
                              onChange={(selectedOption) =>
                                handleOnChange(
                                  selectedOption.value,
                                  selectedOption.label
                                )
                              }
                              value={allApplications.find(
                                (option) => option.value === selectedValue
                              )} // Set value based on selectedValue state
                            />
                          </div>
                          {/* <div className="col-3" style={{ padding: '0 5px' }}>
                                                        <label className="" style={{ padding: '0 5px' }}><span>From</span><span className="mandatory">*</span></label>
                                                        <input step={1} type="date" max={`${moment().format('YYYY-MM-DD')}T00:00`} className="form-control "
                                                            autoComplete="off" placeholder="Call Date & Time"
                                                            required value={from_date ? from_date : moment().startOf("month").format("YYYY-MM-DD")}
                                                            id="fromDateInput"
                                                            onChange={(e) => [setFromDate(e.target.value),
                                                            setToDateDisabled(false),
                                                            setLevelFromDate(e.target.value), handleFromDateChange(e)]} />
                                                    </div> */}
                          {/* <div className="col-3" style={{ padding: '0 5px' }}>
                                                        <label className="" style={{ padding: '0 5px' }}><span>To</span><span className="mandatory">*</span></label>
                                                        <input step={1} type="date" id="toDateInput"
                                                            disabled={toDateDisabled}
                                                            min={`${moment().format('YYYY-MM-DD')}T00:00`}
                                                            className="form-control " autoComplete="off"
                                                            placeholder="Call Date & Time" required value={to_date ? to_date : moment().format("YYYY-MM-DD")}
                                                            onChange={(e) => [setToDate(e.target.value),
                                                            setLevelToDate(e.target.value), handleToDateChange(e)]} />
                                                    </div>
                                                    <div className="col-3" style={{ padding: '0 5px' }}>
                                                        <button style={{ padding: '10px 17px', marginTop: '22px' }} onClick={() => [getOverviewDataOnLoad('clicked'), setIsLoading(true)]} type="button" className={`btn btn-primary alpha ml-auto`}>
                                                            <span>Submit</span>
                                                            <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                                        </button>
                                                    </div> */}
                        </div>
                      </div>
                      <div
                        className="col-auto chart-header-right"
                        style={{ width: "30px", padding: "0 5px" }}
                      >
                        <button className="chart_zoomin_btn">zoom</button>
                        <button className="chart_zoomout_btn">zoom</button>
                      </div>
                    </div>
                    <div className="chart_contents">
                      {overviewText === "call_volumn" && loadCallVolumnChart()}
                      {overviewText === "call_duration" &&
                        loadCallDuationChart()}
                      {overviewText === "agent_emotion" &&
                        loadAgentEmotionChart()}
                      {overviewText === "client_emotion" &&
                        loadClientEmotionChart()}
                    </div>
                    {/* <canvas className="chart_contents" id="ssc_01"></canvas> */}
                  </div>
                </div>
                <div
                  className={`${
                    overviewText === "call_volumn" ? "col-md-6" : "col-md-3"
                  }`}
                >
                  <div
                    id="zoom_chart2"
                    className="chart-card chart_zoom_outer p-4 h-100"
                  >
                    <div className="chart-header d-flex justify-content-between">
                      <h4>
                        {overviewText === "call_volumn"
                          ? "Call Statistics"
                          : overviewText === "call_duration"
                          ? "Call breakdown by duration and emotion"
                          : "Average Duration (min)"}
                      </h4>
                      <div>
                        <button className="chart_zoomin_btn">zoom</button>
                        <button className="chart_zoomout_btn">zoom</button>
                      </div>
                    </div>
                    {console.log("SSSSSSSSSSSSSS", callStaticsDataToday)}
                    {overviewText === "call_volumn" && (
                      <table className="table">
                        <thead>
                          <th></th>
                          <th>{todaydate}</th>
                          <th>Previous Day</th>
                          <th>7 day Avg.</th>
                          <th>Last 30 days avg.</th>
                        </thead>
                        {console.log("asxdcfvgb", columnsArr)}
                        <tbody>
                          {columnsArr.length > 0 &&
                            columnsArr?.map((i, j) => {
                              return i === "Positive client emotion" ||
                                i === "Positive agent emotion" ? (
                                <>
                                  <tr>
                                    <td>{i}</td>
                                    <td>
                                      {typeof callStaticsDataToday[
                                        "data_today"
                                      ][j]?.value === "string"
                                        ? callStaticsDataToday["data_today"][
                                            j
                                          ]?.value.search("%") !== -1
                                          ? callStaticsDataToday["data_today"][
                                              j
                                            ]?.value
                                          : callStaticsDataToday["data_today"][
                                              j
                                            ]?.value + "%"
                                        : // Handle other types if needed
                                          callStaticsDataToday["data_today"][j]
                                            ?.value + "%"}
                                    </td>
                                    <td>
                                      {/* {callStaticsDataToday['data_yesterday'][j]?.value?.search("%") ?callStaticsDataToday['data_yesterday'][j]?.value:callStaticsDataToday['data_yesterday'][j]?.value+"%"} */}
                                      {typeof callStaticsDataToday[
                                        "data_yesterday"
                                      ][j]?.value === "string"
                                        ? callStaticsDataToday[
                                            "data_yesterday"
                                          ][j]?.value.search("%") !== -1
                                          ? callStaticsDataToday[
                                              "data_yesterday"
                                            ][j]?.value
                                          : callStaticsDataToday[
                                              "data_yesterday"
                                            ][j]?.value + "%"
                                        : // Handle other types if needed
                                          callStaticsDataToday[
                                            "data_yesterday"
                                          ][j]?.value + "%"}
                                    </td>
                                    <td>
                                      {typeof callStaticsDataToday[
                                        "data_lastsevenday"
                                      ][j]?.value === "string"
                                        ? callStaticsDataToday[
                                            "data_lastsevenday"
                                          ][j]?.value.search("%") !== -1
                                          ? callStaticsDataToday[
                                              "data_lastsevenday"
                                            ][j]?.value
                                          : callStaticsDataToday[
                                              "data_lastsevenday"
                                            ][j]?.value + "%"
                                        : // Handle other types if needed
                                          callStaticsDataToday[
                                            "data_lastsevenday"
                                          ][j]?.value + "%"}
                                    </td>
                                    <td>
                                      {typeof callStaticsDataToday[
                                        "data_last30day"
                                      ][j]?.value === "string"
                                        ? callStaticsDataToday[
                                            "data_last30day"
                                          ][j]?.value.search("%") !== -1
                                          ? callStaticsDataToday[
                                              "data_last30day"
                                            ][j]?.value
                                          : callStaticsDataToday[
                                              "data_last30day"
                                            ][j]?.value + "%"
                                        : // Handle other types if needed
                                          callStaticsDataToday[
                                            "data_last30day"
                                          ][j]?.value + "%"}

                                      {/* {callStaticsDataToday['data_last30day'][j]?.value+"%"} */}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td>{i}</td>
                                    <td>
                                      {
                                        callStaticsDataToday["data_today"][j]
                                          ?.value
                                      }
                                    </td>
                                    <td>
                                      {
                                        callStaticsDataToday["data_yesterday"][
                                          j
                                        ]?.value
                                      }
                                    </td>
                                    <td>
                                      {
                                        callStaticsDataToday[
                                          "data_lastsevenday"
                                        ][j]?.value
                                      }
                                    </td>
                                    <td>
                                      {
                                        callStaticsDataToday["data_last30day"][
                                          j
                                        ]?.value
                                      }
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          {columnsArr.length == 0 &&
                            columnsArr1?.map((i, j) => {
                              return (
                                <>
                                  <tr>
                                    <td>{i}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                    {overviewText === "call_duration" && !isLoading && (
                      <>
                        {console.log("Data7Data7", Data7)}
                        {Data7?.labels?.length === 0 &&
                        Data7?.datasets?.length === 0 ? (
                          <div
                            className="spinner-border text-black"
                            style={{ marginLeft: "160px", marginTop: "120px" }}
                            role="status"
                          ></div>
                        ) : (
                          isCallDurationClicked && (
                            <Bar
                              height="425"
                              data={Data7}
                              options={options1}
                              width="600%"
                              onClick={onClickCDBars}
                              ref={chartRefCDBars}
                            ></Bar>
                          )
                        )}
                      </>
                    )}

                    {overviewText === "client_emotion" && !isLoading && (
                      <>
                        {Data8?.labels?.length === 0 &&
                        Data8?.datasets?.length === 0 ? (
                          <div
                            className="spinner-border text-black"
                            style={{ marginLeft: "160px", marginTop: "120px" }}
                            role="status"
                          ></div>
                        ) : (
                          isClientEmotionClicked && (
                            <Bar
                              data={Data8}
                              height="425"
                              options={options1}
                              width="600%"
                              onClick={onClickCEBars}
                              ref={chartRefCEBars}
                            ></Bar>
                          )
                        )}
                      </>
                    )}

                    {overviewText === "agent_emotion" && !isLoading && (
                      <>
                        {console.log("OOOOOOOOOOOO", Data9)}
                        {Data9?.labels?.length === 0 &&
                        Data9?.datasets?.length === 0 ? (
                          <div
                            className="spinner-border text-black"
                            style={{ marginLeft: "160px", marginTop: "120px" }}
                            role="status"
                          ></div>
                        ) : (
                          isAgentEmotionClicked && (
                            <Bar
                              data={Data9}
                              height="425"
                              options={options1}
                              width="600%"
                              onClick={onClickAgentBars}
                              ref={chartRefAgentBars}
                            ></Bar>
                          )
                        )}
                      </>
                    )}

                    {/* <canvas className="chart_contents" id="ssc_02"></canvas> */}
                  </div>
                </div>
                {overviewText === "call_duration" && (
                  <div className="col-md-3">
                    <div
                      id="zoom_chart3"
                      className="chart-card chart_zoom_outer p-4 h-100"
                    >
                      <div className="chart-header d-flex justify-content-between">
                        <h4>Call breakdown by duration</h4>
                        <div>
                          <button className="chart_zoomin_btn">zoom</button>
                          <button className="chart_zoomout_btn">zoom</button>
                        </div>
                      </div>
                      {data12?.labels?.length === 0 &&
                      data12?.datasets?.length === 0 ? (
                        <div
                          className="spinner-border text-black"
                          style={{ marginLeft: "160px", marginTop: "120px" }}
                          role="status"
                        ></div>
                      ) : (
                        isCallDurationClicked && (
                          <Doughnut
                            data={data12}
                            options={options13}
                            style={{ margin: "auto" }}
                          />
                        )
                      )}
                      {/* {isClientEmotionClicked && !isLoading && <Doughnut data={data12} options={options13} style={{ margin: 'auto' }} />} */}
                    </div>
                  </div>
                )}
                {overviewText === "agent_emotion" && (
                  <div className="col-md-3">
                    <div
                      id="zoom_chart3"
                      className="chart-card chart_zoom_outer p-4 h-100"
                    >
                      <div className="chart-header d-flex justify-content-between">
                        <h4>Agent Emotion</h4>
                        <div>
                          <button className="chart_zoomin_btn">zoom</button>
                          <button className="chart_zoomout_btn">zoom</button>
                        </div>
                      </div>
                      {data13?.labels?.length === 0 &&
                      data13?.datasets?.length === 0 ? (
                        <div
                          className="spinner-border text-black"
                          style={{ marginLeft: "160px", marginTop: "120px" }}
                          role="status"
                        ></div>
                      ) : (
                        isAgentEmotionClicked && (
                          <Doughnut
                            data={data13}
                            options={options13}
                            style={{ margin: "auto" }}
                          />
                        )
                      )}
                      {/* {isClientEmotionClicked && !isLoading && <Doughnut data={data12} options={options13} style={{ margin: 'auto' }} />} */}
                    </div>
                  </div>
                )}
                {overviewText === "client_emotion" && (
                  <div className="col-md-3">
                    <div
                      id="zoom_chart3"
                      className="chart-card chart_zoom_outer p-4 h-100"
                    >
                      <div className="chart-header d-flex justify-content-between">
                        <h4>Client Emotion</h4>
                        <div>
                          <button className="chart_zoomin_btn">zoom</button>
                          <button className="chart_zoomout_btn">zoom</button>
                        </div>
                      </div>
                      {data14?.labels?.length === 0 &&
                      data14?.datasets?.length === 0 ? (
                        <div
                          className="spinner-border text-black"
                          style={{ marginLeft: "160px", marginTop: "120px" }}
                          role="status"
                        ></div>
                      ) : (
                        isClientEmotionClicked && (
                          <Doughnut
                            data={data14}
                            options={options13}
                            style={{ margin: "auto" }}
                          />
                        )
                      )}
                      {/* {isClientEmotionClicked && !isLoading && <Doughnut data={data12} options={options13} style={{ margin: 'auto' }} />} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

      {/* application charts */}
      {/* {displayCharts === 'application' && loadApplicationsCharts()} */}
      {console.log("RRRRRRRRRRRRRRRR",displayCharts,displayCharts==="smartspeech-app-dashboard",displayCharts==="smartspeech-dashboard-new")}
      {
        
        
      displayCharts ===  getUrl&&
      // displayCharts === "application" && 
      
      (
        <div className="ip-section mt-2 mt-5">
          <div className="container-fluid">
            <div className="form-row">
              <div className="col-md-6">
                <div
                  id="zoom_chart1"
                  className="chart-card chart_zoom_outer p-4"
                >
                  <div className="chart-header d-flex justify-content-between">
                    <div
                      className="col-auto chart-header-left"
                      style={{
                        width: "calc(100% - 30px)",
                        height: "102px",
                      }}
                    >
                      {/* <div className="row" style={{ margin: '0 5px' }}>
                                                        <div className="col-3" style={{ padding: '0 5px' }}>
                                                            <label className="" style={{ padding: '0 5px' }}><span>Applications</span></label>
                                                            
                                                        </div>
                                                        <div className="col-3" style={{ padding: '0 5px' }}>
                                                            <label className="" style={{ padding: '0 5px' }}><span>From</span><span className="mandatory">*</span></label>
                                                            <input step={1} type="date"
                                                                max={`${moment().format('YYYY-MM-DD')}T00:00`}
                                                                className="form-control "
                                                                autoComplete="off"
                                                                id="fromDateInput"
                                                                placeholder="Call Date & Time"
                                                                required value={from_date}
                                                                onChange={(e) => [setFromDate(e.target.value), setToDateDisabled(false), setLevelFromDate(e.target.value), handleFromDateChange(e)]} />
                                                        </div>
                                                        <div className="col-3" style={{ padding: '0 5px' }}>
                                                            <label className="" style={{ padding: '0 5px' }}><span>To</span><span className="mandatory">*</span></label>
                                                            <input step={1} type="date" min={`${moment().format('YYYY-MM-DD')}T00:00`} className="form-control"
                                                                disabled={toDateDisabled}
                                                                id="toDateInput" autoComplete="off" placeholder="Call Date & Time" required value={to_date} onChange={(e) => [setToDate(e.target.value), setLevelToDate(e.target.value), handleToDateChange(e)]} />
                                                        </div>
                                                        <div className="col-3" style={{ padding: '0 5px' }}>
                                                            <button style={{ padding: '10px 17px', marginTop: '22px' }}
                                                                onClick={() => [getOverviewDataOnLoad('clicked'), setIsLoading(true)]} type="button" className={`btn btn-primary alpha ml-auto`}>
                                                                <span>Submit</span>
                                                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                                            </button>
                                                        </div>
                                                    </div> */}
                      {console.log("allApplications", allApplications)}

                      {console.log("filteredFolders123", filteredFolders)}

                      <div className="row">
                        <div className="col-md-6">
                          <Select
                            options={filteredFolders}
                            placeholder="All Folders"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "#fff"
                                  : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              }),
                            }}
                            isSearchable={true}
                            required={true}
                            // onChange={(e) => [getCategories(e.value,
                            //   e.label),
                            //   setToDateDisabled(true), setApplicationId(e.value),
                            //   setAppName(e.label),
                            //   setIsLoading(true)]}
                            // onChange={(selectedOption) =>
                            //     handleOnChange(selectedOption.value, selectedOption.label)
                            // }
                            //value={filteredFolders.find((option) => option.value === selectedValueFolder)} // Set value based on selectedValue state
                            value={selectedValueFolder !== '' ? filteredFolders?.find((option, index) => option.value === selectedValueFolder) : ''}
                                                        
                            onChange={(e) => [
                              console.log("onChangeonChange", e),
                              setFolderID(e.value),
                              getFoldersApplications(e.value),
                              setIsLoading(true),
                              setFolderName(e.label),
                              setAppName(e.label),
                              setAppListData([]),
                              setSelectedValueFolder(e.value),
                              setFilterReset(false),
                              setTableData([]),
                              setAppDataForTable([]),
                              
                            ]}
                          />
                        </div>

                        <div className="col-md-6">
                          <Select
                            options={allApplications}
                            placeholder="All Applications"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "#fff"
                                  : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              }),
                            }}
                            isSearchable={true}
                            required={true}
                            // onChange={(e) => [getCategories(e.value,
                            //   e.label),
                            //   setToDateDisabled(true), setApplicationId(e.value),
                            //   setAppName(e.label),
                            //   setIsLoading(true)]}
                            onChange={(selectedOption) =>
                              
                              handleOnChange(
                                selectedOption.value,
                                selectedOption.label
                              )
                            }
                            // value={allApplications.find(
                            //   (option) => option.value === selectedValue
                            // )} // Set value based on selectedValue state
                            value={selectedValue !== '' ? allApplications?.find((option, index) => option.value === selectedValue) : ''}
                            
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-auto chart-header-right"
                      style={{ width: "30px", padding: "0 5px" }}
                    >
                      <button className="chart_zoomin_btn">zoom</button>
                      <button className="chart_zoomout_btn">zoom</button>
                    </div>
                  </div>
                  {console.log("isRenderData", appText, appName)}
                  <div className="chart_contents">
                    {/* {console.log('apptext, appname', appText, '----', appName, '---', appText === appName, '----', !isLoading)} */}
                    {console.log("HEYEYEYEYEYE", isRenderData)}
                    {console.log(
                      "NNNNNNNNNNNN",
                      appText,
                      "||||",
                      appName,
                      ">>>>>>>>",
                      appText === appName
                    )}
                    {isRenderData && appText === appName ? (
                      <Bar
                        data={Data10}
                        options={options2}
                        width="600%"
                        onClick={onClickApp}
                        ref={chartRefApp}
                      ></Bar>
                    ) : (
                      !isRenderData &&
                      appText === appName && (
                        <div
                          className="spinner-border text-black"
                          style={{ marginLeft: "330px", marginTop: "80px" }}
                          role="status"
                        ></div>
                      )
                    )}
                    {/* {appText === appName && <Line data={Data10} options={options} width="600%" onClick={onClick} ref={chartRef} ></Line>} */}
                  </div>
                  {/* <canvas className="chart_contents" id="ssc_01"></canvas> */}
                </div>
              </div>
              <div className="col-md-6">
                <div
                  id="zoom_chart2"
                  className="chart-card chart_zoom_outer p-4"
                >
                  <div className="chart-header d-flex justify-content-between">
                    <h4>
                      {isAppCatClicked
                        ? `${subCatHeader}`
                        : "Application Sub Categories"}
                    </h4>
                    <div>
                      <button className="chart_zoomin_btn">zoom</button>
                      <button className="chart_zoomout_btn">zoom</button>
                    </div>
                  </div>
                  {console.log("Data11", Data11)}
                  {isAppCatClicked ? (
                    <div style={{ height: "304px" }}>
                      {Data11?.labels?.length === 0 &&
                      Data11?.datasets?.length === 0 ? (
                        loadingLoader&&
                        <div
                          className="spinner-border text-black"
                          style={{ marginLeft: "160px", marginTop: "120px" }}
                          role="status"
                        ></div>
                      ) : (
                        // <Chart data={Data11} options={options11} width="600%" onClick={onClickSubCat} ref={chartRefSubCat} />
                        <ReactFC {...data2} />
                      )}
                    </div>
                  ) : (
                    <div className="mt-div" style={{ height: "304px" }}>
                      {loadingLoader ? (
                        <div
                          className="spinner-border text-black"
                          style={{ marginLeft: "160px", marginTop: "120px" }}
                          role="status"
                        ></div>
                      ) : (
                        <Chart data={Data11} options={options11} width="600%" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* </div> */}
      {
                  folderID!=="" && clickedAppId!=="" &&
      <div className="ip-section mt-5">
        <div className="container-fluid">
          <div className="ip-form p-0">
            <div className="form-contents" style={{display:getUrl==="application"?'block':'none'}}>
            

              <div className="form-row" >
                {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                            <label><span>Level 1</span><span className="mandatory">*</span></label>
                                            <select className="form-control" onChange={(e) => [getSubCategories(e.target.value, 'dropdown'), setCatName(e.target.value)]}>
                                                <option>Select</option>
                                                {catListData.map((i, j) => {
                                                    return (
                                                        <option value={i.category_id}>{i.category_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div> */}

                {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                            <label><span>Level 2</span><span className="mandatory">*</span></label>
                                            <select className="form-control" onChange={(e) => setSubCatName(e.target.value)}>
                                                <option>Select</option>
                                                {subCatListDDData.map((i, j) => {
                                                    return (
                                                        <option value={i.subcategory_id}>{i.sub_category_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div> */}

                {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                            <label><span>Data</span><span className="mandatory">*</span></label>
                                            <select className="form-control custom-select">
                                                <option>Hit/Miss Score</option>
                                                <option>Avg. Call Duration</option>
                                                <option>Avg. Call Silence</option>
                                                <option>Coverage Score</option>
                                            </select>
                                        </div> */}

                <div className="col-sm-6 col-md-4 col-lg-2 form-group">
                  <label>
                    <span>From</span>
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    step={1}
                    type="date"
                    max={`${moment().format("YYYY-MM-DD")}T00:00`}
                    className="form-control "
                    autoComplete="off"
                    placeholder="Call Date & Time"
                    required
                    value={fromDateForApp}
                    onChange={(e) => [
                      setLevelFromDate(e.target.value),
                      setFromDate(e.target.value),
                      setFromDateForApp(e.target.value),
                    ]}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2 form-group">
                  <label>
                    <span>To</span>
                    <span className="mandatory">*</span>
                  </label>
                  <input
                    step={1}
                    type="date"
                    min={`${moment().format("YYYY-MM-DD")}T00:00`}
                    className="form-control "
                    autoComplete="off"
                    placeholder="Call Date & Time"
                    required
                    value={toDateForApp}
                    onChange={(e) => [
                      setLevelToDate(e.target.value),
                      setToDate(e.target.value),
                      setToDateForApp(e.target.value),
                    ]}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2 form-group">
                  <label>
                    <span>Views</span>
                    <span className="mandatory">*</span>
                    {/* <button type="button" className="btn-auto">View</button> */}
                  </label>
                  {/* <select className="form-control" onChange={(e) => [setAgentsValue(e.target.value), handleSummaryReportData(e.target.value)]}> */}
                  {displayCharts !== "application" && (
                    <select
                      className="form-control"
                      onChange={(e) => [setAgentsValue(e.target.value)]}
                    >
                      <option
                        value="Summary"
                        selected={agentsValue === "Summary"}
                      >
                        Summary
                      </option>
                      <option value="Files" selected={agentsValue === "Files"}>
                        Files
                      </option>
                    </select>
                  )}
                  {displayCharts === "application" && (
                    <select
                      className="form-control"
                      onChange={(e) => [setAgentsValue(e.target.value)]}
                    >
                      <option>Select</option>
                      {/* <option value="Agent">Agents</option>
                      <option value="Summary">Summary</option> */}
                      <option value="Files">Files</option>
                    </select>
                  )}
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    onClick={() => [callFileApiFunc(), setIsLoading(true)]}
                    className={`btn btn-primary ml-auto mt-2`}
                  >
                    <span>Submit</span>
                    <span class="loader">
                      <span
                        class="spinner-border"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      
      </div>
}

      <div className="ip-section mt-3">
        {/* {agentsValue === 'Files' && loadDT()} */}
        {/* {(displayCharts === 'application' && localStorage.getItem('userRole') !== 'Quality Supervisor') &&
                            <div className="ip-section">
                                <div className="container-fluid">
                                    <div className="ip-table p-0 data_list_div">
                                        <div className="table-header ">
                                            <div className="d-flex align-items-center">
                                                <div className="d-inline-flex align-items-center mr-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-circle-primary"
                                                        data-toggle="modal"
                                                        data-target="#application-setting"
                                                    >
                                                        <i className="las la-cog"></i>{" "}

                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn btn-primary mx-2"
                                                        data-toggle="modal"
                                                        data-target="#export-calls"
                                                    >
                                                        Export <i className="fa fa-download"></i>{" "}

                                                    </button>

                                                </div>
                                                </div>
                                                </div>
                                                </div></div></div>
                                            } */}
        {console.log("filterReset===", loaderSubCat)}
        {!loaderSubCat && appDataForTable?.length===0 ? (
          <DataTableView
            isAppFilter={false}
            searchValue={searchValue}
            setColumnDetails={setColumnDetails}
            isPending={isLoading}
            columnDetails={columnDetails}
            allData={subCatData}
            setSearchValue={setSearchValue}
            isCSVReport={true}
            isPDFReport={true}
            isXLSReport={true}
            csvReport={csvReport}
            pdfReport={pdfReport}
            xlsReport={xlsReport}
            isQAAutoFilter={false}
            isSetting={true}
            displayCharts={displayCharts}
            onlyForForms="d-none"
            columns={columns}
            data={tableData}
            loading={false}
            totalRec={tableData.length}
            quickSearch={true}
            evaluatorSearch={false}
          />
          
        ) : (
          <div>
            {appDataForTable?.length > 0 || appDataForTable?.length === 0? (
              <DataTableView
                data={appDataForTable}
                searchValue={searchValue}
                isCSVReport={true}
                csvReport={csvReport}
                columns={columns2}
                setSearchValue={setSearchValue}
                totalRec={appDataForTable?.length}
                isPending={loaderSubCat}
                
              />
            ) : (
              ""
              // <div
              //   className="spinner-border text-black "
              //   role="status"
              //   style={{ marginLeft: "730px", marginTop: "50px" }}
              // ></div>
            )}

            {/* <table className="table">
                                        <thead style={{ backgroundColor: "#0D9DD9", color: 'white' }}>
                                            <tr>
                                                <th scope="col">File Name</th>
                                                <th scope="col">Agent ID</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Silence Time</th>
                                                <th scope="col">Overtalk</th>
                                                <th scope="col">Diarization</th>
                                                <th scope="col">Agent Clarity</th>
                                                <th scope="col">Agent Gender</th>
                                                <th scope="col">Client Gender</th>
                                                <th scope="col">Overall Emotion</th>
                                                <th scope="col">Agent Emotion</th>
                                                <th scope="col">Client Emotion</th>
                                                <th scope="col">Overall Emotion</th>
                                                <th scope="col">Transcript ID</th>
                                                <th scope="col">Request ID</th>
                                                <th scope="col">Score</th>
                            
                                            </tr>
                                           
                                            
                                        </thead>
                                        <tbody>
                                            {
                                                
                                             appDataForTable?.length>0 ?appDataForTable?.map(i=>{
                                                 return <tr>
                                                <td title={`${i?.wav}`}><Link to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i?.call_id}`} target="_blank">{i.wav?.slice(0,30)}</Link></td>
                                                <td>{i?.agentid}</td>
                                                <td>{i?.duration}</td>
                                                <td>{i?.silence_percentage}</td>
                                                <td>{i?.overtalk}</td>
                                                <td>NA</td>
                                                <td>NA</td>
                                                <td>{i?.agent_gender}</td>
                                                <td>{i?.client_gender}</td>
                                                <td>{i?.overall_emotion}</td>
                                                <td>{i?.agent_emotion}</td>
                                                <td>{i?.client_emotion}</td>
                                                <td>{i?.overall_emotion}</td>
                                                <td>{i?.tran_id}</td>
                                                <td>NA</td>
                                                <td>NA</td>
                                            </tr>
                                             }) 
                                             :
                                             <div className="spinner-border text-black " role="status" style={{marginLeft:"730px",marginTop:"50px"}}></div>
                                            
                                             
}
                            
                                        </tbody>
                                    </table> */}

            {/* {!loaderSubCat && (
              <div
                className="spinner-border text-black "
                role="status"
                style={{ marginLeft: "730px", marginTop: "50px" }}
              ></div>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard6;
