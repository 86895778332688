import React, { useEffect, useState } from "react";
import MultiSelection from "../controls/MultiSelect";
import Select from "react-select";
import { components } from "react-select";
import { ErrorNotify, SuccessNotify } from "../shared/Toast";
import { useNavigate} from 'react-router-dom';
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var globalManager2Value = '';
var globalManager1Value = '';
var globalAgentValue = '';

const CustomLevel = (props) => {
    const navigate = useNavigate();
    const [hierarchyData, setHierarchyData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [statusVlaue, setStatusValue] = useState([]);
    const [countLob, setCountLob] = useState(0);
    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState([]);
    const [defaultValueC2, setdefaultValueC2] = useState([]);
    const [defaultValueC3, setdefaultValueC3] = useState([]);
    const [defaultValueC4, setdefaultValueC4] = useState([]);

    const [fromDate, setFormDate] = useState();
    const [toDate, setToDate] = useState('');
    const [dateType, setDateType] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [c1, setC1] = useState('');
    const [c2, setC2] = useState('');
    const [c3, setC3] = useState('');
    const [c4, setC4] = useState('');
    const [form, setForm] = useState([]);
    const [manager2, setManager2] = useState([]);
    const [manager1, setManager1] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [agent, setAgent] = useState([]);
    const [callId, setCallId] = useState('');
    const [agentId, setAgent_id] = useState('');


    const [manager2Val, setManager2Val] = useState('');
    const [manager1Val, setManager1Val] = useState('');
    const [supervisorVal, setSupervisorVal] = useState('');
    const [agentVal, setAgentVal] = useState('');
    const [formData2, setFormData2] = useState("");
    const [latestForm, setLatestForm] = useState();

    console.log("agent",agent)

    useEffect(() => {
        console.log("JJJJJJJJJJJJJ",!Object.values(props?.formValVersion).includes(undefined));
        if(!Object.values(props?.formValVersion).includes(undefined)){
            console.log("ffffffffffffff",props?.formValVersion);
            let dFormDisName = formData2&&formData2?.filter(i=>props?.formValVersion?.form_name===i?.form_name)[0]?.display_name
            let dFormName = formData2&&formData2?.filter(i=>props?.formValVersion?.form_name===i?.form_name)[0]?.form_name
            let dFormVersion = formData2&&formData2?.filter(i=>props?.formValVersion?.form_name===i?.form_name)[0]?.form_version
            console.log("dFormDisName",formData2);
            setLatestForm({ label: dFormDisName,value:`${dFormName}||${dFormVersion}`})
        }
        else{
            console.log("OOOOOOOOOO");
            let dFormDisName = formData2&&formData2[0]?.display_name
            let dFormName = formData2&&formData2[0]?.form_name
            let dFormVersion = formData2&&formData2[0]?.form_version
            setLatestForm({ label: dFormDisName,value:`${dFormName}||${dFormVersion}`})
        }

    }, [formData, formData2])

    useEffect(() => {
        // setFormData([...new Set(props?.callListData?.map(i => i))])
        // console.log("myformdata",formData)
        setStatusData([...new Set(props?.callListData?.map(i => i?.evaluation_status))]);
        setSupervisor(props?.callListData);
        console.log("superviserCAllList", props?.callListData)



        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        // var url = `${base_url}calls/getallcalls`;
        // callApi(url, requestOptions, 'call-data');

        var url = `${base_url}hierarchy/get-custom-hierarchy`;
        callApi(url, requestOptions, 'hierarchy-data');

        var url = `${base_url}filter/custom1`;
        callApi(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props?.fromDate,
                to: props?.toDate,
                datetype: props?.dateType?.value,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        }, 'custom-1');

        if (props?.manager2?.length) {
            var url = `${base_url}filter/manager1`;
            callApi(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    from: props?.fromDate,
                    to: props?.toDate,
                    custom1: defaultValueC1,
                    custom2: defaultValueC2,
                    custom3: defaultValueC3,
                    custom4: defaultValueC4,
                    manager2: props?.manager2
                })
            }, 'manager1');
        }

    }, [props?.fromDate, props?.toDate, props?.dateType?.value, props?.unAssignedCallsData, props?.callListData]);

    const handleStatusChanged = (e) => {
        setStatusValue(e)
    }

    // useEffect(() => {

    //     const requestOptions = {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //         },
    //     };

    //     if (props.manager2.length) {
    //         var url = `${base_url}filter/manager1`;
    //         callApi(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //             },
    //             body: JSON.stringify({
    //                 form: props.fromDate,
    //                 to: props.toDate,
    //                 custom1: defaultValueC1,
    //                 custom2: defaultValueC2,
    //                 custom3: defaultValueC3,
    //                 custom4: defaultValueC4,
    //                 manager2: props.manager2
    //             })
    //         }, 'manager1');
    //     }

    //     var url = `${base_url}filter/custom1`;
    //     callApi(url, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //         },
    //         body: JSON.stringify({
    //             form: props.fromDate,
    //             to: props.toDate,
    //             datetype: props.dateType.value
    //         })
    //     }, 'custom-1');

    // }, [props.fromDate, props.toDate, props.dateType.value])

    const callApi = (url, requestOptions, purpose) => {

        fetch(url, requestOptions).then(res => res.json()).then(data => {

            if (data.status != 200) {
                // alert(data.message);
                return;
            }

            // if (purpose === 'call-data') {
            //     setCallListData(data.data);
            // }


            if(purpose==="form-data-on-load"){

                if (data?.status == 200 || data?.status == 201) {
                    setFormData2(data?.data)
                    props?.setDefaultFormData({ form_name: `${data?.data[0]?.form_name}`,form_version:`${data?.data[0]?.form_version}`})
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }

            }
            if (purpose === 'hierarchy-data') {

                var arr = [];

                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                });

                setHierarchyData(arr);

            }
            if (purpose === 'custom-1') {
                // setIsC1Data(true);
                setC1Data(data.data);
                // setIsSupDisabled(false);                
            }

            if (purpose === 'formData') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    // console.log('formdata-==============', data)
                    props.getC1Value(defaultValueC1);
                    props.getC2Value(defaultValueC2);
                    props.getC3Value(defaultValueC3);
                    // props.getC4Value(defaultValueC4);
                    props.getFormData(data.data);
                    setFormData(data.data);
                    props?.setDefaultFormVal && props?.setDefaultFormVal(data.data)
                    // setFormData([...new Set(data.data?.map(i => i.display_name))])
                    console.log("FormData======", data.data)

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'agent-for-supervisor') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('manager2 value----------------', data)

                    props.getC1Value(defaultValueC1);
                    props.getC2Value(defaultValueC2);
                    props.getC3Value(defaultValueC3);
                    props.getC4Value(defaultValueC4);
                    // props.getManager2(data.data);
                    // setManager2(data.data);
                    setAgent(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'manager2') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('manager2 value----------------', data)

                    props.getC1Value(defaultValueC1);
                    props.getC2Value(defaultValueC2);
                    props.getC3Value(defaultValueC3);
                    // props.getC4Value(defaultValueC4);
                    // props.getManager2(data.data);
                    setManager2(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'manager1') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {

                    props.getC1Value(defaultValueC1);
                    props.getC2Value(defaultValueC2);
                    props.getC3Value(defaultValueC3);
                    props.getC4Value(defaultValueC4);
                    // props.getManager1(data.data);
                    setManager1(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'supervisor') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('supervisor--------', data)

                    props.getC1Value(defaultValueC1);
                    props.getC2Value(defaultValueC2);
                    props.getC3Value(defaultValueC3);
                    props.getC4Value(defaultValueC4);
                    props.getSupervisor(data.data);
                    setSupervisor(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'agent') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('agent value-------', data)
                    props.getC1Value(defaultValueC1);
                    props.getC2Value(defaultValueC2);
                    props.getC3Value(defaultValueC3);
                    // props.getC4Value(defaultValueC4);
                    // setAgent(data.data);
                    let agentData = []
                    data?.data?.map(item=>{
                        const values = {
                            'label':item.agent_name,
                            'value':item.agent_id
                        }
                        agentData.push(values);
                    })
                    setAgent(agentData)
                    console.log("DATATATAT", data?.data);
                    const uniqueObjects = [];
                    const uniqueKeys = new Set();

                    data?.data?.forEach((item) => {
                        const key = `${item.agent_id}-${item.agent_name}`;
                        if (!uniqueKeys.has(key)) {
                            uniqueKeys.add(key);
                            uniqueObjects.push(item);
                        }
                    });
                    props?.setAgent && props?.setAgent(uniqueObjects);

                }).catch(err => {
                    console.log(err);
                });
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
        });

    }

    let optionsC1 = c1Data.map((item) => ({
        value: item,
        label: item
    }));

    let defaultOptionsC1 = localStorage.getItem("c1Data")?.split(",").map((item) => ({
        value: item,
        label: item
    }));

    console.log("defaultOptionsC1", defaultOptionsC1);



    console.log("optionsC1", c1Data);

    const handleC2Change = (e) => {
        // handleC3Change(e, "lobFunc)
        // optionsC1 = [];
        // optionsC1 = c1Data.map((item) => ({
        //     value: item,
        //     label: `${e?.length} item selected`
        // }));

        // let opt = optionsC1.find((option, index) => option.value === e?.map(i=>i))
        // console.log("RRRRRRRRRR",optionsC1);
        setdefaultValueC1('');
        setdefaultValueC2('');
        setdefaultValueC3('');
        setdefaultValueC4('');
        setStatusValue('');
        setManager2Val('')
        setManager1Val('')
        setSupervisorVal('')
        setAgentVal('')
        setForm('')
        setC2Data([])
        setC3Data([])
        setC4Data([])
        setManager1([])
        setManager2([])
        setAgent([])
        setSupervisor([])
        props?.setAgentid()
        props.getC1Value(defaultValueC1);
        props.getC2Value('');
        props.getC3Value('');
        props.getC4Value('');
        props.getManager1('');
        props.getManager2('');
        props.getSupervisor('');
        props.getAgent('');
        props.setAgentid()
        props?.setSupervisorId()
        setCountLob(e.length);


        var url = `${base_url}filter/custom2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.dateType.value,
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c2 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC1(e);
            props.getC1Value(e);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }
    const [selectedValue, setSelectedValue] = useState([])
    const handleC3Change = (e, whichFunc) => {

        setdefaultValueC2('');
        setdefaultValueC3('');
        setdefaultValueC4('');
        setStatusValue('');
        setManager2Val('')
        setManager1Val('')
        setSupervisorVal('')
        setAgentVal('')
        setForm('')
        setC3Data([])
        setC4Data([])
        setManager1([])
        setManager2([])
        setAgent([])
        setSupervisor([])
        props?.setAgentid()
        props.getC2Value('');
        props.getC3Value('');
        props.getC4Value('');
        props.getManager1('');
        props.getManager2('');
        props.getSupervisor('');
        props.getAgent('');
        props.setAgentid()
        props?.setSupervisorId()
        //console.log("EEEEEEEEEEEEEEE",e);
        // if(whichFunc==="lobFunc" && e[0]==="ELQ"){
        //     console.log("this is from lobFunc");
        // }
        if (whichFunc === "campaignFunc") {
            setSelectedValue(e)
            console.log("this is from campaignFunc", selectedValue);
        }
        var url = `${base_url}filter/custom3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.dateType.value,
                custom1: defaultValueC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC2(e);
            props.getC2Value(e);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC4Change = (e) => {

        setdefaultValueC3('');
        setdefaultValueC4('');
        setStatusValue('');
        setManager2Val('')
        setManager1Val('')
        setSupervisorVal('')
        setAgentVal('')
        setForm('')
        setC4Data([])
        setManager1([])
        setManager2([])
        setAgent([])
        setSupervisor([])
        props.getManager1('');
        props.getManager2('');
        props.getSupervisor('');
        props.getAgent('');

        props.getC3Value('');
        props.getC4Value('');
        props?.setAgentid()
        props?.setSupervisorId()
        var url = `${base_url}filter/custom4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.dateType.value,
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                return;
            }
            setdefaultValueC3(e);
            props.getC3Value(e);
            setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        var url = `${base_url}filter/forms`;
        const userHierarchy = JSON.parse(localStorage.getItem("usersHierarchy"))
        console.log("userHierarchy",userHierarchy);
        const removeValue = 'add_new';

for (const key in userHierarchy) {
    if (Array.isArray(userHierarchy[key])) {
        userHierarchy[key] = userHierarchy[key].filter(item => {
            if (typeof item === 'object' && item.value === removeValue && item.label === 'Add New') {
                return false;
            }
            return true;
        });
    }
}
        callApi(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.dateType.value,
                custom1: userHierarchy?.c1,
                custom2: userHierarchy?.c2,
                custom3: userHierarchy?.c3,
                custom4: userHierarchy?.c4?.filter(i => typeof (i) === "string"),
                myEvaluation: props?.myEvaluation

            })
        }, 'formData');
    }, [])

    const handleC5Change = (e) => {

        setdefaultValueC4('');
        setStatusValue('');
        setManager2Val('')
        setManager1Val('')
        setSupervisorVal('')
        setAgentVal('')
        setForm('')
        setManager1([])
        setManager2([])
        setAgent([])
        setSupervisor([])
        props.getManager1('');
        props.getManager2('');
        props.getSupervisor('');
        props.getAgent('');
        props?.setAgentid()
        props?.setSupervisorId()
        setdefaultValueC4(e);
        props.getC4Value(e);

        var url = `${base_url}filter/forms`;
        callApi(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: props.fromDate,
                to: props.toDate,
                datetype: props.dateType.value,
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: defaultValueC3,
                custom4: e,

            })
        }, 'formData');

        if ((localStorage.getItem('loginUserRole') === 'OM' || localStorage.getItem('loginUserRole') === 'OS')) {
            var url = `${base_url}filter/agent-for-supervisor`;
            callApi(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    from: props.fromDate,
                    to: props.toDate,
                    datetype: props.dateType.value,
                    custom1: defaultValueC1,
                    custom2: defaultValueC2,
                    custom3: defaultValueC3,
                    custom4: e,
                })
            }, 'agent');
        } else {
            var url = `${base_url}filter/manager2`;
            callApi(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    from: props.fromDate,
                    to: props.toDate,
                    datetype: props.dateType.value,
                    custom1: defaultValueC1,
                    custom2: defaultValueC2,
                    custom3: defaultValueC3,
                    custom4: e,
                })
            }, 'manager2');
        }
        // var url = `${base_url}filter/manager1`;
        // callApi(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         form: props.fromDate,
        //         to: props.toDate,
        //         custom1: JSON.stringify([defaultValueC1]),
        //         custom2: JSON.stringify([defaultValueC2]),
        //         custom3: JSON.stringify([defaultValueC3]),
        //         custom4: JSON.stringify([defaultValueC4]),
        //         manager2: JSON.stringify(props.manager2)
        //     })
        // }, 'manager1');

        // var url = `${base_url}filter/supervisor`;
        // callApi(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         form: props.fromDate,
        //         to: props.toDate,
        //         custom1: JSON.stringify([defaultValueC1]),
        //         custom2: JSON.stringify([defaultValueC2]),
        //         custom3: JSON.stringify([defaultValueC3]),
        //         custom4: JSON.stringify([e]),
        //         manager1: JSON.stringify([props.manager1])
        //     })
        // }, 'supervisor');

        // var url = `${base_url}filter/agent`;
        // callApi(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         form: props.fromDate,
        //         to: props.toDate,
        //         supervisor: JSON.stringify([props.supervisor])
        //     })
        // }, 'agent');

    }

    const handleFormChanged = (val) => {

        console.log('handleFormChanged---------', val)
        props.getFormData(val);
        props?.setFormValVersion({form_name:val?.value?.split("||")[0],form_version:val?.value?.split("||")[1]})
        setForm(val);
        setLatestForm(val)
        props?.setAgentid()
    }

    const handleManager2Changed = (val) => {
        setManager2Val('')
        setManager1Val('')
        setSupervisorVal('')
        setAgentVal('')
        setManager1([])
        setAgent([])
        setSupervisor([])
        props.getManager1('');
        props.getManager2('');
        props.getSupervisor('');
        props.getAgent('');
        console.log('manager2 val------', val)
        globalManager2Value = val;
        setManager2Val(val);
        props.getManager2(val);
        props?.setSupervisorId()
        props?.setAgentid()
        getAllValueFunc('manager1');
    }

    const handleManager1Changed = (val) => {
        setManager1Val('')
        setSupervisorVal('')
        setAgentVal('')
        setAgent([])
        setSupervisor([])
        props.getManager1('');
        props.getSupervisor('');
        props.getAgent('');
        props?.setAgentid()
        props?.setSupervisorId()
        globalManager1Value = val;
        setManager1Val(val);
        props.getManager1(val);
        getAllValueFunc('supervisor');
    }

    const handleSupChanged = (val) => {
        setSupervisorVal('')
        props?.setAgentid()
        setAgentVal('')
        setAgent([])
        props.getSupervisor('');
        props.getAgent('');
        globalAgentValue = val;
        setSupervisorVal(val);
        getAllValueFunc('agent');
        props?.setSupervisorId && props?.setSupervisorId(val)
    }

    const handleAgentChanged = (val) => {

        setAgentVal(val)
        props.getAgent(val);
        console.log("valval", val);
        //props?.setAgent&&props?.setAgent(val)
        props?.setAgentid(val)
    }


    useEffect(() => {
        var url = `${base_url}forms/get-forms`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        callApi(url, requestOptions, 'form-data-on-load');

    }, [])

    const handleFilter = (e) => {

        e.preventDefault();

        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: true,
                from_date: fromDate,
                to_date: toDate,
                date_type: dateType,
                affiliation: affiliation,
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: defaultValueC1,
                form: form,
                manager2: manager2Val,
                manager1: manager1,
                supervisor: supervisor,
                agent: props?.agent && props?.agent,
                call_id: '',
                agent_id: '',
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                 proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('filter data======', data);
            // if (data.status != 200) {
            //     alert(data.message);
            //     return;
            // }
            // setdefaultValueC3(e);
            // setFormData(prevState => [
            //     ...prevState,
            //     data.data
            // ]);
            // setdefaultValueC4(e);
            // console.log('-------------', data.data)
        }).catch(err => {
            console.log(err);
        });
    }

    const getAllValueFunc = (val) => {
        switch (val) {
            case 'manager1':
                // console.log('manager2Val---------', manager2Val)
                var url = `${base_url}filter/manager1`;
                callApi(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        from: props.fromDate,
                        to: props.toDate,
                        dateType: props.dateType.value,
                        custom1: defaultValueC1,
                        custom2: defaultValueC2,
                        custom3: defaultValueC3,
                        custom4: defaultValueC4,
                        manager2: globalManager2Value
                    })
                }, 'manager1');
                break;

            case 'supervisor':
                var url = `${base_url}filter/supervisor`;
                callApi(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        from: props.fromDate,
                        to: props.toDate,
                        datetype: props.dateType.value,
                        custom1: defaultValueC1,
                        custom2: defaultValueC2,
                        custom3: defaultValueC3,
                        custom4: defaultValueC4,
                        manager1: globalManager1Value
                    })
                }, 'supervisor');

                break;

            case 'agent':
                var url = `${base_url}filter/agent`;
                callApi(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        from: props.fromDate,
                        to: props.toDate,
                        datetype: props.dateType.value,
                        supervisor: globalAgentValue
                    })
                }, 'agent');

                break;

            default:
                break;
        }


    }
    const ValueContainer = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && defaultValueC1.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${defaultValueC1.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const ValueContainer2 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && defaultValueC2.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${defaultValueC2.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const ValueContainer3 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && defaultValueC3.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${defaultValueC3.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const ValueContainer4 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && defaultValueC4.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${defaultValueC4.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const ValueContainer5 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && statusVlaue.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${statusVlaue.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    const ValueContainerform = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && form.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${form.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    const ValueContainer6 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && manager2Val.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${manager2Val.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    const ValueContainer7 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && manager1Val.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${manager1Val.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    const ValueContainer8 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && supervisorVal.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${supervisorVal.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const ValueContainer9 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (selectedOption > 0 && agentVal.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    {`${agentVal.length} items selected`}
                </components.ValueContainer>
            );
        }

        else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };
    console.log("formDataformData", formData);

    const allForms = formData?.map((item) => ({
        label: item?.display_name,
        value: `${item?.form_name}||${item?.form_version}`
    }));
    const optionsManager2 = manager2?.map((item) => ({
        value: item?.userId,
        label: item?.name
    }));



    const optionsC2 = c2Data?.map((item) => ({
        value: item,
        label: item
    }));

    const optionsC3 = c3Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC4 = c4Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsFormData = formData?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsManager1 = manager1?.map((item) => ({
        value: item?.userId,
        label: item?.name
    }));
    const optionsStatus = [


        { label: "Pending", value: "Pending" },

        { label: "In-Progress", value: "In-Progress" },

        { label: "Aborted", value: "Aborted" },

        { label: "Completed", value: "Completed" }


    ];

    const optionsSuperviser = supervisor?.map((item) => ({
        value: item?.userId,
        label: item?.name
    }));
    // const optionsSuperviser = {
    //     value: 'Agent',
    //     label: 'Agent'
    // };
    const optionsAgent = props?.agent && props?.agent.map((item) => ({
        value: item,
        label: item
    }));

    function countCommonValues(arr1, arr2) {
        const commonCount = arr1.reduce((count, item) => {
            if (arr2.includes(item)) {
                return count + 1;
            }
            return count;
        }, 0);

        return commonCount;
    }

    function countMatchesWithDefaultValue(options, defaultValue) {
        let count = 0;

        const matchingOption = options.find((option) => option.value === defaultValue);

        if (matchingOption) {
            count++;
        }

        return count;
    }

    const Option = (props) => {

        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    return (
        <>
            <div className="form-group">
                <label><span>{hierarchyData.c1}</span></label>
                {console.log("c1Datac1Data",)}
                {/* <MultiSelection options={c1Data?.length>0?c1Data:JSON.parse(localStorage.getItem("usersHierarchy")).c1} customSelectionName={hierarchyData.c1} isFilter={true} isFormFilter={false} handleC2Change={handleC2Change} /> */}
                <Select
                    options={optionsC1?.length > 0 ? optionsC1 : defaultOptionsC1}
                    // defaultValue={[{label:"item selected",value:"item selected"}]}
                    value={defaultValueC1 !== '' ? optionsC1?.find((option, index) => option.value === defaultValueC1) : ''}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderRadius: '100px', // Adjust the border-radius value as per your needs
                        }),
                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                    }}
                    components={{
                        Option,
                        ValueContainer: ValueContainer
                    }}
                    isMulti
                    placeholder="Select..."
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={true}
                    allowSelectAll={true}
                    //value={formData}
                    onChange={(e) => handleC2Change(e?.map(i => i?.value))}
                />

            </div>

            <div className="form-group">
                <label><span>{hierarchyData.c2}</span></label>
                {/* <MultiSelection options={c2Data} customSelectionName={hierarchyData.c2} isFilter={true} isFormFilter={false} value={selectedValue} handleC3Change={(e) => handleC3Change(e, "campaignFunc")} /> */}

                <Select
                    options={optionsC2}
                    value={defaultValueC2 !== '' ? optionsC2?.find((option) => option.value === defaultValueC2) : ''}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderRadius: '100px', // Adjust the border-radius value as per your needs
                        }),
                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                    }}
                    components={{
                        Option,
                        ValueContainer: ValueContainer2
                    }}
                    isMulti
                    placeholder="Select..."
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={true}
                    allowSelectAll={true}
                    //value={formData}
                    onChange={(e) => handleC3Change(e?.map(i => i?.value))}
                />
            </div>

            <div className="form-group">
                <label><span>{hierarchyData.c3}</span></label>
                {/* <MultiSelection options={c3Data} customSelectionName={hierarchyData.c3} isFilter={true} isFormFilter={false} handleC4Change={handleC4Change} /> */}
                <Select
                    options={optionsC3}
                    value={defaultValueC3 !== '' ? optionsC3?.find((option, index) => option.value === defaultValueC3) : ''}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderRadius: '100px', // Adjust the border-radius value as per your needs
                        }),
                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                    }}
                    components={{
                        Option,
                        ValueContainer: ValueContainer3
                    }}
                    isMulti
                    placeholder="Select..."
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={true}
                    allowSelectAll={true}
                    //value={formData}
                    onChange={(e) => handleC4Change(e?.map(i => i?.value))}
                />

            </div>
            <div className="form-group">
                <label><span>{hierarchyData.c4}</span></label>
                {/* <span className="mandatory">*</span> */}
                {/* <MultiSelection options={c4Data} customSelectionName={hierarchyData.c4} isFilter={true} isFormFilter={false} handleC5Change={handleC5Change} /> */}
                <Select
                    options={optionsC4}
                    value={defaultValueC4 !== '' ? optionsC4?.find((option, index) => option.value === defaultValueC4) : ''}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderRadius: '100px', // Adjust the border-radius value as per your needs
                        }),
                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                    }}
                    components={{
                        Option,
                        ValueContainer: ValueContainer4
                    }}
                    isMulti
                    placeholder="Select..."
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={true}
                    allowSelectAll={true}
                    //value={formData}
                    onChange={(e) => handleC5Change(e?.map(i => i?.value))}
                />
            </div>

            {
                !props?.otherFilter && !props?.dashboardFilter &&

                <div className="form-group">
                    <label><span>Form</span></label>
                    {/* <MultiSelection options={formData} customSelectionName={'formData'} isFilter={true} isFormFilter={true} handleFormChanged={handleFormChanged} /> */}
                    <Select
                        options={allForms}
                        value={form !== '' ? allForms?.find((option, index) => option?.value === form) : ''}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                        }}
                        components={{
                            Option,
                            ValueContainer: ValueContainerform
                        }}
                        isMulti
                        placeholder="Select..."
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        allowSelectAll={true}

                        onChange={(e) => handleFormChanged(e?.map(i => i?.value))}
                    />
                </div>
            }
            {
                props?.statusShow &&

                <div className="form-group">
                    <label><span>Status</span></label>
                    {/* <MultiSelection options={statusData} customSelectionName={'statusData'} isFilter={true} isFormFilter={true} handleFormChanged={handleStatusChanged} /> */}
                    <Select
                        options={optionsStatus}
                        value={statusVlaue !== '' ? optionsStatus?.find((option, index) => option?.value === statusVlaue) : ''}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                        }}
                        components={{
                            Option,
                            ValueContainer: ValueContainer5
                        }}
                        isMulti
                        placeholder="Select..."
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        allowSelectAll={true}
                        //value={formData}
                        onChange={(e) => handleStatusChanged(e?.map(i => i?.value))}
                    />
                </div>
            }
            {!(localStorage.getItem('loginUserRole') === 'OM' || localStorage.getItem('loginUserRole') === 'OS') ?
                (
                    <>
                        <div className="form-group d-none">
                            <label className="form-label">Manager 3</label>
                            <select className="form-control ">
                                <option value="">Select</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Manager 2</label>
                            {/* <MultiSelection options={manager2} customSelectionName={'manager2'} isFilter={true} isFormFilter={false} isManager2Filter={true} handleManager2Changed={handleManager2Changed} /> */}
                            <Select
                                options={optionsManager2}
                                value={manager2Val !== '' ? optionsManager2?.find((option, index) => option.value === manager2Val) : ''}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px', // Adjust the border-radius value as per your needs
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                }}
                                components={{
                                    Option,
                                    ValueContainer: ValueContainer6
                                }}
                                isMulti
                                placeholder="Select..."
                                isSearchable={true}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isClearable={true}
                                allowSelectAll={true}
                                //value={formData}
                                onChange={(e) => handleManager2Changed(e?.map(i => i?.value))}
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Manager 1</label>
                            {/* <MultiSelection options={manager1} customSelectionName={'manager1'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={true} handleManager1Changed={handleManager1Changed} /> */}
                            <Select
                                options={optionsManager1}
                                value={manager1Val !== '' ? optionsManager1?.find((option, index) => option.value === manager1Val) : ''}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px', // Adjust the border-radius value as per your needs
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                }}
                                components={{
                                    Option,
                                    ValueContainer: ValueContainer7
                                }}
                                isMulti
                                placeholder="Select..."
                                isSearchable={true}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isClearable={true}
                                allowSelectAll={true}
                                //value={formData}
                                onChange={(e) => handleManager1Changed(e?.map(i => i?.value))}
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Supervisor</label>
                            {/* <MultiSelection options={supervisor} customSelectionName={'supervisor'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={false} isSupervisorFilter={true} handleSupChanged={handleSupChanged} /> */}
                            <Select
                                options={optionsSuperviser}
                                value={supervisorVal !== '' ? supervisor?.find((option, index) => option.value === supervisorVal) : ''}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px', // Adjust the border-radius value as per your needs
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                }}
                                components={{
                                    Option,
                                    ValueContainer: ValueContainer8

                                }}
                                isMulti
                                placeholder="Select..."
                                isSearchable={true}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isClearable={true}
                                allowSelectAll={true}
                                //value={formData}
                                onChange={(e) => handleSupChanged(e?.map(i => i?.value))}
                            />
                        </div>
                    </>
                ) : ""}
            <div className="form-group">
                <label className="form-label">Agent</label>
                {/* <MultiSelection options={props?.agent&& props?.agent} customSelectionName={'agent'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={false} isSupervisorFilter={false} isAgentFilter={true} handleAgentChanged={handleAgentChanged} /> */}
                <Select
                    options={agent}
                    value={agentVal !== '' ? agent?.find((option, index) => option.value === agentVal) : ''}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderRadius: '100px', // Adjust the border-radius value as per your needs
                        }),
                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                    }}
                    components={{
                        Option,
                        ValueContainer: ValueContainer9
                    }}
                    isMulti
                    placeholder="Select..."
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={true}
                    allowSelectAll={true}
                    //value={formData}
                    onChange={(e) => handleAgentChanged(e?.map(i => i?.value))}
                />
            </div>
            {
                props?.dashboardFilter &&
                <>
                    <div className="form-group">
                        <label><span>Form</span></label>
                        {/* <MultiSelection options={formData} customSelectionName={'formData'}  isFilter={true} isFormFilter={true} handleFormChanged={handleFormChanged} /> */}
                        <Select
                            options={allForms}

                            value={latestForm}
                            

                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: '100px', // Adjust the border-radius value as per your needs
                                }),
                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '250px', // Adjust the maximum height as needed
                                    
                                }),

                            }}
                            placeholder="Select..."
                            isSearchable={true}
                            //value={formData}
                            onChange={handleFormChanged}
                        />
                    </div>

                    <div className="form-group" style={{ marginTop: "25px" }} >
                        <input type="checkbox" value={props?.myEvaluation} onChange={(e) => props?.setMyEvaluation && props?.setMyEvaluation(e.target.checked)} style={{ marginTop: "5px" }} className="mx-2" /><span>Include My Evaluations</span>
                    </div>
                </>
            }


        </>
    )
}

export default CustomLevel;