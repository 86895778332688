import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import Select from 'react-select';
import ModalShow from '../../../controls/Modal'
import { ErrorNotify, SuccessNotify, WarningNotify  } from "../../../shared/Toast";
import "./SSDashboard.css";
import DataTableView from "../../../shared/DataTable";
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_name = process.env.PUBLIC_URL;
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};
const UniversalFormTable = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [universalCallList, setUniversalCallList] = useState([]);
  const [allClasses, setAllClasses] = useState('col-sm-6 col-md-4 col-lg-6 form-group d-none')
  const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
  const [evaluationStatus, setEvaluationStatus] = useState('Pending');
  const [evaluatorName, setEvaluatorName] = useState('');
  const [isDataFound, setIsDataFound] = useState(false)

  const [showEvaluateBtn, setShowEvaluateBtn] = useState(true);
  const [isLoadingEvaluate, setIsLoadingEvaluate] = useState(false)

  const [assignValue, setAssignValue] = useState('');
  const [hierarchyData, setHierarchyData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [c1Data, setC1Data] = useState([]);
  const [c2Data, setC2Data] = useState([]);
  const [c3Data, setC3Data] = useState([]);
  const [c4Data, setC4Data] = useState([]);
  const [conversationId, setConversationId] = useState("");
  const [popupLoader, setPopupLoader] = useState(true);
  const [callInfo, setCallInfo] = useState([]);
  const [addCallData, setAddCallData] = useState([]);
  const [addCallModal,setAddCallModal] = useState(false)
  const [agentSupervisors, setAgentSupervisors] = useState('');
    const [storeAllAgents, setStoreAllAgents] = useState([]);
    const [storeAllTags, setStoreAllTags] = useState([]);
    const [appScoreData, setAppScoreData] = useState([]);
    const [callDateTime, setCallDateTime] = useState('');
    const [callId, setcallId] = useState('');
    const [agentName, setAgentname] = useState('');
    const [callDuration, setCallDuration] = useState('');
    const [formName, setFormName] = useState('');
  const [defaultValueC1, setdefaultValueC1] = useState('');
  const [defaultValueC2, setdefaultValueC2] = useState('');
  const [defaultValueC3, setdefaultValueC3] = useState('');
  const [defaultValueC4, setdefaultValueC4] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  const search = useLocation().search;

  const fromDate = new URLSearchParams(search).get("fromDate");

  const toDate = new URLSearchParams(search).get("toDate");

  const dateType = new URLSearchParams(search).get("date_type");

  const uniFormListColumns = React.useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action",
      },
      {
        Header: "Call Id",
        accessor: "call_id",
      },
      {
        Header: "Call Date & Time",
        accessor: "call_date",
      },
      {
        Header: "Evaluation Date",
        accessor: "evaluation_date",
      },
      {
        Header: "Call Duration",
        accessor: "call_duration",
      },
      {
        Header: "Earned Points",
        accessor: "earnedPoints",
      },
      {
        Header: "Possible Points",
        accessor: "possiblePoints",
      },
      {
        Header: "Pre AF Score",
        accessor: "preAfScore",
      },
      {
        Header: "Total Score",
        accessor: "totalScore",
      },
    ],
    []
  );
  const universalFormHeaders = [
    { label: "Call Id", key: "call_id" },
    // { label: "Application Id", key: "application_id" },
    { label: "Call Date & Time", key: "call_date" },
    { label: "Evaluation Date", key: "evaluation_date" },
    { label: "Call Duration", key: "call_duration" },
    { label: "Earned Points", key: "earnedPoints" },
    { label: "Possible Points", key: "possiblePoints" },
    { label: "Pre AF Score", key: "preAfScore" },
    { label: "Total Score", key: "totalScore" },
  ];

  var csvReport = {
    data: universalCallList,
    headers: universalFormHeaders,
    filename: "Universal_form_call_list.csv",
  };
  useEffect(() => {
    var url = `${base_url}universalform/universal-form-list`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        date_type: dateType,
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("datacall List", data);
        if (data?.status == 200 || data?.status == 201) {
          let uniFormCallList = [];
          data?.data?.map((item) => {
            const values = {
              call_id: item?.call?.call_id,
              call_register_status: item?.call_register_status,
              call_date: item?.call?.call_date,
              evaluation_date: item?.evaluation_date,
              call_duration: item?.call?.call_duration,
              earnedPoints: item?.earnedPoints,
              possiblePoints: item?.possiblePoints,
              preAfScore: item?.preAfScore,
              totalScore: item?.totalScore,
              created_at: item?.created_at,
              updated_at: item?.updated_at,
              form_name : item?.form_name,
              form_version:item?.form_version
            };
            uniFormCallList.push(values);
          });
          console.log("uniFormCallList", uniFormCallList);
          setUniversalCallList(uniFormCallList);
          setIsLoading(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setIsLoading(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setIsLoading(false);
          return;
        }
      })
      .catch((err) => {
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
      });
  }, []);

  useEffect(() => {
    getCustomHierarchyData();
}, []);

const handleAddEvaluation = (isEva = 0) => {

  const data = new FormData();

  if (defaultValueC1 === '' || defaultValueC1.length === 0 || defaultValueC1 === 'Select') {
      ErrorNotify(`Please select ${hierarchyData.c1} value`);
      return;
  }
  if (defaultValueC2 === '' || defaultValueC2.length === 0 || defaultValueC2 === 'Select') {
      ErrorNotify(`Please select ${hierarchyData.c2} value`);
      return;
  }
  if (defaultValueC3 === '' || defaultValueC3.length === 0 || defaultValueC3 === 'Select') {
      ErrorNotify(`Please select ${hierarchyData.c3} value`);
      return;
  }
  if (defaultValueC4 === '' || defaultValueC4.length === 0 || defaultValueC4 === 'Select') {
      ErrorNotify(`Please select ${hierarchyData.c4} value`);
      return;
  }
  if (formName === '') {
      ErrorNotify('Please select Form Name');
      return;
  }
  if (assignValue === '') {
      ErrorNotify('Please select Assign to ');
      return;
  }
  if (evaluationStatus === '') {
      ErrorNotify('Please select Evaluation status');
      return;
  }

  // if (mediaValue === '') {
  //     ErrorNotify('Please select mediaValue ');
  //     return;
  // }

  // data.append('file', file);
  if (!callInfo?.agent_id) {
      return ErrorNotify("Agent id is required")
  }
  data.append('agent', callInfo?.agent_id ? callInfo?.agent_id : '');
  console.log("formnameformnameAgeb=nt", agentName)
  data.append('c1', defaultValueC1);
  data.append('c2', defaultValueC2);
  data.append('c3', defaultValueC3);
  data.append('c4', defaultValueC4);
  data.append('userId', localStorage.getItem('loginUserId'));
  data.append('call_id', callId ? callId : '');

  data.append('call_date', callDateTime ? callDateTime : '');
  console.log("formnameformname", callDateTime)
  data.append('call_duration', callDuration ? callDuration : '');
  console.log("formnameformname", formName)
  data.append('form_name', String(formName).split('||')[0]);
  data.append('form_version', String(formName).split('||')[1]);
  data.append('channel', 'channel');
  data.append('affiliation', 'mattsenkumar');
  data.append('evaluation_status', evaluationStatus);
  data.append('evaluator', evaluatorName);
  data.append("proxy_user_id", localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "");
  data.append("proxy_by", localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : "");

  // data.append('media', mediaValue);
  var url = `${base_url}calls/add`;

  const requestOptions = {
      method: 'POST',
      headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },

      body: data

  };
  // console.log('evaluation data is here=========', requestOptions)
  // return
  fetch(url, requestOptions).then(res => res.json()).then(data => {
      console.log('evaluation data is======', data);

      if (data.status === 401) {
          ErrorNotify(data.message);
          setIsDataFound(false);
          // handleClose();
          return;
      }
      if (data.status === 422) {
          ErrorNotify(data.message);
          setIsDataFound(false);
          // handleClose();
          return;
      }
      if (data.status === 200) {
          console.log("evalutehandling")
          if (isEva) {
              setIsDataFound(false);
              handleClose();
              console.log("evalutehandling2222")
              navigate(`/new-form-preview?formName=${data.data.form_name}&formVersion=${data.data.form_version}&moduleName=evaluation&authSecondId&resolvedSecId&call_id=${data.data.call.call_id}`)
          } else {
              setIsDataFound(true);
              console.log("evalutehandling333")
              SuccessNotify(data.message);

              setTimeout(() => {
                  setIsDataFound(false);
                  handleClose();

                  window.location.reload(false);

                  return;
              }, 3000);
          }
      }
  }).catch(err => {
      console.log(err);
  });
}

const getOtherEvaluators = () => {
  var url = `${base_url}calls/get-other-evaluators`;

  const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
          userId: localStorage.getItem('loginUserId'),
          proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
          proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

      })
  };

  fetch(url, requestOptions).then(res => res.json()).then(data => {
      console.log('setOtherEvaluatorsData data======', data);
      if (data.status != 200) {
          SuccessNotify(data.message);
          return;
      }
      setOtherEvaluatorsData(data.data);
      // setTimeout(() => {
      //     console.log('========', otherEvaluatorsData)
      // }, 1200);
  }).catch(err => {
      console.log(err);
  });
}
const hideEvaluateBtn = (e) => {

  console.log("erererere", e)
  setAssignValue(e);

  if (e === 'none' || e === 'other') {
      setShowEvaluateBtn(false);
      setAllClasses('col-sm-6 col-md-4 col-lg-6 form-group d-none');
  } else {
      setShowEvaluateBtn(true);
      setAllClasses('col-sm-6 col-md-4 col-lg-6 form-group d-none');
  }
  if (e === 'other') {
      setAllClasses('col-sm-6 col-md-4 col-lg-6 form-group');
      getOtherEvaluators();
  }

  if (e === 'none') {
      setEvaluationStatus('Unassigned');
      setEvaluatorName('');
  } else if (e === 'myself' || e === 'other') {
      setEvaluationStatus('Pending');
  }
  if (e === 'myself') {
      setEvaluatorName(localStorage.getItem('loginUserUniqueid'));
  }
}

const handleClose = () => {

  setAddCallModal(false)
  setdefaultValueC1('');
  setdefaultValueC2('');
  setdefaultValueC3('');
  setdefaultValueC4('');
  setFormName('')
  setAssignValue('')
  setAgentname('')
  setcallId('')
  setCallDateTime('')
  setCallDuration('')
  setEvaluatorName('')
  setEvaluationStatus('')
  setAgentSupervisors('')

  // setTimeout(() => {
  //     window.location.reload();
  // }, 3000);

}
const getCustomHierarchyData = async () => {
  var url = `${base_url}hierarchy/get-custom-hierarchy`;

  var requestOptions = {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
  };

  await fetch(url, requestOptions).then(res => res.json()).then(data => {
      console.log('hierarchy data======', data);
      if (isNaN(data.status)) {
          // Logout();
          return
      }
      var arr = [];
      if (data?.status == 200) {
          data?.data?.map((i, j) => {
              arr[i.custom1_id] = i.custom1_name;
              arr[i.custom2_id] = i.custom2_name;
              arr[i.custom3_id] = i.custom3_name;
              arr[i.custom4_id] = i.custom4_name;
          })
          setHierarchyData(arr);

      }
      else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
              localStorage.clear()
          }
          return ErrorNotify(data.message, { icon: "error", });
      }


  }).catch(err => {
      return ErrorNotify("Something went wrong.Please contact to Administrator !", {
          icon: "error",
      });
  });

  var requestOptions = {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
  };

  var url = `${base_url}custom/1`;
  await fetch(url, requestOptions).then(res => res.json()).then(data => {
      setC1Data(data.data);
  }).catch(err => {
      console.log(err);
  });
}

const onAddCallClose = () => {
  setAddCallModal(false)
  setdefaultValueC1('');
  setdefaultValueC2('');
  setdefaultValueC3('');
  setdefaultValueC4('');
  setC2Data([])
  setC3Data([])
  setC4Data([])
  setFormName('')
  setFormData([])
  setOtherEvaluatorsData([])
}
const optionsC1 = c1Data?.map((item) => ({
  value: item,
  label: item
}));
const optionsC2 = c2Data?.map((item) => ({
  value: item,
  label: item
}));
const optionsC3 = c3Data?.map((item) => ({
  value: item,
  label: item
}));

const optionsC4 = c4Data?.map((item) => ({
  value: item,
  label: item
}));

const optionsFormData = formData?.map((i) => ({
  value: `${i.form_name}||${i.form_version}`,
  label: `${i.display_name}(V${i.form_version}.0)`
}));
const optionAssign = [
  { value: '', label: 'Select' },
  { value: 'none', label: 'None' },
  { value: 'myself', label: 'Self' },
  { value: 'other', label: 'Other' }
]
const optionsotherEvaluatorsData = otherEvaluatorsData.map((i) => ({
  value: `${i.agent_id}`,
  label: `${i.agent_name_id}`
}));
async function getDetailsForCalls(id) {
  // setAddNamePhrase("")
  // setIsLoading(true)
  // setIsLoading2(true)

  // if (type === "clickOnCall") {
  //     setClickOnCallLoader(true)
  // }
  // if (type === "clickOnAddCall") {

  //     setPopupLoader(true)
  // }
  setPopupLoader(true)

  // const callDetails = await fetch(`${base_url}callspeech/calldetailss/${id}`, {
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
  //     },
  // });


  await fetch(`${base_url}callspeech/${window.location.host?.split(".")[0] === "recruitment" ? "calldetails-rec" : "calldetails"}/${id}`, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },

  }).then(res => res.json()).then(data => {
      if (data?.status == 200) {
        if(data?.data?.length > 0){
          setAddCallModal(true)
          // if (type === "clickOnCall") {
              // setIsActive(false)
              // const overviewTabLink = document.getElementById("overview-tab");
              // overviewTabLink.click();
              // const fileViewLink = document.getElementById("file-tab");
              // fileViewLink.click();
              setCallInfo(data?.data[0])
              setPopupLoader(false)
              setAppScoreData(data?.data[0]?.tag)
        }
        else{
         WarningNotify(data?.message)
        }
          // }

          // if (type === "clickOnAddCall") {
          //     console.log("222222222222222", data?.data);
          //     setIsActive(false)
          //     const overviewTabLink = document.getElementById("overview-tab");
          //     overviewTabLink.click();
          //     const fileViewLink = document.getElementById("file-tab");
          //     fileViewLink.click();
          //     setAddCallData(data?.data)
          //     setPopupLoader(false)
          //     // setCallInfo(callDetailsData?.data)
          // }
          setAgentSupervisors(data?.data[0]?.supervisorname);
          setCallDuration(data?.data[0]?.duration)
          setAgentname(data?.data[0]?.agent_name)
          setCallDateTime(moment(data?.data[0]?.datetime).format("YYYY-MM-DD HH:mm:ss"))
          setcallId(data?.data[0]?.call_id)

      }
      else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
              localStorage.clear()
          }
          setPopupLoader(false)
      }

  }).catch(err => {
      setPopupLoader(false)
      return ErrorNotify("Something went wrong.Please contact to Administrator !", {
          icon: "error",
      });
  })

  // const callDetailsData = await callDetails.json();
  // console.log("callDetails",callDetails);


  // if (type === "clickOnCall") {
  //     setIsActive(false)
  //     const overviewTabLink = document.getElementById("overview-tab");
  //     overviewTabLink.click();
  //     const fileViewLink = document.getElementById("file-tab");
  //     fileViewLink.click();
  //     setCallInfo(callDetailsData?.data)
  //     setClickOnCallLoader(false)
  // }

  // if (type === "clickOnAddCall") {
  //     console.log("222222222222222");
  //     setIsActive(false)
  //     const overviewTabLink = document.getElementById("overview-tab");
  //     overviewTabLink.click();
  //     const fileViewLink = document.getElementById("file-tab");
  //     fileViewLink.click();
  //     setAddCallData(callDetailsData?.data)
  //     setPopupLoader(false)
  //     // setCallInfo(callDetailsData?.data)
  // }


  // { getAgentSupervisors(callDetailsData?.data[0]?.agentid) }
  // setTimeout(() => {
  //     {callIdFunc(callInfo[0]?.call_id)}

  //     {callDateFunc(moment(callInfo[0]?.datetime).format("YYYY-MM-DD HH:mm:ss"))}
  //     {agentNameFunc(callInfo[0]?.agentid)}
  //     {calldurationFunc(callInfo[0]?.duration)}
  // }, 1000);




  // setPopupLoader(false)
  // setWavFileInfo(wavFileDetailsData?.data)
  // setTagsInfo(tagsDetailsData?.data)
  // setIsLoading(false)
  // setIsLoading2(false)
}
const showEvaluateForm = () => {
  setIsLoadingEvaluate(true);
  // Simulate a loading process
  setTimeout(() => {
      // Your loading logic here
      setIsLoadingEvaluate(false); // Once loading is done
  }, 3000); // Simulate a 2-second loading time

  handleAddEvaluation(1);

}
const handleC2Change = (e) => {
  setdefaultValueC1('');
  setdefaultValueC2('');
  setdefaultValueC3('');
  setdefaultValueC4('');
  // setFormName('')
  // setMediaValue("")
  // setFile({})
  // setAssignValue('')

  setdefaultValueC1(e.value);

  var url = `${base_url}custom/2`;
  const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
          custom1: e.value,
      })
  };

  fetch(url, requestOptions).then(res => res.json()).then(data => {
      console.log('c2 data======', data);
      if (data.status != 200) {
          return;
      }
      { console.log("c1Datac1Data", defaultValueC1) }
      // props.getC1Value(e);
      setC2Data(data.data);
  }).catch(err => {
      console.log(err);
  });
}

const handleC3Change = (e) => {

  setdefaultValueC2('');
  setdefaultValueC3('');
  setdefaultValueC4('');
  // setFormName('')
  // setMediaValue("")
  // setFile({})
  // setAssignValue('')
  setdefaultValueC2(e.value);


  var url = `${base_url}custom/3`;
  const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
          custom1: defaultValueC1,
          custom2: e.value
      })
  };

  fetch(url, requestOptions).then(res => res.json()).then(data => {
      // console.log('c3 data======', data);
      if (data.status != 200) {
          return;
      }
      // props.getC2Value(e);
      setC3Data(data.data);
  }).catch(err => {
      console.log(err);
  });
}

const handleC4Change = (e) => {

  setdefaultValueC3('');
  setdefaultValueC4('');
  // setFormName('')
  // setMediaValue("")
  // setFile({})
  // setAssignValue('')

  setdefaultValueC3(e.value);

  var url = `${base_url}custom/4`;
  const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
          custom1: defaultValueC1,
          custom2: defaultValueC2,
          custom3: e.value,
      })
  };

  fetch(url, requestOptions).then(res => res.json()).then(data => {
      // console.log('c3 data======', data);
      if (data.status != 200) {
          return;
      }
      // props.getC3Value(e);
      setC4Data(data.data);
  }).catch(err => {
      console.log(err);
  });
}
const handleC5Change = (e) => {
  setFormName('')
  setdefaultValueC4(e.value);

  if (e !== '') {
      var url = `${base_url}calls/get-form-name`;
      const requestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
          },
          body: JSON.stringify({
              c1: defaultValueC1,
              c2: defaultValueC2,
              c3: defaultValueC3,
              c4: e.value,
              proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
              proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

          })
      };

      fetch(url, requestOptions).then(res => res.json()).then(data => {
          console.log('form data======', data);
          // if (data.status != 200) {
          //     ErrorNotify(data.message);
          //     return;
          // }
          // setdefaultValueC3(e);
          // setFormData(prevState => [
          //     ...prevState,
          //     data.data
          // ]);
          setFormData(data.data)
          // console.log('-------------', data.data)
      }).catch(err => {
          console.log(err);
      });
  }
}

  const data = React.useMemo(
    () =>
      universalCallList &&
      universalCallList
        ?.filter((item) => {
          return searchValue.toLowerCase() === ""
            ? item && item
            : (item?.call_id &&
                item?.call_id.toLowerCase().includes(searchValue)) 
                // ||
                // (item?.evaluation_date &&
                //   item?.evaluation_date?.includes(searchValue)) ||
                // (item?.earnedPoints &&
                //   item?.earnedPoints?.includes(searchValue)) ||
                // (item?.possiblePoints &&
                //   item?.possiblePoints?.includes(searchValue)) ||
                // (item?.preAfScore && item?.preAfScore?.includes(searchValue)) ||
                // (item?.call_duration &&
                //   item?.call_duration?.includes(searchValue)) ||
                // (item?.totalScore && item?.totalScore?.includes(searchValue));
        })
        .map((i) =>
        ({
          action: [
          
          <span>
            {localStorage.getItem('loginUserRole') == "QS" ? 
            <ul className="action-strip primary iconic">
                 
                        <li><button onClick={() => [setConversationId(i?._id), getDetailsForCalls(i?.call_id),]} className="btn btn-md" disabled={i?.call_register_status == 1 ? "disabled" : ""}><i className="las la-user-plus"></i></button><span className="iconic-tooltip">Register</span></li>
                    
            </ul>:''}
        </span>],
          call_id: [
            <p>
              <Link
                to={`/new-form-preview?formName=${i.form_name}&formVersion=${
                  i.form_version
                }&moduleName=evaluation&fromList=univFormCallList&call_id=${
                  i?.call_id !== undefined && i?.call_id
                }&authSecondId&resolvedSecId`}
                target="_blank"
                className="td-link"
              >
                {i?.call_id !== undefined && i?.call_id}
              </Link>
            </p>,
          ],
          call_date: [<p className="text-nowrap">{i?.call_date}</p>],
          evaluation_date: [
            <p className="text-nowrap">{i?.evaluation_date}</p>,
          ],
          call_duration: [<p className="text-nowrap">{i?.call_duration}</p>],
          earnedPoints: [
            <p className=" text-nowrap">
              {i.earnedPoints ? i.earnedPoints : "0"}
            </p>,
          ],
          possiblePoints: [
            <p className=" text-nowrap">
              {i.possiblePoints ? i.possiblePoints : "0"}
            </p>,
          ],
          preAfScore: [
            <p className=" text-nowrap">{i.preAfScore ? i.preAfScore : "0"}</p>,
          ],
          totalScore: [
            <p className=" text-nowrap">{i.totalScore ? i.totalScore : "0"}</p>,
          ],
        }))
  );
  return (
    <div className="page-wrapper" style={{ backgroundImage: "none" }}>
      {/* <Header />
            <Sidebar /> */}
             {/* modal for Application Reprocess */}
             <ModalShow
                title={`Add Call`}
                onClose={onAddCallClose}
                // onFooterClose={clearAllStates}
                show={addCallModal}
                scrollable={false}
                footer={false}
                modalClass="modal-50w"
                id="add_call_modal"
            >
              <div>
              {popupLoader ? (
                            <div className="loader-container ">
                                <div className="top-0 start-0 w-100 h-100 d-flex p-5 justify-content-center bg-white align-items-center opacity-75">
                                    <div className="spinner-border text-black " role="status">

                                    </div>
                                </div>
                            </div>
                        ) :
                            <div>
   
                                            <div className="row no-gutters" style={{ textAlign: 'justify', lineHeight: '1px' }}>
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h5><span style={{ fontWeight: 600 }}>Call Id : </span>{callInfo?.call_id}  </h5>


                                                    <h5>
                                                        <span style={{ fontWeight: 600 }}>Agent Name : &nbsp; </span>
                                                        {callInfo?.agent_name ? callInfo?.agent_name : 'NA'}
                                                        &nbsp;
                                                    </h5>

                                                    <h5>
                                                        <span style={{ fontWeight: 600 }}>Agent Id : &nbsp; </span>
                                                        {callInfo?.agent_id ? callInfo?.agent_id : 'NA'}

                                                        &nbsp;
                                                    </h5>


                                                    <h5 ><span style={{ fontWeight: 600 }}>Supervisor Name :</span> &nbsp;{callInfo?.supervisorname ? callInfo?.supervisorname : "NA"} &nbsp; </h5>
                                                    <h5 ><span style={{ fontWeight: 600 }}>Supervisor Id :</span> &nbsp;{callInfo?.supervisorid ? callInfo?.supervisorid : "NA"} &nbsp; </h5>


                                                    <h5 ><span style={{ fontWeight: 600 }}>Call Date & Time :</span>&nbsp;{moment(callInfo?.datetime).format("YYYY-MM-DD HH:mm:ss a")} &nbsp; </h5>

                                                    <h5 ><span style={{ fontWeight: 600 }}>Call Duration :</span>&nbsp;{callInfo?.duration} </h5>

                                                </div>
                                            </div>

                                      
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-3 col-lg-6 rounded form-group">
                                                <label><span>{hierarchyData.c1}</span><span className="mandatory">*</span></label>
                                                <Select options={optionsC1}
                                                    value={defaultValueC1 !== '' ? optionsC1.find((option) => option.value === defaultValueC1) : ''}
                                                    isSearchable={true} required={true} onChange={(e) => handleC2Change(e)}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                                                <label><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>
                                                <Select options={optionsC2}
                                                    id="optionC2Id"
                                                    value={defaultValueC2 !== '' ? optionsC2.find((option) => option.value === defaultValueC2) : ''}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} onChange={(e) => handleC3Change(e)} />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                                                <label><span>{hierarchyData.c3}</span><span className="mandatory ">*</span></label>
                                                <Select options={optionsC3}
                                                    value={defaultValueC3 !== '' ? optionsC3.find((option) => option.value === defaultValueC3) : ''}
                                                    id="optionsC3Id"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}

                                                    isSearchable={true} required={true} onChange={(e) => handleC4Change(e)} />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                                                <label><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>
                                                <Select options={optionsC4}
                                                    value={defaultValueC4 !== '' ? optionsC4.find((option) => option.value === defaultValueC4) : ''}
                                                    id="optionsC4Id"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} onChange={(e) => handleC5Change(e)} />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                                                <label><span>Form</span><span className="mandatory">*</span></label>
                                                
                                                <Select options={optionsFormData}
                                                    value={formName !== '' ? optionsFormData.find((option) => option.value === formName) : ''}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} onChange={(e) => setFormName(e.value)} />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-6 form-group">

                                                <label><span>Assign To</span><span className="mandatory">*</span></label>

                                                <Select
                                                    options={optionAssign}
                                                    value={assignValue !== '' ? optionAssign.find((option) => option.value === assignValue) : ''}
                                                    id="reset"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => hideEvaluateBtn(e.value)}
                                                />

                                            </div>
                                            {<div className={allClasses}>
                                                <label><span>Evaluator Name(Id)</span><span className="mandatory">*</span></label>
                                               
                                                <Select options={optionsotherEvaluatorsData}
                                                    value={evaluatorName !== '' ? optionsotherEvaluatorsData.find((option) => option.value === evaluatorName) : ''}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} onChange={(e) => setEvaluatorName(e.value)} />

                                            </div>}
                                     
                                        </div>
                                    </div>
                            

                                <div className="modal-footer">
                                    {showEvaluateBtn &&
                                        (
                                            <div className="position-relative d-flex align-items-center">
                                                <button
                                                    type="button"
                                                    className={`btn btn-outline-primary`}
                                                    onClick={showEvaluateForm}
                                                    disabled={isLoadingEvaluate}

                                                >
                                                    Evaluate
                                                </button>
                                                {isLoadingEvaluate && (
                                                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center opacity-75">
                                                        <div className="spinner-border text-black" role="status">

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                    <button type="button" onClick={() => handleAddEvaluation(0)} className={`btn btn-primary   ${isDataFound ? 'loading' : ''} `}>Register</button>
                                </div>
                            </div>
                        }
              </div>
            </ModalShow>

            {/* modal for Application Reprocess */}
      <main>
        <ToastContainer />
        <div className="main-contents">
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header">
                <h3 className="sc-title mb-0">{window.location.hostname == "neofinancial.neqqo.com" ? "Auto QA List" :"Universal Form Call List"}</h3>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <span>Smartspeech</span>
              </li>
              <li className="breadcrumb-item">
                <span style={{cursor:"pointer"}} onClick={()=>navigate('/smartspeech-dashboard-new')}>Smartspeech Dashboard</span>
              </li>
              <li className="breadcrumb-item">
                <span style={{cursor:"pointer"}} onClick={()=>navigate('/universal-dashboard')}>Universal Dashboard</span>
              </li>
              <li className="breadcrumb-item active">
                <span>{window.location.hostname == "neofinancial.neqqo.com" ? "Auto QA List" :"Universal Form Call List"}</span>
              </li>
            </ul>
          </nav>
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div
                className="alert alert-info text-center"
                id="success_msg"
              ></div>
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg"></button>
            </div>
          </div>

          <DataTableView
            title={window.location.hostname == "neofinancial.neqqo.com" ? "Auto QA List":"Universal Form Call List"}
            isPending={isLoading}
            columns={uniFormListColumns}
            isPDFReport={true}
            data={data}
            allData={universalCallList}
            isXLSReport={true}
            pdfReport={pdfReport}
            totalRec={data.length}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            xlsReport={xlsReport}
            isCSVReport={true}
            csvReport={csvReport}
          />
        </div>
      </main>
    </div>
  );
};

export default UniversalFormTable;
